<template>
    <Home-index />
</template>

<script>
import HomeIndex from '../components/home/index.vue';

export default {
    name: 'Home',

    components: {
        HomeIndex
    }
};
</script>
