var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"text_primary sub-header"},[_vm._v("Słowniki")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.showNewAttachmentDialog = true}}},[_vm._v(" + Nowy ")])],1)],1),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('div',{staticClass:"pl-6 pr-6 mb-14"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',{staticClass:"sub-title"},[_vm._v("Dostępne słowniki")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('Search-field',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mt-4 mb-4 pl-6 pr-6"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Data-table',{attrs:{"must-sort":true,"disable-sort":_vm.isLoading,"headers":_vm.headers,"tableData":_vm.formattedAttachments,"loading":_vm.isLoading,"itemKey":'id',"itemClass":_vm.getDisabledItemClass,"noDataText":_vm.dataEmptyText,"customTemplatesItemsNames":["item.actions", "item.visibility"],"options":_vm.attachmentsDataOptions,"serverItemsLength":_vm.attachmentsData.elements},on:{"update:options":function($event){_vm.attachmentsDataOptions=$event}},scopedSlots:_vm._u([{key:"item.visibility",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-start"},[_c('span',{staticClass:"mr-6"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.visibility == 'public')?_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-earth ")]):_vm._e(),(item.visibility == 'private')?_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1"},'v-icon',attrs,false),on),[_vm._v(" mdi-account ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.visibilityTranslations[item.visibility]))])])],1),(item.visibility == 'private')?_c('span',[(item.state == 'deleted')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"color":_vm.CSS_COLOR.colorAlert}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v("Słownik usunięty")])]):(item.publication_state == 'unreported')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"color":_vm.CSS_COLOR.colorWarning}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Nie zgłoszono do publikacji")])]):(item.publication_state == 'rejected')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"color":_vm.CSS_COLOR.colorAlert}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Odrzucony przez Administratora")])]):(item.publication_state == 'reported')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1",attrs:{"color":_vm.CSS_COLOR.colorSuccess}},'v-icon',attrs,false),on),[_vm._v(" mdi-clock-time-eight-outline ")])]}}],null,true)},[_c('span',[_vm._v("Zgłoszono do publikacji")])]):_vm._e()],1):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showMoreAttachmentDetails(item)}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"paginator"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"total-visible":5,"disabled":_vm.isLoading,"length":_vm.attachmentsData.pages},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=$$v},expression:"selectedPage"}})],1)])],1)],1),_c('Dialog',{attrs:{"title":'Nowy słownik',"forceActivation":_vm.showNewAttachmentDialog,"useSlotContentBtnToCloseDialog":true},on:{"dialogClosed":function($event){(_vm.showNewAttachmentDialog = false), _vm.componentKey++}},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var deactivate = ref.deactivate;
return [_c('Attachment-add-new-dialog',{key:_vm.componentKey,on:{"deactivate":function($event){return deactivate()}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }