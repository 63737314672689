<template>
    <Jobs-Index></Jobs-Index>
</template>

<script>
import JobsIndex from '../components/jobs/index';

export default {
    components: {
        JobsIndex
    }
};
</script>
