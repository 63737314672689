<template>
    <div>
        <v-card-text class="pa-8">
            <v-form ref="form">
                <v-text-field
                    v-model="newGroup.name"
                    outlined
                    :color="CSS_COLOR.textHighlight"
                    label="Nazwa:"
                    @input="$v.newGroup.name.$touch()"
                    @blur="$v.newGroup.name.$touch()"
                    :error-messages="nameErrors"
                    class="v-input v-input_required"
                >
                </v-text-field>

                <v-textarea
                    v-model="newGroup.description"
                    class="v-input v-input_full-width"
                    label="Opis:"
                    height="95px"
                    outlined
                    :no-resize="true"
                    @input="$v.newGroup.description.$touch()"
                    @blur="$v.newGroup.description.$touch()"
                    :error-messages="descriptionErrors"
                ></v-textarea>
            </v-form>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('closeDialog')">Anuluj</v-btn>
                        <v-btn class="v-btn__primary" @click="sendRequestAndCloseDialog">Dodaj grupę</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { groupsService } from '../../../services/groups.service';
import { CSS_COLOR } from '../../../_helpers/consts';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'AddGroupDialog',
    mixins: [validationMixin],

    validations: {
        newGroup: {
            name: { required, maxLength: maxLength(50) },
            description: { maxLength: maxLength(200) }
        }
    },

    data() {
        return {
            CSS_COLOR,
            newGroup: {
                name: '',
                description: ''
            }
        };
    },

    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.newGroup.name.$dirty) return errors;
            !this.$v.newGroup.name.required && errors.push('Nazwa jest wymagana');
            !this.$v.newGroup.name.maxLength && errors.push('Maks. liczba znaków to 50');
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.newGroup.description.$dirty) return errors;
            !this.$v.newGroup.description.maxLength && errors.push('Maks. liczba znaków to 200');
            return errors;
        }
    },

    methods: {
        async sendRequestAndCloseDialog() {
            if (!this.checkFormValidation()) return;

            const response = await groupsService.postNewGroup(this.newGroup);

            this.$emit('closeDialog');
            if (response.status == 200 || response.status == 201) {
                this.getGroupsFromApi();
            }
        },

        async getGroupsFromApi() {
            this.$store.dispatch('getGroupsFromApi');
        },

        checkFormValidation() {
            this.$v.$touch();
            return !this.$v.$anyError;
        }
    }
};
</script>
