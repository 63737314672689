<template>
    <div>
        <v-card-text class="pa-8">
            <v-row>
                <v-col cols="12">
                    <p>Czy na pewno chcesz usunąć {{ target === 'user' ? 'użytkownika' : 'agenta' }}</p>
                    <p class="font-weight-bold">{{ target === 'user' ? selectedUser.login : selectedAgent.name }}</p>
                    <p>z grupy</p>
                    <p class="font-weight-bold">{{ group.name }}?</p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('closeDialog')">Anuluj</v-btn>
                        <v-btn class="v-btn__alert" @click="sendRequestAndCloseDialog">Usuń</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { groupsService } from '../../../services/groups.service';

export default {
    name: 'RemoveFromGroupDialog',

    props: {
        target: { type: String, required: true, validator: (v) => ['user', 'agent'].includes(v) },
        group: { type: Object, required: true }
    },

    computed: {
        selectedUser() {
            return this.$store.getters.selectedUser;
        },
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        }
    },

    methods: {
        async sendRequestAndCloseDialog() {
            let response;
            if (this.target === 'user') {
                response = await groupsService.removeUserFromGroup(this.selectedUser.id, this.group.id);
            } else if (this.target === 'agent') {
                response = await groupsService.removeAgentFromGroup(this.selectedAgent.id, this.group.id);
            }

            this.$emit('closeDialog');
            if (response.status !== 200) return;

            if (this.target === 'user') {
                this.fetchUserData();
            } else if (this.target === 'agent') {
                this.fetchAgentData();
            }
        },

        async fetchUserData() {
            this.$store.dispatch('setUserRefetch', this.selectedUser.id);
            await this.$store.dispatch('getUserById', this.selectedUser.id);
        },
        async fetchAgentData() {
            this.$store.dispatch('setAgentRefetch', this.selectedAgent.id);
            await this.$store.dispatch('getAgentById', this.selectedAgent.id);
        }
    }
};
</script>
