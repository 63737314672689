<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <v-row>
            {{ selectedAgentResources.name }}
            <v-col cols="12">
                <Data-table
                    :headers="headers"
                    :tableData="selectedAgentResources"
                    :itemKey="'id'"
                    :noDataText="noDataText"
                    :showSelect="false"
                    :customTemplatesItemsNames="[`item.name`, `item.type`, `item.actions`]"
                    class="v-data-table_secondary v-data-table_narrow"
                >
                    <template v-slot:[`item.name`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                            </template>
                            <span>{{ item.type.toUpperCase() }}</span> - <span>{{ item.state }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.type`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tag" v-bind="attrs" v-on="on" :class="tagClasses[item.state]">{{ item.type.toUpperCase() }}</span>
                            </template>
                            <span>{{ item.type.toUpperCase() }}</span> - <span>{{ item.state }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon @click="openStructuresDialog(item)">
                            <v-icon> mdi-card-search-outline </v-icon>
                        </v-btn>
                    </template>
                </Data-table>
            </v-col>
        </v-row>

        <Dialog
            dialogWidth="25%"
            :title="'Dostępne formaty'"
            :forceActivation="showStructuresDialog"
            @dialogClosed="showStructuresDialog = false"
        >
            <template v-slot:content="{ deactivate }">
                <Agent-hashes-dialog :selectedStructures="selectedStructures" @deactivate="deactivate()"></Agent-hashes-dialog>
            </template>
        </Dialog>
    </v-card>
</template>

<script>
import DataTable from '../../global/DataTable.vue';
import Dialog from '../../global/Dialog.vue';
import AgentHashesDialog from './AgentHashesDialog.vue';

export default {
    components: { DataTable, Dialog, AgentHashesDialog },
    name: 'AgentResources',

    data: () => ({
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Typ', value: 'type' },
            { text: 'Nazwa', value: 'name' },
            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak zasobów do wyświetlenia',
        showStructuresDialog: false,
        selectedStructures: [],
        tagClasses: {
            idle: '',
            busy: 'tag_active',
            working: 'tag_active',
            disconnected: 'tag_inactive'
        }
    }),

    computed: {
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },

        selectedAgentResources() {
            return this.selectedAgent?.resources;
        }
    },

    methods: {
        openStructuresDialog(item) {
            this.selectedStructures = item?.hashes;
            this.showStructuresDialog = true;
        }
    },

    beforeDestroy() {
        clearInterval(this.refreshInterval);
    }
};
</script>
