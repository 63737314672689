<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <h2 class="text_primary sub-header">Grupy</h2>
            </v-col>
            <v-col cols="auto" v-if="isAdminLogged">
                <v-btn color="primary" dark @click="openNewGroupDialog"> + Nowa </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <div class="pl-6 pr-6 mb-14">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <h3 class="sub-title">Dostępne grupy</h3>
                </v-col>
                <v-col cols="auto">
                    <Search-field v-model.lazy="search" />
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4 pl-6 pr-6"></v-divider>
            <v-row>
                <v-col cols="12">
                    <Data-table
                        :must-sort="true"
                        :disable-sort="isLoading"
                        :headers="headers"
                        :tableData="formattedGroups"
                        :loading="isLoading"
                        :itemKey="'id'"
                        :noDataText="dataEmptyText"
                        :customTemplatesItemsNames="[`item.actions`]"
                        :options.sync="groupsDataOptions"
                        :serverItemsLength="groupsData.elements"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="text-end">
                                <v-btn icon @click="showMoreGroupDetails(item)">
                                    <v-icon> mdi-chevron-right </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Data-table>
                </v-col>
            </v-row>
            <v-row class="paginator">
                <v-col cols="12">
                    <div class="text-center pt-2">
                        <v-pagination :total-visible="5" :disabled="isLoading" v-model="selectedPage" :length="groupsData.pages"></v-pagination>
                    </div>
                </v-col>
            </v-row>
        </div>

        <Dialog :title="'Nowa grupa'" :forceActivation="showNewGroupDialog" dialog-width="600px" @dialogClosed="showNewGroupDialog = false">
            <template v-slot:content="{ deactivate }">
                <add-group-dialog @closeDialog="deactivate()" :key="componentKey"></add-group-dialog>
            </template>
        </Dialog>
    </v-container>
</template>

<script>
import SearchField from '../../global/SearchField.vue';
import DataTable from '../../global/DataTable.vue';
import Dialog from '../../global/Dialog.vue';
import AddGroupDialog from '../subcomponents/AddGroupDialog.vue';

import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';

export default {
    components: { SearchField, DataTable, Dialog, AddGroupDialog },
    mixins: [DataTableOptionsMixin],
    name: 'GroupsMainSection',

    mounted() {
        this.openPreselectedGroupForDetails();
    },

    data: () => ({
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Nazwa', value: 'name', width: '30%' },
            { text: 'Opis', value: 'description' },
            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak grup do wyświetlenia',
        noResultsText: 'Nie znaleziono żadnych grup',
        dataEmptyText: '',

        componentKey: 0,
        showNewGroupDialog: false,

        page: null,
        search: '',
        options: {
            sortBy: ['id'],
            sortDesc: [false]
        }
    }),

    computed: {
        selectedPage: {
            get() {
                return this.groupsData?.page ? this.groupsData.page : 1;
            },
            set(value) {
                this.page = value;
            }
        },

        groupsDataOptions: {
            get() {
                return {
                    sortBy: this.groupsSortBy ? [this.groupsSortBy] : this.options.sortBy,
                    sortDesc: this.groupsSortOrder === 'desc' ? [true] : this.options.sortDesc
                };
            },
            set(value) {
                this.options = value;
            }
        },

        storedGroups() {
            return this.$store.getters.groups;
        },

        groupsData() {
            return this.$store.getters.groupsData;
        },

        groupsSortBy() {
            return this.$store.getters.groupsSortBy;
        },

        groupsSortOrder() {
            return this.$store.getters.groupsSortOrder;
        },

        isLoading() {
            return this.$store.getters.isGroupsDataLoading;
        },

        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        formattedGroups() {
            return JSON.parse(JSON.stringify(this.storedGroups));
        },

        preselectedGroupId() {
            return this.$store.getters.preselectedGroupId;
        }
    },

    methods: {
        showMoreGroupDetails(item) {
            const selectedGroupId = item.id;
            this.$store.dispatch('openDetails');
            this.$store.dispatch('getGroupById', selectedGroupId);
        },

        openPreselectedGroupForDetails() {
            setTimeout(async () => {
                if (!this.preselectedGroupId) return;
                this.$store.dispatch('openDetails');
                await this.$store.dispatch('getGroupById', this.preselectedGroupId);
                this.$store.dispatch('setPreselectedGroupId', null);
            }, 300);
        },

        openNewGroupDialog() {
            this.componentKey++;
            this.showNewGroupDialog = true;
        },

        onPageChange(page) {
            this.$store.dispatch('setGroupsPage', page);
            this.$store.dispatch('getGroupsFromApi');
        },
        onSortChange(sortBy, isSortDesc) {
            this.$store.dispatch('setGroupsSortBy', sortBy ? sortBy : '');
            this.$store.dispatch('setGroupsSortOrder', isSortDesc ? 'desc' : '');
            this.$store.dispatch('getGroupsFromApi');
        },
        onSearchInput(text) {
            this.$store.dispatch('setGroupsPage', 1);
            this.$store.dispatch('setGroupsSearch', text);
            this.$store.dispatch('getGroupsFromApi');
        }
    },

    created() {
        this.dataEmptyText = this.noDataText;
    },

    beforeDestroy() {
        this.$store.dispatch('setGroupsSearch', '');
    },

    watch: {
        storedGroups(newVal) {
            if (this.search && !newVal?.length) {
                this.dataEmptyText = this.noResultsText;
            } else {
                this.dataEmptyText = this.noDataText;
            }
        }
    }
};
</script>
