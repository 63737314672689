<template>
    <Main-section :hideSecondaryCol="false">
        <template v-slot:left-col>
            <Attachments-main-section></Attachments-main-section>
        </template>
        <template v-slot:right-col><Attachments-hidden-section></Attachments-hidden-section> </template>
    </Main-section>
</template>

<script>
import MainSection from '../global/MainSection.vue';
import AttachmentsHiddenSection from './subcomponents/AttachmentsHiddenSection.vue';
import AttachmentsMainSection from './subcomponents/AttachmentsMainSection.vue';

export default {
    name: 'AttachmentsIndex',

    components: {
        MainSection,
        AttachmentsMainSection,
        AttachmentsHiddenSection
    },

    data: () => ({
        routeName: 'Dictionaries'
    }),

    computed: {
        shouldAutoReload() {
            return this.$store.getters.autoRefreshData;
        },

        selectedAttachment() {
            return this.$store.getters.selectedAttachment;
        }
    },

    watch: {
        shouldAutoReload(newBooleanValue) {
            if (newBooleanValue) {
                this.$store.dispatch('autoRefreshCallback', this.getAttachmentsData);
                this.getAttachmentsData();
            } else {
                this.$store.dispatch('autoRefreshCallback', null);
            }
        }
    },

    methods: {
        async getAttachmentsData() {
            await this.$store.dispatch('getAttachmentsFromApi');

            this.$store.dispatch('setAttachmentsLastUpdateTime');

            this.selectedAttachment ? this.refreshSelectedAttachment() : null;
        },

        refreshSelectedAttachment() {
            this.$store.dispatch('setAttachmentRefetch');
            this.$store.dispatch('getAttachmentById', this.selectedAttachment.id);
            this.$store.dispatch('getAttachmentRequestsFromApi', this.selectedAttachment.id);
        }
    },

    mounted() {
        this.getAttachmentsData();
        this.$store.dispatch('autoRefreshCallback', this.getAttachmentsData);
    },

    beforeDestroy() {
        this.$store.dispatch('resetAttachmentsData');

        this.$store.dispatch('autoRefreshCallback', null);
    }
};
</script>
