<template>
    <div class="notifications-list">
        <Notification-message v-for="notification in notifications" :key="notification.id" :notification="notification"></Notification-message>
    </div>
</template>

<script>
import NotificationMessage from './NotificationMessage.vue';
export default {
    name: 'NotificationsList',

    components: {
        NotificationMessage
    },

    computed: {
        notifications() {
            return this.$store.getters.notifications;
        }
    }
};
</script>

NotificationMessage
