import { algorithmsService } from '../../services/algorithms.service';

const state = () => ({
    algorithms: [],
    allAlgorithmsStored: false,
    isLoadingAlgorithmsData: false
});

// getters
const getters = {
    algorithmsData: (state) => {
        return state.algorithms;
    },

    isLoadingAlgorithmsData: (state) => {
        return state.isLoadingAlgorithmsData;
    }
};

// actions
const actions = {
    async getAlgorithmsFromApi({ commit }) {
        commit('isLoadingAlgorithmsDataChange');

        const response = await algorithmsService.getAlgorithms({ per_page: 256 });

        if (response.status !== 200) {
            commit('isLoadingAlgorithmsDataChange');
            return;
        }

        const { content } = response.data;

        commit('algorithmsData', content);
        commit('isLoadingAlgorithmsDataChange');
        commit('changeAllAlgorithmsStoredStatus');
    },

    changeAllAlgorithmsStoredStatus({ commit }) {
        commit('changeAllAlgorithmsStoredStatus');
    }
};

// mutations
const mutations = {
    algorithmsData(state, payload) {
        state.algorithms = payload;
    },

    changeAllAlgorithmsStoredStatus(state) {
        state.allAlgorithmsStored = !state.allAlgorithmsStored;
    },

    isLoadingAlgorithmsDataChange(state) {
        state.isLoadingAlgorithmsData = !state.isLoadingAlgorithmsData;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
