<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <h2 class="text_primary sub-header">Słowniki</h2>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" dark @click="showNewAttachmentDialog = true"> + Nowy </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <div class="pl-6 pr-6 mb-14">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <h3 class="sub-title">Dostępne słowniki</h3>
                </v-col>
                <v-col cols="auto">
                    <Search-field v-model.lazy="search" />
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4 pl-6 pr-6"></v-divider>
            <v-row>
                <v-col cols="12">
                    <Data-table
                        :must-sort="true"
                        :disable-sort="isLoading"
                        :headers="headers"
                        :tableData="formattedAttachments"
                        :loading="isLoading"
                        :itemKey="'id'"
                        :itemClass="getDisabledItemClass"
                        :noDataText="dataEmptyText"
                        :customTemplatesItemsNames="[`item.actions`, `item.visibility`]"
                        :options.sync="attachmentsDataOptions"
                        :serverItemsLength="attachmentsData.elements"
                    >
                        <template v-slot:[`item.visibility`]="{ item }">
                            <div class="text-start">
                                <span class="mr-6">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" v-if="item.visibility == 'public'" class="mb-1"> mdi-earth </v-icon>
                                            <v-icon v-bind="attrs" v-on="on" v-if="item.visibility == 'private'" class="mb-1">
                                                mdi-account
                                            </v-icon>
                                        </template>
                                        <span>{{ visibilityTranslations[item.visibility] }}</span>
                                    </v-tooltip>
                                </span>

                                <span v-if="item.visibility == 'private'">
                                    <v-tooltip bottom v-if="item.state == 'deleted'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :color="CSS_COLOR.colorAlert" v-bind="attrs" v-on="on" class="mb-1"
                                                >mdi-information-outline</v-icon
                                            >
                                        </template>
                                        <span>Słownik usunięty</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else-if="item.publication_state == 'unreported'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" class="mb-1" :color="CSS_COLOR.colorWarning">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>Nie zgłoszono do publikacji</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else-if="item.publication_state == 'rejected'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" class="mb-1" :color="CSS_COLOR.colorAlert">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>Odrzucony przez Administratora</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-else-if="item.publication_state == 'reported'">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon :color="CSS_COLOR.colorSuccess" v-bind="attrs" v-on="on" class="mb-1">
                                                mdi-clock-time-eight-outline
                                            </v-icon>
                                        </template>
                                        <span>Zgłoszono do publikacji</span>
                                    </v-tooltip>
                                </span>
                            </div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="text-end">
                                <v-btn icon @click="showMoreAttachmentDetails(item)">
                                    <v-icon> mdi-chevron-right </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Data-table>
                </v-col>
            </v-row>
            <v-row class="paginator">
                <v-col cols="12">
                    <div class="text-center pt-2">
                        <v-pagination
                            :total-visible="5"
                            :disabled="isLoading"
                            v-model="selectedPage"
                            :length="attachmentsData.pages"
                        ></v-pagination>
                    </div>
                </v-col>
            </v-row>
        </div>

        <Dialog
            :title="'Nowy słownik'"
            :forceActivation="showNewAttachmentDialog"
            :useSlotContentBtnToCloseDialog="true"
            @dialogClosed="(showNewAttachmentDialog = false), componentKey++"
        >
            <template v-slot:content="{ deactivate }">
                <Attachment-add-new-dialog @deactivate="deactivate()" :key="componentKey"></Attachment-add-new-dialog>
            </template>
        </Dialog>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import SearchField from '../../global/SearchField.vue';
import DataTable from '../../global/DataTable.vue';
import AttachmentAddNewDialog from './AttachmentAddNewDialog.vue';
import Dialog from '../../global/Dialog.vue';

import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';

export default {
    components: { SearchField, DataTable, AttachmentAddNewDialog, Dialog },
    mixins: [DataTableOptionsMixin],
    name: 'AttachmentsMainSection',

    data: () => ({
        CSS_COLOR,
        disabledItemClass: 'row--disabled',
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Nazwa', value: 'name' },
            { text: 'Opis', value: 'description' },
            { text: 'Typ', value: 'type' },
            { text: 'Widoczność', value: 'visibility' },
            { text: 'Data dodania', value: 'created_at' },
            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak słowników do wyświetlenia',
        noResultsText: 'Nie znaleziono żadnych słowników',
        dataEmptyText: '',
        showNewAttachmentDialog: false,

        visibilityTranslations: {
            private: 'prywatny',
            public: 'publiczny'
        },

        componentKey: 0,
        page: null,
        search: '',
        options: {
            sortBy: ['id'],
            sortDesc: [false]
        }
    }),

    computed: {
        selectedPage: {
            get() {
                return this.attachmentsData?.page ? this.attachmentsData.page : 1;
            },
            set(value) {
                this.page = value;
            }
        },

        attachmentsDataOptions: {
            get() {
                return {
                    sortBy: this.attachmentsSortBy ? [this.attachmentsSortBy] : this.options.sortBy,
                    sortDesc: this.attachmentsSortOrder === 'desc' ? [true] : this.options.sortDesc
                };
            },
            set(value) {
                this.options = value;
            }
        },

        storedAttachments() {
            return this.$store.getters.attachmentsItems;
        },

        attachmentsData() {
            return this.$store.getters.attachmentsData;
        },

        attachmentsSortBy() {
            return this.$store.getters.attachmentsSortBy;
        },

        attachmentsSortOrder() {
            return this.$store.getters.attachmentsSortOrder;
        },

        isLoading() {
            return this.$store.getters.isAttachmentsDataLoading;
        },

        formattedAttachments() {
            const formattedAttachments = JSON.parse(JSON.stringify(this.storedAttachments));

            formattedAttachments.forEach((attachment) => {
                attachment['created_at'] = this.formatDateFrom1970(attachment['created_at']);
                attachment['isSelectable'] = attachment['state'] !== 'deleted';
            });
            return formattedAttachments;
        }
    },

    methods: {
        showMoreAttachmentDetails(item) {
            const selectedAttachmentId = item.id;
            this.$store.dispatch('openDetails');
            this.$store.dispatch('getAttachmentById', selectedAttachmentId);
        },

        formatDateFrom1970(date) {
            return date ? new Date(date).toLocaleDateString('en-UK') : '';
        },

        getDisabledItemClass(item) {
            if (item.state === 'deleted') {
                return this.disabledItemClass;
            }
        },

        onPageChange(page) {
            this.$store.dispatch('setAttachmentsPage', page);
            this.$store.dispatch('getAttachmentsFromApi');
        },
        onSortChange(sortBy, isSortDesc) {
            this.$store.dispatch('setAttachmentsSortBy', sortBy ? sortBy : '');
            this.$store.dispatch('setAttachmentsSortOrder', isSortDesc ? 'desc' : '');
            this.$store.dispatch('getAttachmentsFromApi');
        },
        onSearchInput(text) {
            this.$store.dispatch('setAttachmentsPage', 1);
            this.$store.dispatch('setAttachmentsSearch', text);
            this.$store.dispatch('getAttachmentsFromApi');
        }
    },

    created() {
        this.dataEmptyText = this.noDataText;
    },

    beforeDestroy() {
        this.$store.dispatch('setAttachmentsSearch', '');
    },

    watch: {
        storedAttachments(newVal) {
            if (this.search && !newVal?.length) {
                this.dataEmptyText = this.noResultsText;
            } else {
                this.dataEmptyText = this.noDataText;
            }
        }
    }
};
</script>
