<template>
    <Main-section :hideSecondaryCol="false">
        <template v-slot:left-col>
            <System-status-main-section
                @showAgentAndResourceInfo="showAgentInformationWithSelectedTab"
                @showMainControllerInfo="showMainControllerInformation"
            ></System-status-main-section>
        </template>
        <template v-slot:right-col>
            <System-status-hidden-section
                :isMainControllerSelected="isMainControllerSelected"
                :preSelectedTab="preSelectedTab"
            ></System-status-hidden-section>
        </template>
    </Main-section>
</template>

<script>
import SystemStatusMainSection from './subcomponents/SystemStatusMainSection.vue';
import SystemStatusHiddenSection from './subcomponents/SystemStatusHiddenSection.vue';
import MainSection from '../global/MainSection.vue';
export default {
    name: 'SystemStatusIndex',

    components: {
        SystemStatusMainSection,
        SystemStatusHiddenSection,
        MainSection
    },

    data: () => ({
        preSelectedTab: 0,
        isMainControllerSelected: false,
        routeName: 'SystemStatus'
    }),

    computed: {
        shouldAutoReload() {
            return this.$store.getters.autoRefreshData;
        },

        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },

        isAgentVerified() {
            return this.$store.getters.isAgentVerified;
        },

        managerVersion() {
            return this.$store.getters.managerVersion;
        }
    },

    watch: {
        shouldAutoReload(newBooleanValue) {
            if (newBooleanValue) {
                this.$store.dispatch('autoRefreshCallback', this.getAgentsData);
                this.getAgentsData();
            } else {
                this.$store.dispatch('autoRefreshCallback', null);
            }
        }
    },

    methods: {
        async getAgentsData() {
            await this.$store.dispatch('getAgentsFromApi');

            this.$store.dispatch('setAgentsLastUpdateTime');

            this.selectedAgent && !this.isMainControllerSelected ? this.refreshSelectedAgent() : null;
        },

        async getManagerVersion() {
            await this.$store.dispatch('getManagerVersionFromApi');
        },

        refreshSelectedAgent() {
            this.$store.dispatch('setAgentRefetch');
            this.$store.dispatch('getAgentById', this.selectedAgent.id);
            if (!this.isAgentVerified) {
                this.$store.dispatch('getAgentLastCsr', this.selectedAgent.id);
            }
        },

        showAgentInformationWithSelectedTab(tabIndex) {
            this.isMainControllerSelected = false;
            this.preSelectedTab = tabIndex;
        },

        showMainControllerInformation() {
            this.isMainControllerSelected = true;
        }
    },

    async mounted() {
        this.getAgentsData();
        this.$store.dispatch('autoRefreshCallback', this.getAgentsData);

        if (!this.managerVersion) {
            await this.getManagerVersion();
        }
    },

    beforeDestroy() {
        this.$store.dispatch('resetAgentsData');

        this.$store.dispatch('autoRefreshCallback', null);
    }
};
</script>
