<template>
    <v-container fluid>
        <v-row justify="space-between">
            <v-col cols="auto">
                <Hidden-section-title
                    :titleText="selectedAttachment ? selectedAttachment.name : ''"
                    :titleCase="titleCase"
                ></Hidden-section-title>
            </v-col>
            <v-col cols="auto">
                <v-btn small icon @click="closeCol">
                    <v-icon :color="CSS_COLOR.textPrimary" small> mdi-close </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-progress-linear v-if="isRefetching" indeterminate height="1" color="primary" class="mt-4"></v-progress-linear>
        <v-divider v-else class="mt-4 v-divider_light"></v-divider>
        <v-row v-if="isNewDataLoading" class="py-10">
            <v-progress-circular class="mt-12 mx-auto" color="primary" size="60" width="2" indeterminate></v-progress-circular>
        </v-row>
        <v-row v-else-if="!isNewDataLoading && selectedAttachment">
            <v-col cols="12">
                <v-card color="transparent" elevation="0">
                    <v-tabs v-model="tab" background-color="transparent" grow>
                        <v-tab v-for="item in items" :key="item">
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" class="mt-8 transparent">
                        <v-tab-item>
                            <Attachment-details></Attachment-details>
                        </v-tab-item>
                        <v-tab-item>
                            <Attachment-jobs></Attachment-jobs>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else justify="center" class="mt-10">
            <v-col cols="auto">
                <v-alert type="error">Błąd wczytywania danych słownika</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import AttachmentDetails from './AttachmentDetails.vue';
import AttachmentJobs from './AttachmentJobs.vue';
import HiddenSectionTitle from '../../global/HiddenSectionTitle.vue';

export default {
    components: {
        AttachmentDetails,
        AttachmentJobs,
        HiddenSectionTitle
    },
    name: 'AttachmentsHiddenSection',

    data: () => ({
        CSS_COLOR,
        tab: null,
        items: ['Szczegóły', 'Zlecenia']
    }),

    computed: {
        titleCase() {
            if (this.isNewDataLoading) {
                return 'loading';
            } else if (this.selectedAttachment && !this.isNewDataLoading) {
                return 'title';
            } else {
                return 'error';
            }
        },
        isNewDataLoading() {
            return this.$store.getters.isAttachmentNewDataLoading;
        },
        isRefetching() {
            return this.$store.getters.isAttachmentRefetching;
        },
        selectedAttachment() {
            return this.$store.getters.selectedAttachment;
        }
    },

    methods: {
        closeCol() {
            this.$store.dispatch('closeDetails');
        }
    }
};
</script>
