<template>
    <v-container fluid fill-height justify-center>
        <v-card outlined color="transparent" class="mb-14">
            <v-row v-if="isLoading" class="text-center">
                <v-col cols="12">
                    <v-progress-circular :size="200" :color="CSS_COLOR.textHighlight" indeterminate></v-progress-circular>
                </v-col>
            </v-row>

            <v-row v-else class="text-center mb-14">
                <v-col cols="12">
                    <v-img :src="require('../../assets/images/cyber-crypt-logo.svg')" class="my-3" contain height="200" />
                </v-col>
                <v-col cols="12" class="mb-14 text_primary">
                    <br />
                    <h1>
                        rozproszony, skalowalny, wysokowydajny system <br />
                        pozwalający na przełamywanie zabezpieczeń kryptograficznych
                    </h1>
                </v-col>
            </v-row>
        </v-card>
        <div v-if="!isLoading" class="d-absolute d-right-0 d-bottom-0 pr-6">
            <p class="text_primary text-end">wersja: {{ managerVersion }}</p>
        </div>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../_helpers/consts';
import { grafanaService } from '../../services/grafana.service';
export default {
    name: 'HomeIndex',

    data: () => ({
        CSS_COLOR,
        showLogo: false
    }),

    computed: {
        isLoading() {
            return this.$store.getters.isCurrentUserDataLoading;
        },

        managerVersion() {
            return this.$store.getters.managerVersion;
        }
    },

    methods: {
        async getCurrentUsersData() {
            await this.$store.dispatch('getLoggedUserFromApi');
            this.showLogo = true;
        },

        async getManagerVersion() {
            await this.$store.dispatch('getManagerVersionFromApi');
        },

        async grafanaLogIn() {
            await grafanaService.grafanaAuth();
        }
    },

    mounted() {
        this.getCurrentUsersData();
        this.getManagerVersion();
    }
};
</script>
