<template>
    <v-card outlined color="transparent" height="100%" width="100%">
        <System-status-graph v-on="$listeners"></System-status-graph>
    </v-card>
</template>

<script>
import SystemStatusGraph from './SystemStatusGraph.vue';

export default {
    name: 'SystemStatusMainSection',

    components: {
        SystemStatusGraph
    },

    data: () => ({})
};
</script>
