<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <div class="mt-12 mb-12">
                    <p class="text-center sub-header" :id="selectionPassId">{{ textPassword }}</p>
                </div>
            </v-container>
        </v-card-text>

        <v-divider class="v-divider_light"></v-divider>

        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-2" @click="$emit('deactivate')">Zamknij</v-btn>
                        <Copy-to-clip-board-btn v-if="isHttps" :textToCopy="textPassword" :btnText="'Skopiuj'"></Copy-to-clip-board-btn>
                        <Select-element-text-btn v-else :containerId="selectionPassId" :btnText="'Zaznacz hasło'"></Select-element-text-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import CopyToClipBoardBtn from '../../global/CopyToClipBoardBtn.vue';
import SelectElementTextBtn from '../../global/SelectElementTextBtn.vue';
export default {
    components: { SelectElementTextBtn, CopyToClipBoardBtn },
    name: 'JobShowPassword',

    data: () => ({
        selectionPassId: 'element-to-password-select',
        isHttps: 'https:' == window.location.protocol
    }),

    props: {
        textPassword: String
    }
};
</script>
