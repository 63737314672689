<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <v-row class="text-left">
            <v-col cols="12">
                <v-form ref="form" class="mr-auto ml-auto ml-md-0">
                    <v-text-field
                        v-model="selectedGroupName"
                        outlined
                        :color="CSS_COLOR.textHighlight"
                        label="Nazwa:"
                        class="v-input v-input_required"
                        @input="$v.selectedGroupName.$touch()"
                        @blur="$v.selectedGroupName.$touch()"
                        :error-messages="nameErrors"
                    >
                    </v-text-field>

                    <v-textarea
                        v-model="selectedGroupDescription"
                        class="v-input v-input_full-width"
                        label="Opis:"
                        height="95px"
                        outlined
                        :no-resize="true"
                        @input="$v.selectedGroupDescription.$touch()"
                        @blur="$v.selectedGroupDescription.$touch()"
                        :error-messages="descriptionErrors"
                    ></v-textarea>

                    <v-row class="justify-space-between">
                        <v-col cols="auto">
                            <v-btn :loading="isDeleting" :disabled="!selectedGroup.deletable" class="v-btn__alert" @click="sendDeleteRequest"
                                >Usuń</v-btn
                            >
                        </v-col>
                        <v-col cols="auto">
                            <v-btn :loading="isUpdating" class="v-btn__primary" @click="sendPatchRequest">Aktualizuj</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { groupsService } from '../../../services/groups.service';

export default {
    name: 'GroupDetails',
    mixins: [validationMixin],

    validations: {
        selectedGroupName: { required, maxLength: maxLength(50) },
        selectedGroupDescription: { maxLength: maxLength(200) }
    },

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,
        name: null,
        description: null,

        isUpdating: false,
        isDeleting: false
    }),

    computed: {
        selectedGroup() {
            return this.$store.getters.selectedGroup;
        },

        selectedGroupName: {
            get() {
                return this.name === null ? this.selectedGroup.name : this.name;
            },
            set(value) {
                this.name = value;
            }
        },

        selectedGroupDescription: {
            get() {
                return this.description === null ? this.selectedGroup.description : this.description;
            },
            set(value) {
                this.description = value;
            }
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.selectedGroupName.$dirty) return errors;
            !this.$v.selectedGroupName.required && errors.push('Nazwa jest wymagana');
            !this.$v.selectedGroupName.maxLength && errors.push('Maks. liczba znaków to 50');
            return errors;
        },
        descriptionErrors() {
            const errors = [];
            if (!this.$v.selectedGroupDescription.$dirty) return errors;
            !this.$v.selectedGroupDescription.maxLength && errors.push('Maks. liczba znaków to 200');
            return errors;
        }
    },

    watch: {
        selectedGroup() {
            this.description = null;
            this.name = null;
        }
    },

    methods: {
        async sendPatchRequest() {
            if (!this.checkFormValidation()) return;
            this.isUpdating = true;

            const response = await groupsService.updateGroup({
                id: this.selectedGroup.id,
                name: this.selectedGroupName,
                description: this.selectedGroupDescription
            });

            if (response.status == 200) {
                await this.getUpdatedGroupsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isUpdating = false;
        },
        async sendDeleteRequest() {
            this.isDeleting = true;

            const response = await groupsService.deleteGroup(this.selectedGroup.id);

            if (response.status == 200) {
                await this.getUpdatedGroupsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isDeleting = false;

            this.$store.dispatch('closeDetails');
        },

        async getUpdatedGroupsData() {
            await this.$store.dispatch('getGroupsFromApi');
        },

        checkFormValidation() {
            this.$v.$touch();
            return !this.$v.$anyError;
        }
    }
};
</script>
