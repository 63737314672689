import { replaceObjectFields } from '../../_helpers/general';
import { jobsService } from '../../services/jobs.service';

const state = () => ({
    jobsData: {},
    jobsItems: [],
    jobsSortBy: '',
    jobsSortOrder: '',
    jobsSearch: '',
    isJobsDataLoading: false,
    jobsLastUpdateTime: '',

    preselectedJobId: null,
    selectedJobForDetails: null,
    isJobNewDataLoading: false,
    isJobRefetching: false,
    newJob: {
        file: [],
        hash: []
    },

    jobSchema: {
        attachments: [],
        created_at: null,
        description: '',
        done_at: null,
        id: null,
        materials: [],
        name: '',
        priority: '',
        processing_time: null,
        progress: null,
        started_at: null,
        state: '',
        type: '',
        updated_at: null,
        user: null
    }
});

// getters
const getters = {
    jobsData: (state) => {
        return state.jobsData;
    },

    jobsItems: (state) => {
        return state.jobsItems;
    },

    jobsSortBy: (state) => {
        return state.jobsSortBy;
    },

    jobsSortOrder: (state) => {
        return state.jobsSortOrder;
    },

    jobsSearch: (state) => {
        return state.jobsSearch;
    },

    selectedJob: (state) => {
        return state.selectedJobForDetails;
    },

    newJob: (state) => {
        return state.newJob;
    },

    isJobsDataLoading: (state) => {
        return state.isJobsDataLoading;
    },

    isJobNewDataLoading: (state) => {
        return state.isJobNewDataLoading;
    },

    isJobRefetching: (state) => {
        return state.isJobRefetching;
    },

    jobsLastUpdateTime: (state) => {
        return state.jobsLastUpdateTime;
    },

    preselectedJobId: (state) => {
        return state.preselectedJobId;
    }
};

// actions
const actions = {
    async getJobsFromApi({ commit, dispatch, state, rootState }) {
        const per_page = rootState.global.itemsPerPage;
        const page = state.jobsData.page;
        const sort = state.jobsSortBy;
        const order = state.jobsSortOrder;
        const search = state.jobsSearch;

        commit('isJobsDataLoadingChange');

        const response = await jobsService.getJobs({ per_page, page, sort, order, search });

        if (response.status !== 200) {
            commit('isJobsDataLoadingChange');
            return;
        }

        const { content, ...data } = response.data;

        commit('jobsData', data);
        commit('jobsItems', content);

        dispatch('autoRefreshResetTimeout');
        commit('isJobsDataLoadingChange');
    },

    async getJobById({ commit, state }, jobId) {
        if (state.isJobNewDataLoading) return;

        if (!state.isJobRefetching) {
            commit('setJobNewDataLoading', true);
        }

        const response = await jobsService.getJobById(jobId);

        if (response.status !== 200) {
            commit('resetJobForDetails');
            commit('setJobRefetching', false);
            commit('setJobNewDataLoading', false);
            return;
        }

        const jobData = { ...state.jobSchema, ...response.data };

        if (state.isJobRefetching) {
            commit('setJobForDetailsSilently', jobData);
        } else {
            commit('setJobForDetails', jobData);
        }
        commit('setJobRefetching', false);
        commit('setJobNewDataLoading', false);
    },

    resetJobsData({ commit }) {
        commit('resetJobsData');
    },

    setJobsPage({ commit }, page) {
        commit('setJobsPage', page);
    },

    setJobsSortBy({ commit }, fieldName) {
        commit('setJobsSortBy', fieldName);
    },

    setJobsSortOrder({ commit }, order) {
        commit('setJobsSortOrder', order);
    },

    setJobsSearch({ commit }, text) {
        commit('setJobsSearch', text);
    },

    setJobsLastUpdateTime({ commit }) {
        const dataTime = new Date().toLocaleString();
        commit('setJobsLastUpdateTime', dataTime);
    },

    setJobRefetch({ commit }) {
        commit('setJobRefetching', true);
    },

    addNewJob({ commit }, newJob) {
        commit('addNewJobHash', newJob);
    },

    addNewJobHash({ commit }, hash) {
        commit('addNewJobHash', hash);
    },

    addNewJobFile({ commit }, file) {
        commit('addNewJobFile', file);
    },

    setPreselectedJobId({ commit }, jobId) {
        commit('setPreselectedJobId', jobId);
    }
};

// mutations
const mutations = {
    jobsData(state, payload) {
        state.jobsData = payload;
    },

    jobsItems(state, payload) {
        state.jobsItems = payload;
    },

    resetJobsData(state) {
        state.jobsData = {};
        state.jobsItems = [];
    },

    setJobsLastUpdateTime(state, payload) {
        state.jobsLastUpdateTime = payload;
    },

    setJobsPage(state, payload) {
        state.jobsData.page = payload;
    },

    setJobsSortBy(state, payload) {
        state.jobsSortBy = payload;
    },

    setJobsSortOrder(state, payload) {
        state.jobsSortOrder = payload;
    },

    setJobsSearch(state, payload) {
        state.jobsSearch = payload;
    },

    resetJobForDetails(state) {
        state.selectedJobForDetails = null;
    },

    setJobForDetails(state, job) {
        state.selectedJobForDetails = job;
    },

    setJobForDetailsSilently(state, job) {
        replaceObjectFields(state.selectedJobForDetails, job);
    },

    addNewJobHash(state, hash) {
        state.newJob.hash = hash;
    },

    addNewJobFile(state, file) {
        state.newJob.file = file;
    },

    isJobsDataLoadingChange(state) {
        if (state.isJobsDataLoading == true) {
            setTimeout(function () {
                state.isJobsDataLoading = false;
            }, 750);
        } else {
            state.isJobsDataLoading = true;
        }
    },

    setJobNewDataLoading(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isJobNewDataLoading = false;
            }, 250);
        } else {
            state.isJobNewDataLoading = true;
        }
    },

    setJobRefetching(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isJobRefetching = false;
            }, 250);
        } else {
            state.isJobRefetching = true;
        }
    },

    setPreselectedJobId(state, jobId) {
        state.preselectedJobId = jobId;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
