<template>
    <Main-section :hideSecondaryCol="false">
        <template v-slot:left-col>
            <Groups-main-section></Groups-main-section>
        </template>
        <template v-slot:right-col>
            <Groups-hidden-section></Groups-hidden-section>
        </template>
    </Main-section>
</template>

<script>
import MainSection from '../global/MainSection.vue';
import GroupsHiddenSection from './subcomponents/GroupsHiddenSection.vue';
import GroupsMainSection from './subcomponents/GroupsMainSection.vue';

export default {
    name: 'GroupsIndex',

    components: {
        MainSection,
        GroupsMainSection,
        GroupsHiddenSection
    },

    data: () => {
        return {
            routeName: 'Groups'
        };
    },

    computed: {
        shouldAutoReload() {
            return this.$store.getters.autoRefreshData;
        },

        selectedGroup() {
            return this.$store.getters.selectedGroup;
        },

        preselectedGroupId() {
            return this.$store.getters.preselectedGroupId;
        }
    },

    watch: {
        shouldAutoReload(newBooleanValue) {
            if (newBooleanValue) {
                this.$store.dispatch('autoRefreshCallback', this.getAgentsData);
                this.getGroupsData();
            } else {
                this.$store.dispatch('autoRefreshCallback', null);
            }
        }
    },

    methods: {
        async getGroupsData() {
            await this.$store.dispatch('getGroupsFromApi');
            this.$store.dispatch('setGroupsLastUpdateTime');
            this.selectedGroup ? this.refreshSelectedGroup() : null;
        },

        refreshSelectedGroup() {
            if (this.preselectedGroupId) return;
            this.$store.dispatch('setGroupRefetch');
            this.$store.dispatch('getGroupById', this.selectedGroup.id);
        }
    },

    mounted() {
        this.getGroupsData();
        this.$store.dispatch('autoRefreshCallback', this.getGroupsData);
    },

    beforeDestroy() {
        this.$store.dispatch('resetGroupsData');
        this.$store.dispatch('autoRefreshCallback', null);
    }
};
</script>
