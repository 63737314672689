<template>
    <v-btn :class="btnClass" @click="copyToClipBoard">{{ btnText }}</v-btn>
</template>

<script>
export default {
    name: 'CopyToClipBoardBtn',

    props: {
        textToCopy: String,
        btnClass: {
            type: String,
            default: 'v-btn__primary'
        },
        btnText: String
    },

    methods: {
        copyToClipBoard() {
            navigator.clipboard.writeText(this.textToCopy);
        }
    }
};
</script>
