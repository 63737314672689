//import router from '../../router';

const state = () => ({
    showDetails: false,
    windowHeight: null,
    itemsPerPage: null,
    currentPath: null,

    autoRefreshData: true,
    autoRefreshCallback: null,
    autoRefreshInterval: 30000,
    autoRefreshTimeout: null,

    notifications: [],
    pauseNotifications: false
});

// getters
const getters = {
    showDetails: (state) => {
        return state.showDetails;
    },

    windowHeight: (state) => {
        return state.windowHeight;
    },

    itemsPerPage: (state) => {
        return state.itemsPerPage;
    },

    autoRefreshData: (state) => {
        return state.autoRefreshData;
    },

    autoRefreshInterval: (state) => {
        return state.autoRefreshInterval;
    },

    autoRefreshTimeout: (state) => {
        return state.autoRefreshTimeout;
    },

    notifications: (state) => {
        return state.notifications;
    }
};

// actions
const actions = {
    openDetails({ commit }) {
        commit('openDetails');
    },

    closeDetails({ commit }) {
        commit('closeDetails');
    },

    setWindowHeight({ commit }, windowHeight) {
        commit('setWindowHeight', windowHeight);
    },

    setItemsPerPage({ commit, state }) {
        let items = 1;

        const breakPoints = {
            540: 2,
            620: 3,
            700: 4,
            780: 5,
            840: 6,
            900: 7,
            1000: 8,
            1100: 9
        };

        Object.keys(breakPoints).forEach((point) => {
            if (state.windowHeight >= point) {
                items = breakPoints[point];
            }
        });

        commit('setItemsPerPage', items);
    },

    setCurrentRoutePath({ commit }, toPath) {
        commit('setCurrentRoutePath', toPath);
    },

    autoRefreshDataChange({ commit, state }) {
        commit('autoRefreshDataChange');
        if (state.autoRefreshData) {
            commit('autoRefreshSetTimeout');
        } else {
            commit('autoRefreshClearTimeout');
        }
    },

    autoRefreshResetTimeout({ commit, state }) {
        commit('autoRefreshClearTimeout');
        if (state.autoRefreshData) {
            commit('autoRefreshSetTimeout');
        }
    },

    autoRefreshCallback({ commit }, callback) {
        commit('autoRefreshCallback', callback);
    },

    autoRefreshIntervalChange({ commit }, ms) {
        commit('autoRefreshIntervalChange', ms);
    },

    addNotification({ commit }, notification) {
        commit('addNotification', notification);
    },

    removeNotification({ commit }, notification) {
        commit('removeNotification', notification);
    },

    pauseNotifications({ commit }) {
        commit('pauseNotifications', true);
    },

    startNotifications({ commit }) {
        commit('pauseNotifications', false);
    }
};

// mutations
const mutations = {
    openDetails(state) {
        state.showDetails = true;
    },

    closeDetails(state) {
        state.showDetails = false;
    },

    setWindowHeight(state, payload) {
        state.windowHeight = payload;
    },

    setItemsPerPage(state, payload) {
        state.itemsPerPage = payload;
    },

    setCurrentRoutePath(state, payload) {
        state.currentPath = payload.path;
    },

    autoRefreshDataChange(state) {
        state.autoRefreshData = !state.autoRefreshData;
    },

    autoRefreshCallback(state, payload) {
        state.autoRefreshCallback = payload;
    },

    autoRefreshSetTimeout(state) {
        state.autoRefreshTimeout = setTimeout(state.autoRefreshCallback, state.autoRefreshInterval);
    },

    autoRefreshClearTimeout(state) {
        clearTimeout(state.autoRefreshTimeout);
        state.autoRefreshTimeout = null;
    },

    autoRefreshIntervalChange(state, payload) {
        state.autoRefreshInterval = payload;
    },

    addNotification(state, payload) {
        if (state.pauseNotifications) return;
        state.notifications.push({
            id: `${Math.random()}${Date.now()}`,
            ...payload
        });
    },

    removeNotification(state, payload) {
        if (state.pauseNotifications) return;
        state.notifications = state.notifications.filter((notification) => {
            return notification.id != payload.id;
        });
    },

    pauseNotifications(state, payload) {
        state.pauseNotifications = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
