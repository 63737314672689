<template>
    <Attachments-index></Attachments-index>
</template>

<script>
import AttachmentsIndex from '../components/attachments/index';

export default {
    components: {
        AttachmentsIndex
    }
};
</script>
