<template>
    <div>
        <slot name="activator" :activate="activate"></slot>

        <v-dialog persistent content-class="dialog-container" v-model="dialog" :width="dialogWidth">
            <v-card>
                <div>
                    <v-card-title class="pa-2">
                        <v-container>
                            <v-row justify="space-between">
                                <v-col cols="auto">
                                    <h2 class="text_secondary sub-title">{{ title }}</h2>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn
                                        small
                                        icon
                                        @click="useSlotContentBtnToCloseDialog ? triggerSlotContentCancelBtnClick() : deactivate()"
                                    >
                                        <v-icon :color="CSS_COLOR.textPrimary" small> mdi-close </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>

                    <v-divider class="v-divider_light"></v-divider>

                    <slot name="content" :deactivate="deactivate"></slot>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../_helpers/consts';
export default {
    name: 'Dialog',

    props: {
        title: String,
        dialogWidth: {
            default: '40%',
            type: String
        },
        forceActivation: {
            default: false,
            type: Boolean
        },

        useSlotContentBtnToCloseDialog: {
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            CSS_COLOR,
            dialog: false
        };
    },
    methods: {
        activate() {
            this.dialog = true;
        },

        deactivate() {
            this.dialog = false;
            this.$emit('dialogClosed');
        },

        triggerSlotContentCancelBtnClick() {
            document.getElementsByClassName('js__close-btn')[0].click();
        }
    },

    watch: {
        forceActivation() {
            if (this.forceActivation) {
                this.activate();
            }
        }
    }
};
</script>
