var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-left",attrs:{"outlined":"","min-height":"100%","color":"transparent"}},[_c('v-row',[_vm._v(" "+_vm._s(_vm.selectedAgentResources.name)+" "),_c('v-col',{attrs:{"cols":"12"}},[_c('Data-table',{staticClass:"v-data-table_secondary v-data-table_narrow",attrs:{"headers":_vm.headers,"tableData":_vm.selectedAgentResources,"itemKey":'id',"noDataText":_vm.noDataText,"showSelect":false,"customTemplatesItemsNames":["item.name", "item.type", "item.actions"]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type.toUpperCase()))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(item.state))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"tag",class:_vm.tagClasses[item.state]},'span',attrs,false),on),[_vm._v(_vm._s(item.type.toUpperCase()))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.type.toUpperCase()))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(item.state))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openStructuresDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-card-search-outline ")])],1)]}}],null,true)})],1)],1),_c('Dialog',{attrs:{"dialogWidth":"25%","title":'Dostępne formaty',"forceActivation":_vm.showStructuresDialog},on:{"dialogClosed":function($event){_vm.showStructuresDialog = false}},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var deactivate = ref.deactivate;
return [_c('Agent-hashes-dialog',{attrs:{"selectedStructures":_vm.selectedStructures},on:{"deactivate":function($event){return deactivate()}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }