<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <v-row class="text-left">
                    <v-col cols="12">
                        <v-form class="mr-auto ml-auto ml-md-0">
                            <v-text-field
                                v-model="newPassword"
                                outlined
                                label="Nowe hasło:"
                                class="v-input_required"
                                :color="CSS_COLOR.textHighlight"
                                :type="show ? 'text' : 'password'"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :error-messages="newPasswordErrors"
                                @click:append="show = !show"
                                @input="$v.newPassword.$touch()"
                                @blur="$v.newPassword.$touch()"
                            ></v-text-field>
                            <v-text-field
                                v-model="confirmPassword"
                                outlined
                                label="Powtórz hasło:"
                                class="v-input_required"
                                :color="CSS_COLOR.textHighlight"
                                :type="show ? 'text' : 'password'"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="show = !show"
                                :error-messages="confirmPasswordErrors"
                                @input="$v.confirmPassword.$touch()"
                                @blur="$v.confirmPassword.$touch()"
                            ></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="closeDialog">Anuluj</v-btn>
                        <v-btn :loading="isUpdating" class="v-btn__primary" @click="submitNewPassword">Aktualizuj</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { usersService } from '../../../services/users.service';

export default {
    name: 'UserChangePasswordDialog',

    mixins: [validationMixin],

    validations: {
        newPassword: { required },
        confirmPassword: { required, sameAsNewPassword: sameAs('newPassword') }
    },

    data: () => ({
        CSS_COLOR,
        newPassword: '',
        confirmPassword: '',
        isUpdating: false,
        show: false
    }),

    computed: {
        newPasswordErrors() {
            const errors = [];
            if (!this.$v.newPassword.$dirty) return errors;
            !this.$v.newPassword.required && errors.push('Hasło jest wymagane');
            return errors;
        },

        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.confirmPassword.$dirty) return errors;
            !this.$v.confirmPassword.sameAsNewPassword && errors.push('Hasła muszą być takie same');
            !this.$v.confirmPassword.required && errors.push('Hasła muszą być takie same');

            return errors;
        },

        selectedUser() {
            return this.$store.getters.selectedUser;
        }
    },

    methods: {
        submitNewPassword() {
            if (!this.checkFormValidation()) {
                return;
            }

            this.sendPatchRequest();
        },

        async sendPatchRequest() {
            this.isUpdating = true;

            const response = await usersService.updateUserPassword(this.selectedUser, this.newPassword);

            if (response.status == 200) {
                this.closeDialog();
            }
            this.isUpdating = false;
        },

        closeDialog() {
            this.$emit('deactivate');
        },

        checkFormValidation() {
            this.$v.$touch();
            if (this.$v.newPassword.$anyError || this.$v.confirmPassword.$anyError) {
                return false;
            }
            return true;
        }
    }
};
</script>
