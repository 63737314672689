<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <v-row>
            <v-col cols="12" class="pt-0">
                <Data-table
                    :headers="headers"
                    :tableData="selectedGroupAgents"
                    itemKey="id"
                    :noDataText="noDataText"
                    :showSelect="false"
                    :customTemplatesItemsNames="[`item.actions`]"
                    :page.sync="page"
                    :itemsPerPage="itemsPerPage"
                    @page-count="pageCount = $event"
                    class="v-data-table_secondary v-data-table_narrow"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon @click="openAgentDetails(item)">
                            <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                    </template>
                </Data-table>
                <v-pagination v-if="pageCount > 1" v-model="page" :total-visible="3" :length="pageCount" class="mt-2"></v-pagination>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import DataTable from '../../global/DataTable.vue';

export default {
    components: { DataTable },
    name: 'GroupUsers',

    data: () => ({
        headers: [
            { text: 'Id', value: 'id', width: 70 },
            { text: 'Nazwa', value: 'name' },
            { text: 'Akcje', value: 'actions', sortable: false, width: 75 }
        ],
        noDataText: 'Brak agentów do wyświetlenia',

        page: 1,
        pageCount: 0,
        itemsPerPage: 10
    }),

    computed: {
        selectedGroup() {
            return this.$store.getters.selectedGroup;
        },

        selectedGroupAgents() {
            return this.selectedGroup?.agents ?? [];
        }
    },

    methods: {
        openAgentDetails(agent) {
            this.$store.dispatch('setPreselectedAgentId', agent.id);
            this.$router.push({ path: '/agenci' });
        }
    }
};
</script>
