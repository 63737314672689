<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <div>
            <v-row>
                <v-col cols="12">
                    <v-icon class="pr-2 v-icon_highlight"> mdi-file-outline </v-icon>
                    <span>{{ selectedAttachment.name }}</span>
                </v-col>
            </v-row>
            <v-row class="text-left">
                <v-col cols="12">
                    <v-form class="mr-auto ml-auto ml-md-0">
                        <v-text-field
                            v-model="selectedAttachmentName"
                            :disabled="isDeleted"
                            outlined
                            :color="CSS_COLOR.textHighlight"
                            label="Nazwa:"
                            :error-messages="nameErrors"
                            required
                            @input="$v.selectedAttachmentName.$touch()"
                            @blur="$v.selectedAttachmentName.$touch()"
                            class="v-input_required"
                        >
                        </v-text-field>

                        <v-textarea
                            v-model="selectedAttachmentDescription"
                            :disabled="isDeleted"
                            class="v-input_full-width"
                            label="Opis:"
                            height="95px"
                            outlined
                            :no-resize="true"
                        ></v-textarea>

                        <v-row class="justify-end">
                            <v-col v-if="isDeleted" cols="12">
                                <v-alert type="warning" outlined dense>Ten słownik został usunięty</v-alert>
                            </v-col>
                            <v-col v-else cols="auto">
                                <v-btn :loading="isUpdating" class="v-btn__primary" @click="sendPatchRequest">Aktualizuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>

            <v-row class="text-left">
                <v-col cols="12">
                    <p class="mb-1 text_primary">Dane techniczne:</p>
                </v-col>
            </v-row>
            <v-divider class="v-divider_light mb-8"></v-divider>
            <v-card outlined color="transparent" class="text-left">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <span>Typ: </span>
                        <span class="text_primary">{{ selectedAttachment.type }}</span>
                    </v-col>
                    <v-col cols="7">
                        <span>Data dodania: </span>
                        <span class="text_primary">{{ selectedAttachmentCreatedAt }}</span>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <span>Status: </span>
                        <span class="text_primary">{{ visibilityTranslations[selectedAttachment.visibility] }}</span>
                    </v-col>
                    <v-col cols="7">
                        <span>Właściciel: </span>
                        <template v-if="isSelectedAttachmentUserAvailable">
                            <span class="text_primary">{{ selectedAttachmentUser.login }} </span>
                            <v-btn v-if="isAdminLogged" icon @click="openUserDetails(selectedAttachmentUser)"
                                ><v-icon :color="CSS_COLOR.textPrimary">mdi-open-in-new</v-icon>
                            </v-btn>
                        </template>
                        <span v-else class="text_primary font-italic">brak użytkownika</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col grow> </v-col>

                    <v-col grow> </v-col>
                </v-row>
                <v-row v-if="!isDeleted" class="justify-space-between">
                    <v-col cols="auto">
                        <v-row>
                            <v-col cols="auto" class="pr-0">
                                <v-btn class="v-btn__primary" :href="selectedAttachmentDownloadHref">
                                    <v-icon class="mb-1 mr-2"> mdi-arrow-down-circle-outline </v-icon>
                                    <span>Pobierz</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto" v-if="!isPublic">
                                <span>
                                    <template v-if="!isAdminLogged">
                                        <v-btn
                                            v-if="
                                                selectedAttachmentPublicationState == 'rejected' ||
                                                selectedAttachmentPublicationState == 'unreported'
                                            "
                                            class="v-btn__success mb-2"
                                            @click="showReportDialog = true"
                                        >
                                            <v-icon class="mb-1 mr-2"> mdi-check-circle-outline </v-icon>
                                            <span>Zgłoś do publikacji</span>
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <template v-if="selectedAttachmentPublicationState == 'reported'">
                                            <v-btn class="v-btn__success mb-2" @click="showPublicityDialog = true">
                                                <v-icon class="mb-1 mr-2"> mdi-check-circle-outline </v-icon>
                                                <span>Opublikuj</span>
                                            </v-btn>
                                            <br />
                                            <v-btn class="v-btn__alert" @click="showRejectDialog = true">
                                                <v-icon class="mb-1 mr-2"> mdi-close-circle-outline </v-icon>
                                                <span class="pl-2 pr-3">Odrzuć</span>
                                            </v-btn>
                                        </template>
                                        <template v-else>
                                            <v-btn class="v-btn__success mb-2" @click="showPublicityDialog = true">
                                                <v-icon class="mb-1 mr-2"> mdi-check-circle-outline </v-icon>
                                                <span>Opublikuj</span>
                                            </v-btn>
                                        </template>
                                    </template>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="auto">
                        <v-btn v-if="!isPublic" class="v-btn__alert" @click="showRemovingDialog = true">Usuń</v-btn>

                        <v-tooltip v-else bottom>
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn disabled>Usuń</v-btn>
                                </div>
                            </template>
                            <span>Nie można usunąć publicznego słownika</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <Dialog dialogWidth="30%" :title="'Usuwanie słownika'" :forceActivation="showRemovingDialog" @dialogClosed="showRemovingDialog = false">
            <template v-slot:content="{ deactivate }">
                <Attachment-removing-dialog :attachment="selectedAttachment" @deactivate="deactivate()"></Attachment-removing-dialog>
            </template>
        </Dialog>

        <Confirmation-dialog
            :cancelButtonText="'Anuluj'"
            :confirmationButtonText="'Opublikuj'"
            :isQuestionHtmlContent="true"
            :questionContent="`<h2 class='text_primary text-center mb-2'>Czy na pewno chcesz opublikować wybrany słownik ?</h2>`"
            :dialogWidth="'30%'"
            :title="'Publikacja słownika'"
            :confirmationButtonClass="'v-btn__success'"
            v-model="showPublicityDialog"
            @confirmEvent="publicAttachment"
        >
        </Confirmation-dialog>

        <Confirmation-dialog
            :cancelButtonText="'Anuluj'"
            :confirmationButtonText="'Odrzuć'"
            :isQuestionHtmlContent="true"
            :questionContent="`<h2 class='text_primary text-center mb-2'>Czy na pewno chcesz odrzucić wybrany słownik ?</h2>`"
            :dialogWidth="'30%'"
            :title="'Odrzucenie słownika'"
            :confirmationButtonClass="'v-btn__alert'"
            v-model="showRejectDialog"
            @confirmEvent="rejectAttachment"
        >
        </Confirmation-dialog>

        <Confirmation-dialog
            :cancelButtonText="'Anuluj'"
            :confirmationButtonText="'Zgłoś'"
            :isQuestionHtmlContent="true"
            :questionContent="`<h2 class='text_primary text-center mb-2'>Czy na pewno chcesz zgłosić do publikacji wybrany słownik ?</h2>`"
            :dialogWidth="'30%'"
            :title="'Zgłoszenie słownika'"
            :confirmationButtonClass="'v-btn__alert'"
            v-model="showReportDialog"
            @confirmEvent="reportAttachment"
        >
        </Confirmation-dialog>
    </v-card>
</template>

<script>
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { attachmentsService } from '../../../services/attachments.service';
import Dialog from '../../global/Dialog.vue';
import ConfirmationDialog from '../../global/ConfirmationDialog.vue';
import AttachmentRemovingDialog from './AttachmentRemovingDialog.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AttachmentDetails',

    mixins: [validationMixin],

    validations: {
        selectedAttachmentName: { required }
    },

    components: {
        Dialog,
        AttachmentRemovingDialog,
        ConfirmationDialog
    },

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,
        name: null,
        description: null,
        isUpdating: false,
        showRemovingDialog: false,
        showPublicityDialog: false,
        showRejectDialog: false,
        showReportDialog: false,
        visibilityTranslations: {
            private: 'prywatny',
            public: 'publiczny'
        }
    }),

    computed: {
        selectedAttachment() {
            return this.$store.getters.selectedAttachment;
        },

        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        selectedAttachmentDownloadHref() {
            return attachmentsService.downloadAttachment(this.selectedAttachment.id);
        },

        selectedAttachmentPublicationState() {
            return this.$store.getters.selectedAttachment.publication_state;
        },

        selectedAttachmentName: {
            get() {
                return this.name === null ? this.selectedAttachment.name : this.name;
            },
            set(value) {
                this.name = value;
            }
        },

        isPublic() {
            return this.selectedAttachment.visibility == 'public';
        },

        isDeleted() {
            return this.selectedAttachment.state == 'deleted';
        },

        selectedAttachmentDescription: {
            get() {
                return this.description === null ? this.selectedAttachment.description : this.description;
            },
            set(value) {
                this.description = value;
            }
        },

        selectedAttachmentCreatedAt() {
            return this.formatDateFrom1970(this.selectedAttachment.created_at);
        },

        isSelectedAttachmentUserAvailable() {
            return this.selectedAttachment.user ? true : false;
        },

        selectedAttachmentUser() {
            return this.selectedAttachment.user;
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.selectedAttachmentName.$dirty) return errors;
            !this.$v.selectedAttachmentName.required && errors.push('Nazwa jest wymagana');
            return errors;
        }
    },

    watch: {
        selectedAttachment() {
            this.description = null;
            this.name = null;
        }
    },

    methods: {
        async sendPatchRequest() {
            this.isUpdating = true;

            const response = await attachmentsService.updateAttachment({
                name: this.selectedAttachmentName,
                description: this.selectedAttachmentDescription,
                id: this.selectedAttachment.id
            });

            if (response.status == 200) {
                await this.getUpdatedAttachmentsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isUpdating = false;
        },

        async getUpdatedAttachmentsData() {
            await this.$store.dispatch('getAttachmentsFromApi');
            this.$store.dispatch('getAttachmentById', this.selectedAttachment.id);
        },

        formatDateFrom1970(date) {
            return date ? new Date(date).toLocaleDateString('en-UK') : '';
        },

        async downloadAttachment() {
            const attachmentId = this.selectedAttachment?.id;
            await fetch(attachmentsService.downloadAttachment(attachmentId));
        },

        async publicAttachment() {
            const attachment = this.selectedAttachment;

            if (this.selectedAttachmentPublicationState !== 'reported') {
                const reportResponse = await attachmentsService.reportAttachment(attachment);
                if (reportResponse.status != 200) return;
            }

            const response = await attachmentsService.publicAttachment(attachment);

            if (response.status == 200 || response.status == 201) {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Słownik został opublikowany'));
                await this.getUpdatedAttachmentsData();
            }
        },

        async reportAttachment() {
            const attachment = this.selectedAttachment;

            const response = await attachmentsService.reportAttachment(attachment);

            if (response.status == 200) {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Słownik został zgłoszony do publikacji'));
                await this.getUpdatedAttachmentsData();
            }
        },

        async rejectAttachment() {
            const attachment = this.selectedAttachment;

            const response = await attachmentsService.rejectAttachment(attachment);

            if (response.status == 200) {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Słownik został odrzucony'));
                await this.getUpdatedAttachmentsData();
                return response;
            }
        },

        checkFormValidation() {
            this.$v.$touch();

            return !this.$v.$anyError;
        },

        openUserDetails(user) {
            this.$store.dispatch('setPreselectedUserId', user.id);
            this.$router.push({ path: '/uzytkownicy' });
        }
    }
};
</script>
