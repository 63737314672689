<template>
    <div class="network-container" ref="networkContainer">
        <div v-if="showMaxAgentsAlert" class="overlay-alert">
            <v-alert dense type="warning">Rysunek ograniczono do 100 agentów</v-alert>
        </div>
        <div v-if="isLoading && loadingCounter <= 1">
            <v-progress-linear indeterminate :color="CSS_COLOR.textSecondary" class="mb-0"> </v-progress-linear>
        </div>
        <div v-else>
            <network
                :events="['selectNode', 'stabilizationIterationsDone', 'hoverNode', 'blurNode']"
                @select-node="onNodeSelected"
                @hover-node="onNodeHover"
                @blur-node="onNodeBlur"
                @stabilization-iterations-done="resizeNetwork"
                ref="network"
                :nodes="nodes"
                :edges="edges"
                :options="options"
            ></network>
        </div>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';

export default {
    name: 'SystemStatusGraph',

    data: () => ({
        loadingCounter: 0,
        CSS_COLOR,
        hiddenSectionWidth: 640,
        selectedNode: null,
        imgsUrl: {
            main: require('@/assets/images/main-active.png'),
            agent: {
                connected: require('@/assets/images/agent-active.png'),
                disconnected: require('@/assets/images/agent-inactive.png'),
                busy: require('@/assets/images/agent-busy.png'),
                working: require('@/assets/images/agent-busy.png'),
                service: require('@/assets/images/agent-service.png')
            },
            resources: {
                cpu: {
                    idle: require('@/assets/images/cpu-active.png'),
                    disconnected: require('@/assets/images/cpu-inactive.png'),
                    busy: require('@/assets/images/cpu-busy.png'),
                    working: require('@/assets/images/cpu-busy.png')
                },
                gpu: {
                    idle: require('@/assets/images/gpu-active.png'),
                    disconnected: require('@/assets/images/gpu-inactive.png'),
                    busy: require('@/assets/images/gpu-busy.png'),
                    working: require('@/assets/images/gpu-busy.png')
                },
                fpga: {
                    idle: require('@/assets/images/fpga-active.png'),
                    disconnected: require('@/assets/images/fpga-inactive.png'),
                    busy: require('@/assets/images/fpga-busy.png'),
                    working: require('@/assets/images/fpga-busy.png')
                }
            }
        },
        options: {
            physics: {
                enabled: false,
                solver: 'repulsion',
                repulsion: {
                    nodeDistance: 400
                }
            },
            autoResize: true,
            height: '100%',
            width: '100%',
            interaction: { hover: true },
            nodes: {
                fixed: true,
                font: {
                    color: '#575F7E',
                    size: 14
                }
                // chosen: {
                //     node: function (values, id, selected, hovering) {
                //     }
                // }
            },

            layout: {
                hierarchical: {
                    enabled: true,
                    levelSeparation: 250,
                    nodeSpacing: 175
                }
            },
            edges: {
                color: { color: CSS_COLOR.textPrimary },
                chosen: {
                    edge: function (values) {
                        if (this.from.hover || this.to.hover || this.from.selected || this.to.selected) {
                            values.color = 'white';
                            (values.width = 2), 5;
                        }
                    }
                },
                font: { align: 'top', size: 10, color: CSS_COLOR.textPrimary, strokeWidth: 0 }
            }
        }
    }),

    computed: {
        storedAgents() {
            return this.$store.getters.agentsItems;
        },

        showMaxAgentsAlert() {
            return this.storedAgents.length > 100;
        },

        isLoading() {
            return this.$store.getters.isAgentsDataLoading;
        },

        showDetails() {
            return this.$store.getters.showDetails;
        },

        formattedAgents() {
            const formattedAgents = JSON.parse(JSON.stringify(this.storedAgents));

            formattedAgents.forEach((agent) => {
                agent['created_at'] = this.formatDateFrom1970(agent['created_at']);
            });

            return formattedAgents;
        },

        allAgentsResources() {
            let resources = [];
            this.storedAgents.forEach((agent) => {
                agent.resources.forEach((resource) => {
                    resources.push({ agentId: agent.id, ...resource });
                });
            });
            return resources;
        },

        nodes() {
            const graphNodes = [
                {
                    id: `main-0`,
                    shape: 'image',
                    image: this.imgsUrl.main,
                    label: 'Zarządca',
                    size: 70
                }
            ];

            this.storedAgents.forEach((agent) => {
                graphNodes.push({
                    id: `agent-${agent.id}`,
                    label: agent.name,
                    shape: 'image',
                    size: 40,
                    widthConstraint: { maximum: 110 },

                    image: this.findImagePath('agent', agent)
                });
            });

            this.allAgentsResources.forEach((resource) => {
                graphNodes.push({
                    id: `resource-${resource.id}`,
                    label: resource.name,
                    shape: 'image',
                    size: 20,
                    widthConstraint: { maximum: 110 },
                    image: this.findImagePath('resource', resource)
                });
            });

            return graphNodes;
        },

        edges() {
            const graphEdges = [];
            this.storedAgents.forEach((agent) => {
                graphEdges.push({ from: `agent-${agent.id}`, to: 'main-0', label: agent.ip });
            });
            this.allAgentsResources.forEach((resource) => {
                graphEdges.push({
                    from: `resource-${resource.id}`,
                    to: `agent-${resource.agentId}`
                });
            });

            return graphEdges;
        }
    },

    mounted() {
        this.options.height = `${document.getElementsByClassName('network-container')[0].clientHeight}px`;
        this.options.width = `${window.innerWidth - this.hiddenSectionWidth - 1}px`;
    },

    methods: {
        onNodeSelected(params) {
            const clickedItem = params.nodes[0];
            const clickedItemId = clickedItem.substr(clickedItem.indexOf('-') + 1);
            let selectedAgentId;

            if (clickedItem.startsWith('agent')) {
                selectedAgentId = clickedItemId;
                this.$emit('showAgentAndResourceInfo', 0);
            } else if (clickedItem.startsWith('resource')) {
                selectedAgentId = this.findSelectedAgentByResourceId(clickedItemId);
                this.$emit('showAgentAndResourceInfo', 1);
            } else if (clickedItem.startsWith('main')) {
                this.$emit('showMainControllerInfo');
            } else {
                return;
            }

            this.selectedNode = clickedItem;
            this.$store.dispatch('openDetails');
            if (selectedAgentId) {
                this.$store.dispatch('getAgentById', selectedAgentId);
            }
        },

        findSelectedAgentByResourceId(id) {
            const resource = this.allAgentsResources.find((resource) => resource.id == id);
            return resource.agentId;
        },

        findImagePath(type, item) {
            if (type === 'agent' && !item.state) {
                return this.imgsUrl.agent[item.service ? 'service' : 'disconnected'];
            }

            switch (type) {
                case 'agent':
                    return this.imgsUrl.agent[item.service ? 'service' : item.state];
                case 'resource':
                    return this.imgsUrl.resources[item.type][item.state];
            }
        },

        resizeNetwork() {
            this.options.width = `${window.innerWidth - this.hiddenSectionWidth}px`;
        },

        onNodeHover() {
            document.body.style.cursor = 'pointer';
            // this.options.physics = { enabled: true };
        },

        onNodeBlur() {
            document.body.style.cursor = 'default';
        }
    },

    watch: {
        isLoading(newVal) {
            this.loadingCounter++;

            if (newVal || !this.selectedNode) return;
            this.$refs.network.setSelection({
                nodes: [this.selectedNode],
                unselectAll: true,
                highlightEdges: true
            });
        },
        showDetails(newVal) {
            if (!newVal) {
                this.selectedNode = null;
                this.$refs.network.unselectAll();
            }
        }
    }
};
</script>
