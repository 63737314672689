<template>
    <v-app>
        <MainHeader v-if="hasHeader"></MainHeader>

        <Notifications-list></Notifications-list>

        <!-- Sizes your content based upon application components -->
        <v-main :class="{ 'has-footer': hasFooter }">
            <!-- Provides the application the proper gutter -->
            <!-- If using vue-router -->
            <router-view></router-view>
        </v-main>

        <MainFooter v-if="hasFooter"></MainFooter>
    </v-app>
</template>

<script>
import MainHeader from './components/global/MainHeader.vue';
import MainFooter from './components/global/MainFooter.vue';
import NotificationsList from './components/global/NotificationsList.vue';

export default {
    name: 'App',
    components: {
        MainHeader,
        MainFooter,
        NotificationsList
    },

    data: () => ({
        viewsWithFooter: ['Login'],
        viewsWithHeader: ['Home', 'Jobs', 'Agents', 'Users', 'Groups', 'Dictionaries', 'Profile', 'SystemStatus']
    }),

    computed: {
        hasHeader() {
            return this.viewsWithHeader.includes(this.$route.name);
        },

        hasFooter() {
            return this.viewsWithFooter.includes(this.$route.name);
        }
    },

    created() {
        if (this.$route.name != 'Login') {
            this.$store.dispatch('getLoggedUserFromApi');
        }
    }
};
</script>
