<template>
    <div>
        <v-toolbar class="navbar" elevation="4" color="transparent">
            <v-toolbar-title>
                <router-link :to="homePagePath.path">
                    <v-img max-height="42" contain src="@/assets/images/cyber-crypt-logo.png"></v-img>
                </router-link>
            </v-toolbar-title>

            <v-toolbar-items class="hidden-sm-and-down navbar__item">
                <v-btn
                    v-for="routerLink in navbarFilteredItemsByAdminRights"
                    :key="routerLink.name"
                    :to="routerLink.path"
                    :ripple="false"
                    active-class="navbar__btn_active"
                    plain
                    class="navbar__btn body-1 text-capitalize"
                    >{{ routerLink.name }}</v-btn
                >
                <v-btn
                    v-if="isAdminLogged"
                    :href="monitoringDashboardUrl"
                    :ripple="false"
                    target="_blank"
                    active-class="navbar__btn_active"
                    plain
                    class="navbar__btn body-1 text-capitalize"
                    >Monitoring</v-btn
                >
            </v-toolbar-items>

            <v-spacer></v-spacer>

            <v-menu :close-on-content-click="false" left offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon :color="CSS_COLOR.textHighlight" v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-action>
                                <v-switch :input-value="autoRefreshData" @change="changeAutoRefreshDataState"> </v-switch>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>Odświeżaj automatycznie</v-list-item-title>
                                <v-list-item-subtitle v-if="![homePagePath.path, userPagePath.path].includes($route.path)">
                                    Czas ostatniej aktualizacji: {{ lastUpdateTime }}</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu left offset-y transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon :color="CSS_COLOR.textHighlight" v-bind="attrs" v-on="on">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-list class="text-start">
                    <v-list-item link :to="userPagePath.path">
                        <!-- <v-list-item link> -->
                        <v-list-item-title>Profil</v-list-item-title>
                    </v-list-item>
                    <v-list-item link v-on:click="logout">
                        <v-list-item-title>Wyloguj się</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../_helpers/consts';
import { authorizationService } from '../../services/authorization.service';

export default {
    name: 'MainHeader',

    data() {
        return {
            CSS_COLOR,
            navbarItems: [
                { name: 'Zlecenia', path: '/zlecenia', visibilityForAllUsers: true },
                { name: 'Słowniki', path: '/slowniki', visibilityForAllUsers: true },
                { name: 'Użytkownicy', path: '/uzytkownicy', visibilityForAllUsers: false },
                { name: 'Agenci', path: '/agenci', visibilityForAllUsers: false },
                { name: 'Grupy', path: '/grupy', visibilityForAllUsers: false },
                { name: 'Status Systemu', path: '/status-systemu', visibilityForAllUsers: false }
            ],
            homePagePath: { name: '', path: '/' },
            userPagePath: { name: 'Profil', path: '/profil' },
            monitoringDashboardUrl: 'https://cybercrypt-monitoring.redgelabs.com/login'
        };
    },

    methods: {
        logout() {
            authorizationService.logout();
        },

        changeAutoRefreshDataState() {
            this.$store.dispatch('autoRefreshDataChange');
        }
    },

    computed: {
        autoRefreshData() {
            return this.$store.getters.autoRefreshData;
        },

        lastUpdateTime() {
            const times = {
                Jobs: this.$store.getters.jobsLastUpdateTime,
                Dictionaries: this.$store.getters.attachmentsLastUpdateTime,
                Users: this.$store.getters.usersLastUpdateTime,
                Agents: this.$store.getters.agentsLastUpdateTime,
                Groups: this.$store.getters.groupsLastUpdateTime,
                SystemStatus: this.$store.getters.agentsLastUpdateTime
            };

            return times[this.$route.name];
        },

        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        navbarFilteredItemsByAdminRights() {
            if (this.isAdminLogged) {
                return this.navbarItems;
            } else {
                return this.navbarItems.filter((item) => item.visibilityForAllUsers == true);
            }
        }
    },

    watch: {
        $route() {
            this.$store.dispatch('closeDetails');
        }
    }
};
</script>
