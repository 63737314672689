<template>
    <div>
        <System-status-details v-if="isMainControllerSelected"></System-status-details>
        <Agents-hidden-section v-else :preSelectedTab="preSelectedTab"></Agents-hidden-section>
    </div>
</template>

<script>
import AgentsHiddenSection from '../../agents/subcomponents/AgentsHiddenSection.vue';
import SystemStatusDetails from './SystemStatusDetails.vue';
export default {
    components: { AgentsHiddenSection, SystemStatusDetails },
    name: 'SystemStatusHiddenSection',
    props: {
        preSelectedTab: {
            type: Number,
            default: 0
        },
        isMainControllerSelected: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({})
};
</script>
