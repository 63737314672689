import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';
import { generateQueryString } from '../_helpers/general';

// for stopping the send chunk session request, used in send and stop methods
let chunkSessionController;

export const attachmentsService = {
    getAttachments,
    getAttachmentById,
    getAttachmentRequests,
    postNewAttachment,
    updateAttachment,
    deleteAttachment,
    rejectAttachment,
    reportAttachment,
    publicAttachment,
    downloadAttachment,
    createUploadSession,
    sendChunkSession,
    commitUploadSession,
    cancelUploadSession,
    destroyUploadFile,
    //
    stopChunkSessionRequest
};

async function getAttachments(queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const queryString = generateQueryString(queryObj);

    const response = await fetch(`${config.apiUrl}/${config.attachmentsUrl}${queryString}`, requestOptions);
    const attachments = await globalService.handleResponse(response);

    return attachments;
}

async function getAttachmentById(id) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentsUrl}/${id}`, requestOptions);
    const attachment = await globalService.handleResponse(response);

    return attachment;
}

async function getAttachmentRequests(attachmentId, queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const queryString = generateQueryString(queryObj, { startWithFirstParam: false });

    const response = await fetch(`${config.apiUrl}/${config.attachmentRequestsUrl}${attachmentId}${queryString}`, requestOptions);
    const attachmentRequests = await globalService.handleResponse(response);
    return attachmentRequests;
}

async function postNewAttachment(newAttachment) {
    let jsonDate;

    try {
        jsonDate = JSON.stringify(newAttachment);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentsUploadUrl}`, requestOptions);
    const addedAttachment = await globalService.handleResponse(response);

    return addedAttachment;
}

async function updateAttachment(attachment) {
    const updatedData = {
        name: attachment.name,
        description: attachment.description
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentsUrl}/${attachment.id}`, requestOptions);
    const updatedAttachment = await globalService.handleResponse(response);

    return updatedAttachment;
}

async function deleteAttachment(attachmentId) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentsUrl}/${attachmentId}`, requestOptions);
    const deletedAttachment = await globalService.handleResponse(response);

    return deletedAttachment;
}

async function rejectAttachment(attachment) {
    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentRejectUrl(attachment.id)}`, requestOptions);
    const updatedAttachment = await globalService.handleResponse(response);

    return updatedAttachment;
}
async function reportAttachment(attachment) {
    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentReportUrl(attachment.id)}`, requestOptions);
    const updatedAttachment = await globalService.handleResponse(response);

    return updatedAttachment;
}

async function publicAttachment(attachment) {
    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentPublicUrl(attachment.id)}`, requestOptions);
    const updatedAttachment = await globalService.handleResponse(response);

    return updatedAttachment;
}

function downloadAttachment(attachmentId) {
    return `${config.apiUrl}/${config.attachmentDownloadUrl(attachmentId)}`;
}

async function createUploadSession(file) {
    const uploadedFile = {
        fileName: file.name,
        fileSize: file.size
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(uploadedFile);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentCreateUploadSessionUrl}`, requestOptions);
    const handledResponse = await globalService.handleResponse(response);
    const sessionId = handledResponse.data.id;

    return sessionId;
}

async function sendChunkSession(chunk, sessionId) {
    chunkSessionController = new AbortController();
    const signal = chunkSessionController.signal;

    const requestOptions = {
        method: 'PUT',
        signal: signal,
        headers: {
            'Content-Type': 'application/octet-stream',
            'X-OFFSET': chunk.offset,
            'X-SIZE': chunk.size,
            'X-CRC32': chunk.checksum
        },
        credentials: 'include',
        body: chunk.content
    };

    let response;
    try {
        response = await fetch(`${config.apiUrl}/${config.attachmentChunkUploadUrl(sessionId)}`, requestOptions);
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn('Użytkownik przerwał wysyłanie pliku.');
            return;
        }
        console.error(error.message);
    }
    // const sessionId = await globalService.handleResponse(response);

    return response;
}

async function commitUploadSession(sessionId) {
    const requestOptions = {
        method: 'PUT',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentUploadSessionCommitUrl(sessionId)}`, requestOptions);
    const commitData = await globalService.handleResponse(response);
    // const sessionId = await globalService.handleResponse(response);

    return commitData;
}

async function cancelUploadSession(sessionId) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentUploadSessionCancelUrl(sessionId)}`, requestOptions);
    const responseData = await globalService.handleResponse(response);

    return responseData;
}

async function destroyUploadFile(sessionId) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.attachmentUploadSessionDestroyUrl(sessionId)}`, requestOptions);
    const responseData = await globalService.handleResponse(response);

    return responseData;
}

function stopChunkSessionRequest() {
    if (chunkSessionController) {
        chunkSessionController.abort();
    }
}
