<template>
    <div>
        <v-card-text>
            <v-container class="pl-16 pr-16">
                <v-row class="justify-center">
                    <v-col cols="12">
                        <h2 class="text_primary text-center mb-2">Czy na pewno chcesz usunąć wybrany słownik:</h2>
                        <h2 class="text_secondary text-center">{{ attachment.name }} <span class="text_primary">?</span></h2>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('deactivate')">Anuluj</v-btn>
                        <v-btn class="v-btn__alert mr-4" :loading="isDeleting" @click="deleteAttachment">Usuń</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { NOTIFICATIONS } from '../../../_helpers/consts';
import { attachmentsService } from '../../../services/attachments.service';

export default {
    name: 'AttachmentRemovingDialog',

    data() {
        return {
            NOTIFICATIONS,
            isDeleting: false
        };
    },

    props: {
        attachment: {
            type: Object,
            required: true
        }
    },

    computed: {},

    methods: {
        deactivate() {
            this.$emit('deactivate');
        },

        closeDetails() {
            this.$store.dispatch('closeDetails');
        },

        async deleteAttachment() {
            this.isDeleting = true;

            const response = await attachmentsService.deleteAttachment(this.attachment.id);

            if (response.status == 200) {
                await this.getAttachmentsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Słownik został usunięty'));
                this.deactivate();
                this.closeDetails();
            } else {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.alert('Nie można usunąć słownika'));
            }
            this.isDeleting = false;
        },

        async getAttachmentsData() {
            await this.$store.dispatch('getAttachmentsFromApi');
        }
    }
};
</script>
