<template>
    <div>
        <v-row class="text-left">
            <v-col cols="12">
                <v-form class="mr-auto ml-auto ml-md-0">
                    <v-text-field
                        @change="updateFormData"
                        v-model="newHash.name"
                        outlined
                        class="v-input_required"
                        label="Tytuł:"
                        :color="CSS_COLOR.textHighlight"
                        :error-messages="nameErrors"
                        @input="$v.newHash.name.$touch()"
                        @blur="$v.newHash.name.$touch()"
                    ></v-text-field>

                    <v-select
                        @change="updateFormData"
                        v-model="newHash.type"
                        class="v-input_required"
                        label="Typ skrótu:"
                        attach
                        outlined
                        :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options', maxHeight: '240px' }"
                        :color="CSS_COLOR.textHighlight"
                        :items="storedAlgorithms"
                        :error-messages="valueErrors"
                        @input="$v.newHash.name.$touch()"
                        @blur="$v.newHash.name.$touch()"
                    ></v-select>

                    <v-textarea
                        @change="updateFormData"
                        v-model="newHash.value"
                        class="v-input_full-width v-input_required"
                        label="Wartość skrótu:"
                        height="95px"
                        outlined
                        :no-resize="true"
                        :error-messages="valueErrors"
                        @input="$v.newHash.name.$touch()"
                        @blur="$v.newHash.name.$touch()"
                    ></v-textarea>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'JobAddHashForm',

    mixins: [validationMixin],

    validations: {
        newHash: {
            name: { required },
            type: { required },
            value: { required }
        }
    },

    props: {},

    data() {
        return {
            CSS_COLOR,
            newHash: {
                name: null,
                type: null,
                value: null
            }
        };
    },

    computed: {
        storedAlgorithms() {
            return this.$store.getters.algorithmsData;
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.newHash.name.$dirty) return errors;
            !this.$v.newHash.name.required && errors.push('Tytuł jest wymagany');
            return errors;
        },

        typeErrors() {
            const errors = [];
            if (!this.$v.newHash.type.$dirty) return errors;
            !this.$v.newHash.type.required && errors.push('Pole nie może być puste');
            return errors;
        },

        valueErrors() {
            const errors = [];
            if (!this.$v.newHash.value.$dirty) return errors;
            !this.$v.newHash.value.required && errors.push('Pole nie może być puste');
            return errors;
        }
    },

    methods: {
        updateFormData() {
            this.$emit('formUpdated', this.newHash);
        },

        checkFormValidation() {
            this.$v.$touch();
            return !this.$v.$anyError;
        }
    }
};
</script>
