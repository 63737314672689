export const CSS_COLOR = {
    textHighlight: '#3985EB',
    textPrimary: '#8d93a3',

    linesColorTertiary: '#464a5a',

    colorAlert: '#ea6767',
    colorWarning: '#e29c68',
    colorSuccess: '#37eb91',

    backgroundSecondary: '#36394A'
};

export const NOTIFICATIONS = {
    success: (message) => {
        return {
            type: 'success',
            message: message ? message : 'poprawnie zaktualizowano dane'
        };
    },
    alert: (message) => {
        return {
            type: 'error',
            message: message ? message : 'wystąpił błąd'
        };
    },

    warning: (message) => {
        return {
            type: 'warning',
            message: message ? message : 'spróbuj ponownie ponownie'
        };
    }
};
