<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <Job-add-hash-form @formUpdated="hashFormChanged" :key="componentKey" ref="jobAddHashForm"></Job-add-hash-form>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn
                            class="v-btn__secondary mr-4"
                            @click="
                                $emit('deactivate', null);
                                forceRerender();
                            "
                            >Anuluj</v-btn
                        >
                        <v-btn class="v-btn__primary" @click="checkFormValidationAndAddHash">Dodaj Hash</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import JobAddHashForm from './JobAddHashForm.vue';
export default {
    components: { JobAddHashForm },
    name: 'JobAddHash',

    data() {
        return {
            componentKey: 0,
            newHash: null
        };
    },

    props: {},

    methods: {
        hashFormChanged(hashObject) {
            this.newHash = hashObject;
        },

        forceRerender() {
            this.componentKey += 1;
        },

        checkFormValidationAndAddHash() {
            if (!this.$refs.jobAddHashForm.checkFormValidation()) return;

            this.$emit('deactivate', this.newHash);
            this.forceRerender();
        }
    }
};
</script>
