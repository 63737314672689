import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';

export const grafanaService = {
    grafanaAuth
};

async function grafanaAuth() {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions,

        headers: {
            ...defaultRequestOptions.headers,
            Accept: 'application/json',
            Authorization: 'Basic ZGFzaGJvYXJkX3VzZXI6SEx6Wks1aEQxRFR1a3AwenZqYnZaTEdialBWV24wSVpJYXc='
        }
    };

    const response = await fetch(`${config.grafanaHomeDashboardUrl}`, requestOptions);
    const grafanaJson = await globalService.handleResponse(response);

    console.log(response, grafanaJson);
    return response;
}
