<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <v-row class="text-left">
                    <v-col cols="12 mb-16">
                        <v-form class="mr-auto ml-auto ml-md-0">
                            <v-select
                                class="v-input"
                                v-model="selectedUserRole"
                                :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                                outlined
                                :color="CSS_COLOR.textHighlight"
                                :items="['admin', 'user']"
                                label="Uprawnienia:"
                            ></v-select>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light mt-4"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="closeDialog">Anuluj</v-btn>
                        <v-btn :loading="isUpdating" class="v-btn__primary" @click="submitNewRole">Aktualizuj</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';

import { usersService } from '../../../services/users.service';

export default {
    name: 'UserChangeRolesDialog',

    data: () => ({
        CSS_COLOR,
        isUpdating: false,
        role: null,
        roles: {
            admin: {
                roles: [
                    {
                        name: 'USER'
                    },
                    {
                        name: 'ADMIN'
                    }
                ]
            },
            user: {
                roles: [
                    {
                        name: 'USER'
                    }
                ]
            }
        }
    }),

    computed: {
        selectedUser() {
            return this.$store.getters.selectedUser;
        },

        selectedUserDepartment: {
            get() {
                return this.userDetails.department === null ? this.selectedUser.department : this.userDetails.department;
            },
            set(value) {
                this.userDetails.department = value;
            }
        },

        selectedUserRole: {
            get() {
                if (this.role === null) {
                    const roles = [];
                    this.selectedUser.roles.forEach((role) => {
                        roles.push(role.name);
                    });

                    let role;

                    switch (true) {
                        case roles.includes('ADMIN'):
                            role = 'admin';
                            break;
                        case roles.includes('USER'):
                            role = 'user';
                            break;
                        default:
                            role = 'unknown';
                        // code block
                    }

                    return role;
                } else {
                    return this.role;
                }
            },
            set(value) {
                this.role = value;
            }
        }
    },

    methods: {
        async sendPatchRequest() {
            this.isUpdating = true;
            const preparedRolesBody = this.roles[this.selectedUserRole];

            const response = await usersService.updateUserRole(this.selectedUser, preparedRolesBody);

            if (response.status == 200) {
                this.fetchUserData();
                this.closeDialog();
            } else if (response.status == 403) {
                this.closeDialog();
            }
            this.isUpdating = false;
        },

        submitNewRole() {
            this.sendPatchRequest();
        },

        closeDialog() {
            this.$emit('deactivate');
        },

        async fetchUserData() {
            this.$store.dispatch('setUserRefetch', this.selectedUser.id);
            await this.$store.dispatch('getUserById', this.selectedUser.id);
            await this.$store.dispatch('getUsersFromApi');
        }
    }
};
</script>
