<template>
    <Login-Index></Login-Index>
</template>

<script>
import LoginIndex from '../components/login/index';

export default {
    components: {
        LoginIndex
    }
};
</script>
