<template>
    <Users-index></Users-index>
</template>

<script>
import UsersIndex from '../components/users/index';

export default {
    components: {
        UsersIndex
    }
};
</script>
