<template>
    <Main-section :hideSecondaryCol="false">
        <template v-slot:left-col>
            <Users-main-section></Users-main-section>
        </template>
        <template v-slot:right-col><Users-hidden-section></Users-hidden-section> </template>
    </Main-section>
</template>

<script>
import MainSection from '../global/MainSection.vue';
import UsersHiddenSection from './subcomponents/UsersHiddenSection.vue';
import UsersMainSection from './subcomponents/UsersMainSection.vue';

export default {
    name: 'UsersIndex',

    components: {
        MainSection,
        UsersMainSection,
        UsersHiddenSection
    },

    data: () => ({
        routeName: 'Users'
    }),

    computed: {
        shouldAutoReload() {
            return this.$store.getters.autoRefreshData;
        },

        selectedUser() {
            return this.$store.getters.selectedUser;
        },

        preselectedUserId() {
            return this.$store.getters.preselectedUserId;
        }
    },

    watch: {
        shouldAutoReload(newBooleanValue) {
            if (newBooleanValue) {
                this.$store.dispatch('autoRefreshCallback', this.getUsersData);
                this.getUsersData();
            } else {
                this.$store.dispatch('autoRefreshCallback', null);
            }
        }
    },

    methods: {
        async getUsersData() {
            await this.$store.dispatch('getUsersFromApi');

            this.$store.dispatch('setUsersLastUpdateTime');

            this.selectedUser ? this.refreshSelectedUser() : null;
        },

        refreshSelectedUser() {
            if (this.preselectedUserId) return;
            this.$store.dispatch('setUserRefetch');
            this.$store.dispatch('getUserById', this.selectedUser.id);
        }
    },

    mounted() {
        this.getUsersData();
        this.$store.dispatch('autoRefreshCallback', this.getUsersData);
    },

    beforeDestroy() {
        this.$store.dispatch('resetUsersData');

        this.$store.dispatch('autoRefreshCallback', null);
    }
};
</script>
