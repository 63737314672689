<template>
    <v-container fluid>
        <v-row justify="space-between">
            <v-col cols="auto">
                <Hidden-section-title :titleText="selectedJob ? selectedJob.name : ''" :titleCase="titleCase"></Hidden-section-title>
            </v-col>
            <v-col cols="auto">
                <v-btn small icon @click="closeCol">
                    <v-icon :color="CSS_COLOR.textPrimary" small> mdi-close </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-progress-linear v-if="isRefetching" indeterminate height="1" color="primary" class="mt-4"></v-progress-linear>
        <v-divider v-else class="mt-4 v-divider_light"></v-divider>
        <v-row v-if="isNewDataLoading" class="py-10">
            <v-progress-circular class="mt-12 mx-auto" color="primary" size="60" width="2" indeterminate></v-progress-circular>
        </v-row>
        <v-row v-else-if="!isNewDataLoading && selectedJob">
            <v-col cols="12">
                <v-card color="transparent" elevation="0">
                    <v-tabs v-model="tab" background-color="transparent" grow>
                        <v-tab v-for="item in items" :key="item">
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" class="mt-8 transparent">
                        <v-tab-item>
                            <Job-materials></Job-materials>
                        </v-tab-item>
                        <v-tab-item>
                            <Job-details></Job-details>
                        </v-tab-item>
                        <v-tab-item>
                            <Job-summary></Job-summary>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else justify="center" class="mt-10">
            <v-col cols="auto">
                <v-alert type="error">Błąd wczytywania danych zlecenia</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import JobDetails from './JobDetails.vue';
import JobMaterials from './JobMaterials.vue';
import JobSummary from './JobSummary.vue';
import HiddenSectionTitle from '../../global/HiddenSectionTitle.vue';

export default {
    components: {
        JobSummary,
        JobDetails,
        JobMaterials,
        HiddenSectionTitle
    },
    name: 'JobsHiddenSection',

    data: () => ({
        CSS_COLOR,
        tab: null,
        items: ['Materiały', 'Szczegóły', 'Podsumowanie']
    }),

    computed: {
        titleCase() {
            if (this.isNewDataLoading) {
                return 'loading';
            } else if (this.selectedJob && !this.isNewDataLoading) {
                return 'title';
            } else {
                return 'error';
            }
        },
        isNewDataLoading() {
            return this.$store.getters.isJobNewDataLoading;
        },
        isRefetching() {
            return this.$store.getters.isJobRefetching;
        },
        selectedJob() {
            return this.$store.getters.selectedJob;
        }
    },

    methods: {
        closeCol() {
            this.$store.dispatch('closeDetails');
        }
    }
};
</script>
