<template>
    <div>
        <v-alert :type="notification.type">{{ notification.message }}</v-alert>
    </div>
</template>

<script>
export default {
    name: 'NotificationMessage',

    data() {
        return {
            removeNotificationTime: 3000
        };
    },

    props: {
        notification: Object
    },

    created() {
        setTimeout(this.removeNotification, this.removeNotificationTime);
    },

    methods: {
        removeNotification() {
            this.$store.dispatch('removeNotification', this.notification);
        }
    }
};
</script>
