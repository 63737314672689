<template>
    <Agents-index></Agents-index>
</template>

<script>
import AgentsIndex from '../components/agents/index';

export default {
    components: {
        AgentsIndex
    }
};
</script>
