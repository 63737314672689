<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <Data-table
                    :headers="headers"
                    :height="'65vh'"
                    :tableData="selectedStructures"
                    :itemKey="'id'"
                    :noDataText="noDataText"
                    :showSelect="false"
                    :customTemplatesItemsNames="[`item.hashrate`]"
                    class="v-data-table_secondary v-data-table_narrow"
                >
                    <template v-slot:[`item.hashrate`]="{ item }">
                        {{ getFormattedHashrate(item.hashrate) }}
                    </template>
                </Data-table>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('deactivate')">Zamknij</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import DataTable from '../../global/DataTable.vue';
export default {
    components: { DataTable },
    name: 'AgentHashesDialog',

    data() {
        return {
            headers: [
                { text: 'Id', value: 'id', width: '30%' },
                { text: 'Nazwa', value: 'name', width: '30%' },
                { text: 'Wydajność [H/s]', value: 'hashrate', width: '30%', align: 'end' }
            ],
            noDataText: 'Brak struktur do wyświetlenia'
        };
    },

    props: {
        selectedStructures: Array
    },

    methods: {
        getFormattedHashrate(number) {
            return Math.round(number).toLocaleString('pl', { useGrouping: true });
        }
    }
};
</script>
