<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left pt-6">
        <v-row>
            <v-col cols="3">
                <span>Nazwa:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ selectedAgent.name }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <span>Identyfikator:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ selectedAgent.identifier }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <span>Wersja:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ selectedAgent.version }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <span>IP:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ selectedAgent.ip }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <span>Data dodania:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ formatDateFrom1970(selectedAgent.created_at) }}</span>
            </v-col>
        </v-row>

        <v-row class="mt-8">
            <v-col cols="3">
                <span>CSR:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ csrLoading ? 'Ładowanie...' : isError ? csrError : csrState }}</span>
            </v-col>
        </v-row>

        <v-row v-if="isCsrCreated" justify="space-between" class="mt-8">
            <v-col cols="auto">
                <v-btn :loading="isUpdating" :disabled="csrLoading || isError" @click="openCsrRejectDialog" class="v-btn__alert">Odrzuć</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn :disabled="csrLoading || isError" class="v-btn__primary mr-2" :href="downloadCsrHref">
                    <v-icon class="mr-2"> mdi-arrow-down-circle-outline </v-icon>
                    <span>Pobierz CSR</span>
                </v-btn>
                <v-btn :disabled="csrLoading || isError" class="v-btn__primary" @click="openCsrAcceptDialog">Zatwierdź</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="isCsrRejected">
            <v-col cols="12">
                <v-alert type="warning" dense outlined
                    >Ten agent został odrzucony.
                    <div class="text-body-2 mt-2">
                        Skontaktuj się z administratorem w celu ponownego podłączenia agenta lub usunięcia go z listy.
                    </div>
                </v-alert>
            </v-col>
        </v-row>
        <v-row v-if="isCsrAccepted">
            <v-col cols="12">
                <v-alert type="success" dense outlined
                    >Agent został pomyślnie zaakceptowany.
                    <div class="text-body-2 mt-2">Trwa propagacja zmian w systemie.</div>
                </v-alert>
            </v-col>
        </v-row>

        <Dialog dialogWidth="30%" :title="'Zatwierdź agenta'" :forceActivation="showCsrAcceptDialog" @dialogClosed="showCsrAcceptDialog = false">
            <template v-slot:content="{ deactivate }">
                <Agent-csr-accept-dialog :csr="csr" :key="dialogKey" @deactivate="deactivate()"></Agent-csr-accept-dialog>
            </template>
        </Dialog>

        <Confirmation-dialog
            :cancelButtonText="'Anuluj'"
            :confirmationButtonText="'Odrzuć'"
            :isQuestionHtmlContent="true"
            :questionContent="`<h2 class='text_primary text-center mb-2'>Czy na pewno odrzucić CSR ?</h2>`"
            :dialogWidth="'30%'"
            :title="'Potwierdź odrzucenie CSRa'"
            :confirmationButtonClass="'v-btn__alert'"
            v-model="showCsrRejectDialog"
            @confirmEvent="csrReject"
        >
        </Confirmation-dialog>
    </v-card>
</template>

<script>
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { agentsService } from '../../../services/agents.service';

import Dialog from '../../global/Dialog.vue';
import ConfirmationDialog from '../../global/ConfirmationDialog.vue';
import AgentCsrAcceptDialog from './AgentCsrAcceptDialog.vue';

export default {
    name: 'AgentNotVerified',

    components: { Dialog, ConfirmationDialog, AgentCsrAcceptDialog },

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,

        isUpdating: false,
        showCsrAcceptDialog: false,
        showCsrRejectDialog: false,
        dialogKey: 0
    }),

    computed: {
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },
        csr() {
            return this.$store.getters.agentLastCsr;
        },

        csrState() {
            return this.csr ? this.csr.state : '-';
        },
        csrLoading() {
            return this.$store.getters.isAgentLastCsrLoading;
        },
        csrError() {
            return !this.csr ? 'Brak CSRa.' : null;
        },
        isError() {
            return this.csrError ? true : false;
        },

        isCsrCreated() {
            return this.csrState === 'created';
        },
        isCsrAccepted() {
            return this.csrState === 'accepted';
        },
        isCsrRejected() {
            return this.csrState === 'rejected';
        },

        downloadCsrHref() {
            return this.csr ? agentsService.downloadCsr(this.csr.id) : '';
        }
    },

    methods: {
        openCsrRejectDialog() {
            this.showCsrRejectDialog = true;
        },

        async csrReject() {
            this.isUpdating = true;

            const response = await agentsService.rejectCsr(this.csr.id);

            if (response.status == 200) {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Odrzucono agenta'));
                await this.getAgentLastCsr();
            }
            this.showCsrRejectDialog = false;
            this.isUpdating = false;
        },

        openCsrAcceptDialog() {
            this.dialogKey++;
            this.showCsrAcceptDialog = true;
        },

        async getAgentLastCsr() {
            await this.$store.dispatch('getAgentLastCsr', this.selectedAgent.id);
        },

        formatDateFrom1970(date) {
            return date ? new Date(date).toLocaleDateString('en-UK') : '';
        }
    },

    mounted() {
        this.getAgentLastCsr();
    }
};
</script>
