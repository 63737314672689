<template>
    <v-container fluid>
        <v-row justify="space-between">
            <v-col cols="auto">
                <h2 class="text_primary sub-header">Zarządca</h2>
            </v-col>
            <v-col cols="auto">
                <v-btn small icon @click="closeCol">
                    <v-icon :color="CSS_COLOR.textPrimary" small> mdi-close </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 v-divider_light"></v-divider>
        <v-row class="text-left mt-6">
            <v-col cols="12">
                <p class="mb-1 text_primary">Szczegóły:</p>
            </v-col>
        </v-row>
        <v-divider class="v-divider_light mb-8"></v-divider>
        <v-card outlined color="transparent" class="text-left">
            <v-row>
                <v-col cols="auto">
                    <span>Wersja zarządcy:</span>
                </v-col>
                <v-col grow>
                    <span class="text_primary">{{ managerVersion }}</span>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';

export default {
    name: 'SystemStatusDetails',

    data: () => ({
        CSS_COLOR
    }),

    methods: {
        closeCol() {
            this.$store.dispatch('closeDetails');
        }
    },

    computed: {
        managerVersion() {
            return this.$store.getters.managerVersion;
        }
    }
};
</script>
