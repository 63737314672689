import router from '../router';
import store from '../store';
import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';

export const authorizationService = {
    login,
    logout,
    userLoggedIn,
    userLoggedOut
    // deleteUser,
    // register
};

async function login(username, password) {
    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: JSON.stringify({
            login: username,
            password: password
        })
    };

    const response = await fetch(`${config.apiUrl}/${config.loginUrl}`, requestOptions);
    await globalService.handleResponse(response);

    if (response.status == 204) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        authorizationService.userLoggedIn();
        return true;
    } else {
        authorizationService.userLoggedOut();
        return false;
    }
    // // login successful if there's a jwt token in the response
    // if (user.token) {
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('user', JSON.stringify(user));
    // }

    // console.log(user);
    // return user;
}

async function logout() {
    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.logoutUrl}`, requestOptions);
    await globalService.handleResponse(response);

    authorizationService.userLoggedOut();

    return false;

    // remove user from local storage to log user out
}

async function userLoggedIn() {
    try {
        localStorage.setItem('user', true);
    } catch (e) {
        console.log(e);
    }

    await store.dispatch('getLoggedUserFromApi');
    router.push('/');
}

function userLoggedOut() {
    try {
        localStorage.removeItem('user');
    } catch (e) {
        console.log(e);
    }

    if (router.currentRoute.path != '/login') {
        router.push('/login');
    }
}

// async function register(user) {
//     console.log(config.apiUrl);
//     console.log(user);

//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     const response = await fetch(`${config.apiUrl}/users/register`, requestOptions);
//     return handleResponse(response);
// }

// prefixed function name with underscore because delete is a reserved word in javascript
// async function deleteUser(id) {
//     const requestOptions = {
//         method: 'DELETE',
//         headers: authHeader()
//     };

//     const response = await fetch(`${config.apiUrl}/users/${id}`, requestOptions);
//     return handleResponse(response);
// }
