import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';
import { generateQueryString } from '../_helpers/general';

export const groupsService = {
    getGroups,
    getGroupById,
    getGroupAgents,
    getGroupUsers,

    postNewGroup,
    updateGroup,
    deleteGroup,

    addUserToGroup,
    removeUserFromGroup,
    addAgentToGroup,
    removeAgentFromGroup
};

async function getGroups(queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };
    const queryString = generateQueryString(queryObj);
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}${queryString}`, requestOptions);
    const groups = await globalService.handleResponse(response);
    return groups;
}

async function getGroupById(id) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}/${id}`, requestOptions);
    const group = await globalService.handleResponse(response);
    return group;
}
async function getGroupAgents(groupId) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };
    const queryString = generateQueryString({ per_page: 256 });
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}/${groupId}/agents${queryString}`, requestOptions);
    const group = await globalService.handleResponse(response);
    return group;
}
async function getGroupUsers(groupId) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };
    const queryString = generateQueryString({ per_page: 256 });
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}/${groupId}/operators${queryString}`, requestOptions);
    const group = await globalService.handleResponse(response);
    return group;
}

async function postNewGroup(newGroup) {
    let jsonData;

    try {
        jsonData = JSON.stringify(newGroup);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: jsonData
    };
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}`, requestOptions);
    const addedGroup = await globalService.handleResponse(response);
    return addedGroup;
}
async function updateGroup(group) {
    const updatedData = {
        name: group.name,
        description: group.description
    };

    let jsonData;
    try {
        jsonData = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonData
    };
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}/${group.id}`, requestOptions);
    const updatedGroup = await globalService.handleResponse(response);
    return updatedGroup;
}
async function deleteGroup(id) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };
    const response = await fetch(`${config.apiUrl}/${config.groupsUrl}/${id}`, requestOptions);
    const deletedGroup = await globalService.handleResponse(response);
    return deletedGroup;
}

async function addUserToGroup(userId, groupId) {
    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions
    };
    const response = await fetch(`${config.apiUrl}/${config.usersUrl}/${userId}/groups/${groupId}`, requestOptions);
    const processedResponse = await globalService.handleResponse(response);
    return processedResponse;
}
async function removeUserFromGroup(userId, groupId) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };
    const response = await fetch(`${config.apiUrl}/${config.usersUrl}/${userId}/groups/${groupId}`, requestOptions);
    const processedResponse = await globalService.handleResponse(response);
    return processedResponse;
}

async function addAgentToGroup(agentId, groupId) {
    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions
    };
    const response = await fetch(`${config.apiUrl}/${config.agentsUrl}/${agentId}/groups/${groupId}`, requestOptions);
    const processedResponse = await globalService.handleResponse(response);
    return processedResponse;
}
async function removeAgentFromGroup(agentId, groupId) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };
    const response = await fetch(`${config.apiUrl}/${config.agentsUrl}/${agentId}/groups/${groupId}`, requestOptions);
    const processedResponse = await globalService.handleResponse(response);
    return processedResponse;
}
