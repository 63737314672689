import { replaceObjectFields } from '../../_helpers/general';
import { usersService } from '../../services/users.service';

const state = () => ({
    users: [],
    usersData: {},
    usersSortBy: '',
    usersSortOrder: '',
    usersSearch: '',
    isUsersDataLoading: false,
    usersLastUpdateTime: '',

    currentUser: {},

    preselectedUserId: null,
    selectedUserForDetails: null,
    isUserNewDataLoading: false,
    isUserRefetching: false,

    userSchema: {
        active: false,
        created_at: '',
        department: '',
        email: '',
        forename: '',
        id: null,
        last_logged_at: '',
        login: '',
        modified_at: '',
        roles: [],
        surname: ''
    }
});

// getters
const getters = {
    users: (state) => {
        return state.users;
    },

    usersData: (state) => {
        return state.usersData;
    },

    usersSortBy: (state) => {
        return state.usersSortBy;
    },

    usersSortOrder: (state) => {
        return state.usersSortOrder;
    },

    usersSearch: (state) => {
        return state.usersSearch;
    },

    isUsersDataLoading: (state) => {
        return state.isUsersDataLoading;
    },

    usersLastUpdateTime: (state) => {
        return state.usersLastUpdateTime;
    },

    currentUser: (state) => {
        return state.currentUser;
    },

    selectedUser: (state) => {
        return state.selectedUserForDetails;
    },

    isUserNewDataLoading: (state) => {
        return state.isUserNewDataLoading;
    },

    isUserRefetching: (state) => {
        return state.isUserRefetching;
    },

    preselectedUserId: (state) => {
        return state.preselectedUserId;
    }
};

// actions
const actions = {
    async getUsersFromApi({ commit, dispatch, state, rootState }) {
        const per_page = rootState.global.itemsPerPage;
        const page = state.usersData.page;
        const sort = state.usersSortBy;
        const order = state.usersSortOrder;
        const search = state.usersSearch;

        commit('isUsersDataLoadingChange');

        const response = await usersService.getUsers({ per_page, page, sort, order, search });

        if (response.status !== 200) {
            commit('isUsersDataLoadingChange');
            return;
        }

        const { content, ...data } = response.data;

        commit('usersData', data);
        commit('usersItems', content);

        dispatch('autoRefreshResetTimeout');
        commit('isUsersDataLoadingChange');
    },

    async getCurrentUserFromApi({ commit }) {
        commit('isUsersDataLoadingChange');

        const response = await usersService.getCurrentUser();

        if (response.status !== 200) {
            commit('isUsersDataLoadingChange');
            return;
        }

        commit('currentUserData', response.data);
        commit('isUsersDataLoadingChange');
    },

    async getUserById({ commit, state }, userId) {
        if (state.isUserNewDataLoading) return;

        if (!state.isUserRefetching) {
            commit('setUserNewDataLoading', true);
        }

        const response = await usersService.getUserById(userId);

        if (response.status !== 200) {
            commit('resetUserForDetails');
            commit('setUserNewDataLoading', false);
            commit('setUserRefetching', false);
            return;
        }

        const userData = { ...state.userSchema, ...response.data };

        if (state.isUserRefetching) {
            commit('setUserForDetailsSilently', userData);
        } else {
            commit('setUserForDetails', userData);
        }
        commit('setUserRefetching', false);
        commit('setUserNewDataLoading', false);
    },

    resetUsersData({ commit }) {
        commit('resetUsersData');
    },

    setUsersPage({ commit }, page) {
        commit('setUsersPage', page);
    },

    setUsersSortBy({ commit }, fieldName) {
        commit('setUsersSortBy', fieldName);
    },

    setUsersSortOrder({ commit }, order) {
        commit('setUsersSortOrder', order);
    },

    setUsersSearch({ commit }, text) {
        commit('setUsersSearch', text);
    },

    setUsersLastUpdateTime({ commit }) {
        const dataTime = new Date().toLocaleString();
        commit('setUsersLastUpdateTime', dataTime);
    },

    setUserRefetch({ commit }) {
        commit('setUserRefetching', true);
    },

    setPreselectedUserId({ commit }, userId) {
        commit('setPreselectedUserId', userId);
    }
};

// mutations
const mutations = {
    usersItems(state, payload) {
        state.users = payload;
    },

    usersData(state, payload) {
        state.usersData = payload;
    },

    resetUsersData(state) {
        state.usersData = {};
        state.users = [];
    },

    setUsersLastUpdateTime(state, payload) {
        state.usersLastUpdateTime = payload;
    },

    setUsersPage(state, payload) {
        state.usersData.page = payload;
    },

    setUsersSortBy(state, payload) {
        state.usersSortBy = payload;
    },

    setUsersSortOrder(state, payload) {
        state.usersSortOrder = payload;
    },

    setUsersSearch(state, payload) {
        state.usersSearch = payload;
    },

    currentUserData(state, payload) {
        state.currentUser = payload;
    },

    resetUserForDetails(state) {
        state.selectedUserForDetails = null;
    },

    setUserForDetails(state, user) {
        state.selectedUserForDetails = user;
    },

    setUserForDetailsSilently(state, user) {
        replaceObjectFields(state.selectedUserForDetails, user);
    },

    isUsersDataLoadingChange(state) {
        if (state.isUsersDataLoading == true) {
            setTimeout(function () {
                state.isUsersDataLoading = false;
            }, 750);
        } else {
            state.isUsersDataLoading = true;
        }
    },

    setUserNewDataLoading(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isUserNewDataLoading = false;
            }, 250);
        } else {
            state.isUserNewDataLoading = true;
        }
    },

    setUserRefetching(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isUserRefetching = false;
            }, 250);
        } else {
            state.isUserRefetching = true;
        }
    },

    setPreselectedUserId(state, userId) {
        state.preselectedUserId = userId;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
