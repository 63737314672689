<template>
    <v-btn :class="btnClass" @click="selectText">{{ btnText }}</v-btn>
</template>

<script>
export default {
    name: 'SelectTextBtn',

    props: {
        containerId: String,
        btnClass: {
            type: String,
            default: 'v-btn__primary'
        },
        btnText: String
    },

    methods: {
        selectText() {
            const containerId = this.containerId;
            if (document.selection) {
                // IE
                const range = document.body.createTextRange();
                range.moveToElementText(document.getElementById(containerId));
                range.select();
            } else if (window.getSelection) {
                const range = document.createRange();
                range.selectNode(document.getElementById(containerId));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        }
    }
};
</script>
