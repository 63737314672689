<template>
    <v-card outlined color="transparent" class="text-left">
        <v-row>
            <v-col cols="3">
                <span>Czas rzeczywisty:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ formattedTimeDuration }}</span>
                <span v-if="!selectedJob.done_at && selectedJob.started_at" class="text_primary"> (w toku) </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <span>Czas przetwarzania:</span>
            </v-col>
            <v-col grow>
                <span class="text_primary">{{ formattedTimeProcessing }}</span>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { msToDayHoursMinutesSeconds } from '../../../_helpers/data-time';

export default {
    name: 'JobSummary',

    data: () => ({}),

    computed: {
        selectedJob() {
            return this.$store.getters.selectedJob;
        },

        formattedTimeDuration() {
            let durationTimeString;

            if (this.selectedJob?.started_at && this.selectedJob?.done_at) {
                durationTimeString = new Date(this.selectedJob?.done_at - this.selectedJob?.started_at);
            } else if (this.selectedJob?.started_at) {
                durationTimeString = new Date(Date.now() - this.selectedJob?.started_at);
            } else {
                return '-';
            }

            return this.convertDateToHumanReadableTime(durationTimeString);
        },

        formattedTimeProcessing() {
            let processingTimeString;

            if (this.selectedJob?.processing_time || this.selectedJob?.processing_time === 0) {
                processingTimeString = new Date(this.selectedJob?.processing_time * 1000);
            } else {
                return '-';
            }

            return this.convertDateToHumanReadableTime(processingTimeString);
        }
    },

    methods: {
        convertDateToHumanReadableTime(dataString) {
            const days = msToDayHoursMinutesSeconds(dataString)['days'].toString().padStart(2, '0');
            const hours = msToDayHoursMinutesSeconds(dataString)['hours'].toString().padStart(2, '0');
            const min = msToDayHoursMinutesSeconds(dataString)['minutes'].toString().padStart(2, '0');
            const sec = msToDayHoursMinutesSeconds(dataString)['seconds'].toString().padStart(2, '0');

            if (days !== '00') {
                return `${days}d ${hours}h ${min}m ${sec}s`;
            }
            return `${hours}h ${min}m ${sec}s`;
        }
    }
};
</script>
