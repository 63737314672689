<template>
    <Groups-index></Groups-index>
</template>

<script>
import GroupsIndex from '../components/groups/index.vue';

export default {
    components: {
        GroupsIndex
    }
};
</script>
