import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';
import { generateQueryString } from '../_helpers/general';

export const algorithmsService = {
    getAlgorithms
};

async function getAlgorithms(queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const queryString = generateQueryString(queryObj);

    const response = await fetch(`${config.apiUrl}/${config.algorithmsUrl}${queryString}`, requestOptions);
    const algorithms = await globalService.handleResponse(response);

    return algorithms;
}
