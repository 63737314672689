import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/css/main.less';
import vuetify from './plugins/vuetify';
import { Network } from 'vue2vis';

// setup fake backend
//import { configureFakeBackend } from './_helpers/fake-backend';
//

//configureFakeBackend();
Vue.config.productionTip = false;

Vue.component('network', Network);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app');
