<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <v-row>
            <v-col cols="12">
                <Data-table
                    :must-sort="true"
                    :disable-sort="isAttachmentRequestsLoading"
                    :showSelect="false"
                    :headers="headers"
                    :tableData="attachmentRequests"
                    :itemKey="'id'"
                    :loading="isAttachmentRequestsLoading"
                    :noDataText="noDataText"
                    :customTemplatesItemsNames="[`item.actions`]"
                    class="v-data-table_secondary v-data-table_narrow"
                    :options.sync="requestsOptions"
                    :serverItemsLength="attachmentRequestsData.elements"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="text-start">
                            <v-btn icon @click="openRequestDetails(item)"><v-icon>mdi-open-in-new</v-icon> </v-btn>
                        </div>
                    </template>
                </Data-table>
                <v-pagination
                    v-if="attachmentRequestsData.pages > 1"
                    total-visible="3"
                    :disabled="isAttachmentRequestsLoading"
                    v-model="page"
                    :length="attachmentRequestsData.pages"
                    class="mt-4"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import DataTable from '../../global/DataTable.vue';
import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';

export default {
    name: 'AttachmentJobs',

    mixins: [DataTableOptionsMixin],

    components: {
        DataTable
    },

    data: () => ({
        headers: [
            { text: 'Id', value: 'id' },
            { text: 'Nazwa', value: 'name' },
            { text: 'Status', value: 'state' },
            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak powiązanych zleceń do wyświetlenia',
        showJobPass: null,
        clickedMaterialPassword: null,

        page: 1,
        options: {
            sortBy: ['id'],
            sortDesc: [false]
        }
    }),

    computed: {
        requestsOptions: {
            get() {
                return {
                    sortBy: this.requestsSortBy ? [this.requestsSortBy] : this.options.sortBy,
                    sortDesc: this.requestsSortOrder === 'desc' ? [true] : this.options.sortDesc
                };
            },
            set(value) {
                this.options = value;
            }
        },

        requestsSortBy() {
            return this.$store.getters.requestsSortBy;
        },

        requestsSortOrder() {
            return this.$store.getters.requestsSortOrder;
        },

        selectedAttachment() {
            return this.$store.getters.selectedAttachment;
        },

        attachmentRequestsData() {
            return this.$store.getters.attachmentRequestsData;
        },

        attachmentRequests() {
            return this.$store.getters.attachmentRequestsItems;
        },

        isAttachmentRequestsLoading() {
            return this.$store.getters.isAttachmentRequestsLoading;
        }
    },

    methods: {
        async getAttachmentRequestsData() {
            this.$store.dispatch('resetAttachmentRequestsData');
            await this.$store.dispatch('getAttachmentRequestsFromApi', this.selectedAttachment.id);
        },

        openRequestDetails(job) {
            this.$store.dispatch('setPreselectedJobId', job.id);
            this.$router.push({ path: '/zlecenia' });
        },

        onPageChange(page) {
            this.$store.dispatch('setAttachmentRequestsPage', page);
            this.$store.dispatch('getAttachmentRequestsFromApi', this.selectedAttachment.id);
        },
        onSortChange(sortBy, isSortDesc) {
            this.$store.dispatch('setAttachmentRequestsSortBy', sortBy ? sortBy : '');
            this.$store.dispatch('setAttachmentRequestsSortOrder', isSortDesc ? 'desc' : '');
            this.$store.dispatch('getAttachmentRequestsFromApi', this.selectedAttachment.id);
        }
    },

    watch: {
        selectedAttachment() {
            this.getAttachmentRequestsData();
        }
    },

    mounted() {
        this.getAttachmentRequestsData();
    }
};
</script>
