<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <v-row>
            <v-col cols="12" class="text-center">
                <v-btn class="v-btn__primary" @click="openAddUserToGroupDialog">Dodaj do grupy</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <Data-table
                    :must-sort="true"
                    :showSelect="false"
                    :headers="headers"
                    :tableData="userGroups"
                    itemKey="id"
                    :noDataText="noDataText"
                    :customTemplatesItemsNames="[`item.actions`]"
                    class="v-data-table_secondary v-data-table_narrow"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="text-end">
                            <v-btn icon color="error" @click="openDeleteUserFromGroupDialog(item)"><v-icon>mdi-account-minus</v-icon></v-btn>
                        </div>
                    </template>
                </Data-table>
            </v-col>
        </v-row>

        <Dialog
            :title="`Dodaj użytkownika do grupy`"
            dialog-width="450px"
            :forceActivation="dialogs.addUserToGroup"
            @dialogClosed="dialogs.addUserToGroup = false"
        >
            <template v-slot:content="{ deactivate }">
                <Add-to-group-dialog @closeDialog="deactivate()" :key="componentKey" target="user"></Add-to-group-dialog>
            </template>
        </Dialog>

        <Dialog
            :title="`Usuń użytkownika z grupy`"
            dialog-width="450px"
            :forceActivation="dialogs.removeUserFromGroup"
            @dialogClosed="dialogs.removeUserFromGroup = false"
        >
            <template v-slot:content="{ deactivate }">
                <Remove-from-group-dialog
                    @closeDialog="deactivate()"
                    :key="componentKey"
                    target="user"
                    :group="selectedGroup"
                ></Remove-from-group-dialog>
            </template>
        </Dialog>
    </v-card>
</template>

<script>
import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';
import Dialog from '../../global/Dialog.vue';
import DataTable from '../../global/DataTable.vue';

import AddToGroupDialog from '../../groups/subcomponents/AddToGroupDialog.vue';
import RemoveFromGroupDialog from '../../groups/subcomponents/RemoveFromGroupDialog.vue';

export default {
    components: { Dialog, DataTable, AddToGroupDialog, RemoveFromGroupDialog },
    name: 'userGroups',

    mixins: [DataTableOptionsMixin],

    data: () => ({
        headers: [
            { text: 'Id', value: 'id', width: 70 },
            { text: 'Nazwa', value: 'name' },
            { text: 'Opis', value: 'description' },
            { text: 'Akcje', value: 'actions', sortable: false, width: 75 }
        ],
        noDataText: 'Brak grup do wyświetlenia',

        dialogs: {
            addUserToGroup: false,
            removeUserFromGroup: false
        },
        selectedGroup: null,
        componentKey: 0
    }),

    computed: {
        selectedUser() {
            return this.$store.getters.selectedUser;
        },
        userGroups() {
            return this.selectedUser.groups ?? [];
        }
    },

    methods: {
        openAddUserToGroupDialog() {
            this.componentKey++;
            this.dialogs.addUserToGroup = true;
        },
        openDeleteUserFromGroupDialog(group) {
            this.componentKey++;
            this.selectedGroup = group;
            this.dialogs.removeUserFromGroup = true;
        }
    }
};
</script>
