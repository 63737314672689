<template>
    <v-row class="text-left">
        <v-col cols="12">
            <v-row class="text-left">
                <v-col cols="12">
                    <v-form class="mr-auto ml-auto ml-md-0">
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    class="v-input"
                                    v-model="currentUserForename"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Imię:"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    class="v-input"
                                    v-model="currentUserSurname"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Nazwisko:"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    disabled
                                    class="v-input v-input_required"
                                    v-model="currentUserLogin"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Login:"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    class="v-input v-input_required"
                                    v-model="currentUserEmail"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="E-mail:"
                                    @input="$v.currentUserEmail.$touch()"
                                    @blur="$v.currentUserEmail.$touch()"
                                    :error-messages="emailErrors"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <v-select
                                    disabled
                                    class="v-input"
                                    v-model="currentUserRole"
                                    :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    :items="['admin', 'user']"
                                    label="Uprawnienia:"
                                ></v-select>
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <v-text-field
                                    class="v-input"
                                    v-model="currentUserDepartment"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Departament:"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="justify-end">
                            <v-col cols="auto" class="pb-0">
                                <v-btn :loading="isUpdatingUserData" class="v-btn__primary" @click="submitUserData">Aktualizuj</v-btn>
                            </v-col>
                        </v-row>

                        <v-row class="text-left mt-4 mb-0">
                            <v-col cols="12">
                                <p class="mb-0 text_primary">Hasło:</p>
                            </v-col>
                        </v-row>

                        <v-divider class="v-divider_light mb-4"></v-divider>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="password.newPassword"
                                    outlined
                                    class="v-input_required"
                                    label="Nowe hasło:"
                                    :color="CSS_COLOR.textHighlight"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :error-messages="newPasswordErrors"
                                    @click:append="showPassword = !showPassword"
                                    @input="$v.password.newPassword.$touch()"
                                    @blur="$v.password.newPassword.$touch()"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="password.confirmPassword"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Powtórz hasło:"
                                    class="v-input_required"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :error-messages="confirmPasswordErrors"
                                    @click:append="showPassword = !showPassword"
                                    @input="$v.password.confirmPassword.$touch()"
                                    @blur="$v.password.confirmPassword.$touch()"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="justify-end">
                            <v-col cols="auto" class="pt-0">
                                <v-btn :loading="isUpdatingPassword" class="v-btn__primary" @click="submitNewPassword">Zmień hasło</v-btn>
                            </v-col>
                        </v-row>

                        <v-divider class="v-divider_light mt-10 mb-4"></v-divider>
                    </v-form>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { usersService } from '../../../services/users.service';
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';

export default {
    name: 'ProfileCardForm',

    mixins: [validationMixin],

    validations: {
        currentUserEmail: { required, email },
        password: {
            newPassword: { required },
            confirmPassword: { required, sameAsNewPassword: sameAs('newPassword') }
        }
    },

    components: {},

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,
        userDetails: {
            forename: null,
            surname: null,
            email: null,
            department: null,
            role: null
        },

        password: {
            newPassword: '',
            confirmPassword: ''
        },

        isUpdatingUserData: false,
        showPassword: false,
        isUpdatingPassword: false
    }),

    computed: {
        currentUser() {
            return this.$store.getters.currentUser;
        },

        currentUserForename: {
            get() {
                return this.userDetails.forename === null ? this.currentUser.forename : this.userDetails.forename;
            },
            set(value) {
                this.userDetails.forename = value;
            }
        },

        currentUserSurname: {
            get() {
                return this.userDetails.surname === null ? this.currentUser.surname : this.userDetails.surname;
            },
            set(value) {
                this.userDetails.surname = value;
            }
        },

        currentUserLogin: {
            get() {
                return this.currentUser.login;
            }
        },

        currentUserEmail: {
            get() {
                return this.userDetails.email === null ? this.currentUser.email : this.userDetails.email;
            },
            set(value) {
                this.userDetails.email = value;
            }
        },

        currentUserDepartment: {
            get() {
                return this.userDetails.department === null ? this.currentUser.department : this.userDetails.department;
            },
            set(value) {
                this.userDetails.department = value;
            }
        },

        currentUserRole: {
            get() {
                if (this.userDetails.role === null && this.currentUser.roles) {
                    const roles = [];

                    this.currentUser.roles.forEach((role) => {
                        roles.push(role.name);
                    });

                    let role;

                    switch (true) {
                        case roles.includes('ADMIN'):
                            role = 'admin';
                            break;
                        case roles.includes('USER'):
                            role = 'user';
                            break;
                        default:
                            role = 'unknown';
                    }

                    return role;
                } else {
                    return this.role;
                }
            },
            set(value) {
                this.role = value;
            }
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.currentUserEmail.$dirty) return errors;
            !this.$v.currentUserEmail.required && errors.push('Adres email jest wymagany');
            !this.$v.currentUserEmail.email && errors.push('Wprowadź poprawny adres email');
            return errors;
        },

        newPasswordErrors() {
            const errors = [];
            if (!this.$v.password.newPassword.$dirty) return errors;
            !this.$v.password.newPassword.required && errors.push('Hasło jest wymagane');
            return errors;
        },

        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.password.confirmPassword.$dirty) return errors;
            !this.$v.password.confirmPassword.sameAsNewPassword && errors.push('Hasła muszą być takie same');
            !this.$v.password.confirmPassword.required && errors.push('Hasła muszą być takie same');

            return errors;
        }
    },

    methods: {
        submitUserData() {
            if (!this.checkFormValidation('currentUserEmail')) {
                return;
            }

            this.sendUserDataPatchRequest();
        },

        submitNewPassword() {
            if (!this.checkFormValidation('password')) {
                return;
            }

            this.sendNewPasswordPatchRequest();
        },

        async sendUserDataPatchRequest() {
            this.isUpdatingUserData = true;

            const response = await usersService.updateCurrentUser({
                forename: this.currentUserForename,
                surname: this.currentUserSurname,
                email: this.currentUserEmail,
                department: this.currentUserDepartment
            });

            if (response.status == 200) {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
                // await this.getUpdatedUsersData();
            }

            setTimeout(() => {
                this.isUpdatingUserData = false;
            }, 750);
        },

        async sendNewPasswordPatchRequest() {
            this.isUpdatingPassword = true;

            const response = await usersService.updateCurrentUserPassword(this.password.newPassword);

            if (response.status == 200) {
                this.password.newPassword = '';
                this.password.confirmPassword = '';
                this.clearFormValidation('password');
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }

            setTimeout(() => {
                this.isUpdatingPassword = false;
            }, 750);
        },

        checkFormValidation(checkedForm) {
            this.$v[checkedForm].$touch();
            return this.$v[checkedForm].$anyError ? false : true;
        },

        clearFormValidation(checkedForm) {
            this.$v[checkedForm].$reset();
        }
    }
};
</script>
