<template>
    <v-row>
        <div class="ml-auto mr-auto form-block mr-md-16 text_primary">
            <v-img src="@/assets/images/cyber-crypt-logo.svg" class="mr-auto" max-width="200px" contain></v-img>
            <p>cybercrypt@gov – rozproszony, skalowalny, wysokowydajny system pozwalający na przełamywanie zabezpieczeń kryptograficznych</p>
        </div>
    </v-row>
</template>

<script>
export default {
    name: 'LoginDescription',

    data: () => ({})
};
</script>
