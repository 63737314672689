import Vue from 'vue';
import Vuex from 'vuex';
import jobs from './modules/jobs';
import global from './modules/global';
import attachments from './modules/attachments';
import agents from './modules/agents';
import algorithms from './modules/algorithms';
import users from './modules/users';
import groups from './modules/groups';
import authorization from './modules/authorization';
import manager from './modules/manager';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        attachments,
        jobs,
        agents,
        algorithms,
        users,
        groups,
        authorization,
        manager,
        global
    }
});
