<template>
    <v-container fill-height>
        <v-row v-if="isLoading" class="text-center">
            <v-col cols="12" class="mb-12">
                <v-progress-circular :size="200" :color="CSS_COLOR.textHighlight" indeterminate></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-if="!isLoading" class="justify-center">
            <v-col cols="auto">
                <v-card width="650px" height="auto" :color="CSS_COLOR.backgroundSecondary">
                    <v-card-title>
                        <v-row class="justify-space-between align-center">
                            <v-col cols="auto">
                                <v-avatar :color="CSS_COLOR.textHighlight">
                                    <span class="sub-title">{{ currentUserAvatarLetters }}</span>
                                </v-avatar>
                            </v-col>
                            <v-col cols="grow" class="text-left">
                                <span class="text_tertiary sub-title">{{ currentUser.forename }} {{ currentUser.surname }}</span>
                                <br />
                                <span v-if="isAdminLogged" class="text_highlight">Administrator</span>
                                <span v-else class="text_highlight">Użytkownik</span>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn class="v-btn__secondary" v-on:click="logout">
                                    <span>Wyloguj się</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider class="v-divider_light mb-8"></v-divider>
                    <v-card-text>
                        <Profile-Card-Form></Profile-Card-Form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import { authorizationService } from '../../../services/authorization.service';
import ProfileCardForm from './ProfileCardForm.vue';

export default {
    name: 'ProfileCard',

    components: { ProfileCardForm },

    data: () => ({
        CSS_COLOR
    }),

    computed: {
        isLoading() {
            return this.$store.getters.isUsersDataLoading;
        },

        currentUser() {
            return this.$store.getters.currentUser;
        },

        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        currentUserAvatarLetters() {
            const firstLatter = this.currentUser.forename ? this.currentUser.forename.charAt(0) : '';
            const secondLatter = this.currentUser.surname ? this.currentUser.surname.charAt(0) : '';

            return `${firstLatter}${secondLatter}`;
        }
    },

    methods: {
        logout() {
            authorizationService.logout();
        }
    }
};
</script>
