<template>
    <Profile-Index></Profile-Index>
</template>

<script>
import ProfileIndex from '../components/profile/index';

export default {
    name: 'Profile',

    components: { ProfileIndex }
};
</script>
