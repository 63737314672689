var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-left",attrs:{"outlined":"","min-height":"100%","color":"transparent"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Data-table',{staticClass:"v-data-table_secondary v-data-table_narrow",attrs:{"headers":_vm.headers,"tableData":_vm.formattedJobsMaterials,"itemKey":'id',"noDataText":_vm.noDataText,"show-select":false,"customTemplatesItemsNames":["item.shortName", "item.actions"]},scopedSlots:_vm._u([{key:"item.shortName",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.shortName))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item['results'] && item['results'].length)?_c('div',{staticClass:"text-start"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogWithPassword(item)}}},[_c('v-icon',[_vm._v(" mdi-card-search-outline ")])],1)],1):_vm._e()]}}],null,true)})],1)],1),_c('Dialog',{attrs:{"title":'Hasło',"forceActivation":_vm.showJobPass},on:{"dialogClosed":_vm.closeDialog},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var deactivate = ref.deactivate;
return [_c('job-show-password',{attrs:{"textPassword":_vm.clickedMaterialPasswords},on:{"deactivate":deactivate}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }