<template>
    <span>
        <v-card outlined color="transparent" height="100%" width="100%">
            <Profile-Card></Profile-Card>
        </v-card>
    </span>
</template>

<script>
import ProfileCard from './ProfileCard.vue';

export default {
    name: 'ProfileMainSection',

    components: { ProfileCard },

    data: () => ({}),

    computed: {}
};
</script>
