<template>
    <Main-section :hideSecondaryCol="false">
        <template v-slot:left-col>
            <jobs-main-section></jobs-main-section>
        </template>
        <template v-slot:right-col>
            <jobs-hidden-section></jobs-hidden-section>
        </template>
    </Main-section>
</template>

<script>
import MainSection from '../global/MainSection.vue';
import JobsHiddenSection from './subcomponents/JobsHiddenSection.vue';
import JobsMainSection from './subcomponents/JobsMainSection.vue';

export default {
    name: 'JobsIndex',

    components: {
        MainSection,
        JobsMainSection,
        JobsHiddenSection
    },

    data: () => {
        return {
            routeName: 'Jobs'
        };
    },

    computed: {
        shouldAutoReload() {
            return this.$store.getters.autoRefreshData;
        },

        selectedJob() {
            return this.$store.getters.selectedJob;
        },

        preselectedJobId() {
            return this.$store.getters.preselectedJobId;
        }
    },

    watch: {
        shouldAutoReload(newBooleanValue) {
            if (newBooleanValue) {
                this.$store.dispatch('autoRefreshCallback', this.getJobsData);
                this.getJobsData();
            } else {
                this.$store.dispatch('autoRefreshCallback', null);
            }
        }
    },

    methods: {
        async getJobsData() {
            await this.$store.dispatch('getJobsFromApi');

            this.$store.dispatch('setJobsLastUpdateTime');

            this.selectedJob ? this.refreshSelectedJob() : null;
        },

        refreshSelectedJob() {
            if (this.preselectedJobId) return;
            this.$store.dispatch('setJobRefetch');
            this.$store.dispatch('getJobById', this.selectedJob.id);
        }
    },

    mounted() {
        this.getJobsData();
        this.$store.dispatch('autoRefreshCallback', this.getJobsData);
    },

    beforeDestroy() {
        this.$store.dispatch('resetJobsData');

        this.$store.dispatch('autoRefreshCallback', null);
    }
};
</script>
