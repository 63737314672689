import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Jobs from '../views/Jobs.vue';
import Dictionaries from '../views/Dictionaries.vue';
import Users from '../views/Users.vue';
import Agents from '../views/Agents.vue';
import Groups from '../views/Groups.vue';
import Profile from '../views/Profile.vue';
import Login from '../views/Login.vue';
import SystemStatus from '../views/SystemStatus.vue';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/zlecenia',
        name: 'Jobs',
        component: Jobs
    },
    {
        path: '/slowniki',
        name: 'Dictionaries',
        component: Dictionaries
    },
    {
        path: '/uzytkownicy',
        name: 'Users',
        component: Users
    },
    {
        path: '/agenci',
        name: 'Agents',
        component: Agents
    },
    {
        path: '/grupy',
        name: 'Groups',
        component: Groups
    },
    {
        path: '/status-systemu',
        name: 'SystemStatus',
        component: SystemStatus
    },
    {
        path: '/profil',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },

    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (to.path == '/login' && loggedIn) {
        return next('/');
    }

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    store.dispatch('setCurrentRoutePath', to);

    next();
});

export default router;
