<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <div>
                    <v-row class="text-left">
                        <v-col cols="12">
                            <v-form ref="form" class="mr-auto ml-auto ml-md-0">
                                <v-row>
                                    <v-col cols="6" class="pb-0">
                                        <v-text-field
                                            class="v-input v-input_full-width"
                                            v-model="newUser.forename"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="Imię:"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pb-0">
                                        <v-text-field
                                            class="v-input v-input_full-width"
                                            v-model="newUser.surname"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="Nazwisko:"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" class="pt-0 pb-0">
                                        <v-text-field
                                            class="v-input v-input_required v-input_full-width"
                                            v-model="newUser.login"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="Login:"
                                            autocomplete="off"
                                            :error-messages="loginErrors"
                                            @input="$v.newUser.login.$touch()"
                                            @blur="$v.newUser.login.$touch()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-0">
                                        <v-text-field
                                            class="v-input v-input_required v-input_full-width"
                                            v-model="newUser.email"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="E-mail:"
                                            autocomplete="off"
                                            :error-messages="emailErrors"
                                            @input="$v.newUser.email.$touch()"
                                            @blur="$v.newUser.email.$touch()"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" class="pt-0 pb-0">
                                        <v-text-field
                                            class="v-input v-input_required v-input_full-width"
                                            v-model="newUser.password"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="Hasło"
                                            autocomplete="new-password"
                                            :type="show ? 'text' : 'password'"
                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="show = !show"
                                            :error-messages="passwordErrors"
                                            @input="$v.newUser.password.$touch()"
                                            @blur="$v.newUser.password.$touch()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 pb-0">
                                        <v-text-field
                                            class="v-input v-input_required v-input_full-width"
                                            v-model="newUser.repeatPassword"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="Powtórz hasło"
                                            autocomplete="new-password"
                                            :type="show ? 'text' : 'password'"
                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                            @click:append="show = !show"
                                            :error-messages="repeatPasswordErrors"
                                            @input="$v.newUser.repeatPassword.$touch()"
                                            @blur="$v.newUser.repeatPassword.$touch()"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12" class="py-0">
                                        <v-text-field
                                            class="v-input v-input_full-width"
                                            v-model="newUser.department"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            label="Departament:"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" class="py-0">
                                        <v-select
                                            class="v-input v-input_full-width"
                                            v-model="selectedRole"
                                            :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                                            outlined
                                            :color="CSS_COLOR.textHighlight"
                                            :items="['admin', 'user']"
                                            label="Uprawnienia:"
                                            @change="setUserRole"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6" class="py-0">
                                        <v-select
                                            class="v-input v-input_full-width"
                                            v-model="selectedGroups"
                                            outlined
                                            multiple
                                            :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                                            :color="CSS_COLOR.textHighlight"
                                            :items="groups"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            :loading="groupsLoading"
                                            label="Grupy:"
                                            :no-data-text="noDataText"
                                            @change="setUserGroups"
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.name }}</span>
                                                <span v-if="index === 1" class="grey--text text-caption"
                                                    >&nbsp;(+{{ newUser.groups.length - 1 }} więcej)</span
                                                >
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('closeDialog')">Anuluj</v-btn>
                        <v-btn class="v-btn__primary" @click="sendRequestAndCloseDialog">Dodaj użytkownika</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { usersService } from '../../../services/users.service';
import { CSS_COLOR } from '../../../_helpers/consts';
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';

export default {
    name: 'UserAddNewDialog',

    mixins: [validationMixin],

    validations: {
        newUser: {
            login: { required },
            email: { required, email },
            password: { required },
            repeatPassword: { required, sameAsPassword: sameAs('password') }
        }
    },

    created() {
        this.getGroupsFromApi();
    },

    data() {
        return {
            CSS_COLOR,
            newUser: {
                forename: '',
                surname: '',
                login: '',
                email: '',
                password: '',
                repeatPassword: '',
                department: '',
                groups: [],
                roles: [
                    {
                        name: 'USER'
                    }
                ]
            },

            selectedRole: 'user',
            selectedGroups: [],

            roles: {
                admin: [
                    {
                        name: 'USER'
                    },
                    {
                        name: 'ADMIN'
                    }
                ],
                user: [
                    {
                        name: 'USER'
                    }
                ]
            },

            show: false,
            formValidated: false,

            noDataText: 'Brak danych'
        };
    },

    computed: {
        groups() {
            return this.$store.getters.groups;
        },
        groupsLoading() {
            return this.$store.getters.isGroupsDataLoading;
        },

        loginErrors() {
            const errors = [];
            if (!this.$v.newUser.login.$dirty) return errors;
            !this.$v.newUser.login.required && errors.push('Login jest wymagany');
            return errors;
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.newUser.email.$dirty) return errors;
            !this.$v.newUser.email.required && errors.push('Adres email jest wymagany');
            !this.$v.newUser.email.email && errors.push('Wprowadź poprawny adres email');
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.newUser.password.$dirty) return errors;
            !this.$v.newUser.password.required && errors.push('Hasło jest wymagane');
            return errors;
        },

        repeatPasswordErrors() {
            const errors = [];
            if (!this.$v.newUser.repeatPassword.$dirty) return errors;
            !this.$v.newUser.repeatPassword.sameAsPassword && errors.push('Hasła muszą być takie same');
            !this.$v.newUser.repeatPassword.required && errors.push('Hasła muszą być takie same');

            return errors;
        }
    },

    methods: {
        async getGroupsFromApi() {
            this.$store.dispatch('getGroupsFromApi', { perPage: 256 });
        },

        async sendRequestAndCloseDialog() {
            if (!this.checkFormValidation()) {
                return;
            }

            const newUserData = {
                ...this.newUser
            };

            delete newUserData.repeatPassword;

            const response = await usersService.postNewUser(newUserData);

            this.$emit('closeDialog');
            if (response.status == 200 || response.status == 201) {
                this.getUpdatedUsersData();
            }
        },

        async getUpdatedUsersData() {
            await this.$store.dispatch('getUsersFromApi');
        },

        setUserRole(role) {
            this.newUser.roles = this.roles[role];
        },
        setUserGroups(groups) {
            this.newUser.groups = groups.map((group) => ({
                ...group
            }));
        },

        checkFormValidation() {
            this.$v.$touch();

            this.formValidated = !this.$v.$anyError;

            if (!this.formValidated) {
                return false;
            }

            return true;
        }
    }
};
</script>
