import { managerService } from '../../services/manager.service';

const state = () => ({
    managerVersion: ''
});

// getters
const getters = {
    managerVersion: (state) => {
        return state.managerVersion;
    }
};

// actions
const actions = {
    async getManagerVersionFromApi({ commit, state }) {
        if (state.managerVersion) return;

        const response = await managerService.getManagerVersion();

        if (response.status !== 200) {
            return;
        }

        commit('managerVersionData', response.data);
    }
};

// mutations
const mutations = {
    managerVersionData(state, payload) {
        state.managerVersion = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
