<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <h2 class="text_primary sub-header">Zlecenia</h2>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" dark @click="getAttachmentsFromApi()"> + Nowe </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <div class="pl-6 pr-6 mb-14">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <h3 class="sub-title">Bieżące zlecenia</h3>
                </v-col>
                <v-col cols="auto">
                    <Search-field v-model.lazy="search" />
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4 pl-6 pr-6"></v-divider>
            <v-row>
                <v-col cols="12">
                    <Data-table
                        :must-sort="true"
                        :disable-sort="isLoading"
                        :headers="headers"
                        :tableData="formattedJobs"
                        :loading="isLoading"
                        :itemKey="'id'"
                        :noDataText="dataEmptyText"
                        :customTemplatesItemsNames="[`item.actions`]"
                        :options.sync="jobsDataOptions"
                        :serverItemsLength="jobsData.elements"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="text-end">
                                <v-btn icon @click="showMoreJobDetails(item)">
                                    <v-icon> mdi-chevron-right </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Data-table>
                </v-col>
            </v-row>
            <v-row class="paginator">
                <v-col cols="12">
                    <div class="text-center pt-2">
                        <v-pagination :total-visible="5" :disabled="isLoading" v-model="selectedPage" :length="jobsData.pages"></v-pagination>
                    </div>
                </v-col>
            </v-row>
        </div>

        <Dialog :title="'Nowe zlecenie'" :forceActivation="showNewJobDialog" @dialogClosed="showNewJobDialog = false">
            <template v-slot:content="{ deactivate }">
                <Job-add-new @closeDialog="deactivate" :key="componentKey"></Job-add-new>
            </template>
        </Dialog>
    </v-container>
</template>

<script>
import SearchField from '../../global/SearchField.vue';
import DataTable from '../../global/DataTable.vue';
import Dialog from '../../global/Dialog.vue';
import JobAddNew from './JobAddNew.vue';

import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';

export default {
    components: { SearchField, DataTable, Dialog, JobAddNew },
    mixins: [DataTableOptionsMixin],
    name: 'JobsMainSection',

    data: () => ({
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Nazwa', value: 'name' },
            { text: 'Opis', value: 'description' },
            { text: 'Status', value: 'state' },
            { text: 'Data zakończenia', value: 'done_at' },
            { text: 'Właściciel', value: 'user', sortable: false },
            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak zleconych zadań',
        noResultsText: 'Nie znaleziono żadnych zadań',
        dataEmptyText: '',
        addHash: false,
        showNewJobDialog: false,
        componentKey: 0,

        page: null,
        search: '',
        options: {
            sortBy: ['id'],
            sortDesc: [false]
        }
    }),

    computed: {
        selectedPage: {
            get() {
                return this.jobsData?.page ? this.jobsData.page : 1;
            },
            set(value) {
                this.page = value;
            }
        },

        jobsDataOptions: {
            get() {
                return {
                    sortBy: this.jobsSortBy ? [this.jobsSortBy] : this.options.sortBy,
                    sortDesc: this.jobsSortOrder === 'desc' ? [true] : this.options.sortDesc
                };
            },
            set(value) {
                this.options = value;
            }
        },

        storedJobs() {
            return this.$store.getters.jobsItems;
        },

        jobsData() {
            return this.$store.getters.jobsData;
        },

        jobsSortBy() {
            return this.$store.getters.jobsSortBy;
        },

        jobsSortOrder() {
            return this.$store.getters.jobsSortOrder;
        },

        isLoading() {
            return this.$store.getters.isJobsDataLoading;
        },

        isAttachmentDataLoading() {
            return this.$store.getters.isAttachmentsDataLoading;
        },

        formattedJobs() {
            const formattedJobs = JSON.parse(JSON.stringify(this.storedJobs));

            formattedJobs.forEach((job) => {
                job['created_at'] = this.formatDateFrom1970(job['created_at']);
                job['done_at'] = this.formatDateFrom1970(job['done_at']);
                job['state'] = this.formatState(job['state'], job['progress']);
                job['user'] = job.user ? job.user.login : '-';
            });

            return formattedJobs;
        },

        preselectedJobId() {
            return this.$store.getters.preselectedJobId;
        }
    },

    methods: {
        showMoreJobDetails(item) {
            const jobId = item.id;
            this.$store.dispatch('openDetails');
            this.$store.dispatch('getJobById', jobId);
        },

        formatDateFrom1970(date) {
            return date ? new Date(date).toLocaleDateString('en-UK') : '';
        },

        formatState(state, progress) {
            const stateMapper = {
                new: 'nowe',
                processing: `w toku [ ${Number(progress).toFixed(2)}% ]`,
                done: 'zakończono',
                abandoned: 'porzucone przez agenta',
                interrupted: 'przerwane przez użytkownika',
                aborting: 'w trakcie przerywania',
                aborted: 'przerwane przez użytkownika'
            };

            return stateMapper[state] || '';
        },

        async getAttachmentsFromApi() {
            await this.$store.dispatch('getAttachmentsFromApi', { perPage: 256 });
            this.forceRerender();
            this.showNewJobDialog = true;
        },

        forceRerender() {
            this.componentKey += 1;
        },

        openPreselectedJobForDetails() {
            setTimeout(async () => {
                if (!this.preselectedJobId) return;
                this.$store.dispatch('openDetails');
                await this.$store.dispatch('getJobById', this.preselectedJobId);
                this.$store.dispatch('setPreselectedJobId', null);
            }, 300);
        },

        onPageChange(page) {
            this.$store.dispatch('setJobsPage', page);
            this.$store.dispatch('getJobsFromApi');
        },
        onSortChange(sortBy, isSortDesc) {
            this.$store.dispatch('setJobsSortBy', sortBy ? sortBy : '');
            this.$store.dispatch('setJobsSortOrder', isSortDesc ? 'desc' : '');
            this.$store.dispatch('getJobsFromApi');
        },
        onSearchInput(text) {
            this.$store.dispatch('setJobsPage', 1);
            this.$store.dispatch('setJobsSearch', text);
            this.$store.dispatch('getJobsFromApi');
        }
    },

    created() {
        this.dataEmptyText = this.noDataText;
    },

    mounted() {
        this.openPreselectedJobForDetails();
    },

    beforeDestroy() {
        this.$store.dispatch('resetAttachmentsData');
        this.$store.dispatch('setJobsSearch', '');
    },

    watch: {
        storedJobs(newVal) {
            if (this.search && !newVal?.length) {
                this.dataEmptyText = this.noResultsText;
            } else {
                this.dataEmptyText = this.noDataText;
            }
        }
    }
};
</script>
