<template>
    <h2 class="text_primary sub-header">
        <v-icon v-if="showUserIcon" class="mb-1" icon :color="CSS_COLOR.textHighlight"> mdi-account-outline </v-icon>
        {{ title }}
    </h2>
</template>

<script>
import { CSS_COLOR } from '../../_helpers/consts';

export default {
    name: 'HiddenSectionTitle',

    data() {
        return {
            CSS_COLOR,
            titleCaseString: {
                loading: 'Wczytywanie...',
                error: 'Wystąpił błąd'
            }
        };
    },

    computed: {
        title() {
            switch (this.titleCase) {
                case 'error':
                    return this.titleCaseString['error'];
                case 'loading':
                    return this.titleCaseString['loading'];
                case 'title':
                    return this.titleText;
                default:
                    return '-';
            }
        }
    },

    props: {
        showUserIcon: {
            type: Boolean,
            default: false
        },
        titleText: {
            type: String,
            default: ''
        },
        titleCase: {
            type: String,
            default: 'title',
            validator: (value) => ['title', 'error', 'loading'].includes(value)
        }
    }
};
</script>
