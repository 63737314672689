import { usersService } from '../../services/users.service';

const state = () => ({
    loggedUserRole: {
        admin: false,
        user: false
    },
    loggedUserDetails: {},
    isCurrentUserDataLoading: false
});

// getters
const getters = {
    loggedUserRole: (state) => {
        return state.loggedUserRole;
    },

    loggedUserDetails: (state) => {
        return state.loggedUserDetails;
    },

    isAdminLogged: (state) => {
        return state.loggedUserRole.admin;
    },

    isCurrentUserDataLoading: (state) => {
        return state.isCurrentUserDataLoading;
    }
};

// actions
const actions = {
    async getLoggedUserFromApi({ commit, state }) {
        if (state.isCurrentUserDataLoading) return;
        commit('isCurrentUserDataLoadingChange');

        const response = await usersService.getCurrentUser();
        if (response.status !== 200) {
            commit('isCurrentUserDataLoadingChange');
            return;
        }

        if (!response.data) {
            commit('isCurrentUserDataLoadingChange');
            return;
        }

        commit('loggedUserData', response.data);
        commit('loggedUserRole', response.data);

        commit('isCurrentUserDataLoadingChange');
    }
};

// mutations
const mutations = {
    loggedUserData(state, payload) {
        state.loggedUserDetails = payload;
    },

    loggedUserRole(state, payload) {
        state.loggedUserRole.admin = payload.roles.some((role) => role.name === 'ADMIN');
        state.loggedUserRole.user = payload.roles.some((role) => role.name === 'USER');
    },

    isCurrentUserDataLoadingChange(state) {
        if (state.isCurrentUserDataLoading == true) {
            setTimeout(function () {
                state.isCurrentUserDataLoading = false;
            }, 750);
        } else {
            state.isCurrentUserDataLoading = true;
        }
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
