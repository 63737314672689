<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <h2 class="text_primary sub-header">Agenci</h2>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <div class="pl-6 pr-6 mb-14">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <h3 class="sub-title">Dostępni agenci</h3>
                </v-col>
                <v-col cols="auto">
                    <Search-field v-model.lazy="search" />
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4 pl-6 pr-6"></v-divider>
            <v-row>
                <v-col cols="12">
                    <Data-table
                        :must-sort="true"
                        :disable-sort="isLoading"
                        :headers="headers"
                        :tableData="formattedAgents"
                        :loading="isLoading"
                        :itemKey="'id'"
                        :noDataText="dataEmptyText"
                        :customTemplatesItemsNames="[`item.state`, `item.resources`, `item.actions`]"
                        :options.sync="agentsDataOptions"
                        :serverItemsLength="agentsData.elements"
                    >
                        <template v-slot:[`item.state`]="{ item }">
                            <template v-if="item.verified === 'verified'">
                                {{ item.ser }}
                                <span v-if="item.service" class="warning--text">service</span>
                                <template v-else>{{ item.state }}</template>
                            </template>
                            <span v-else class="error--text">not verified</span>
                        </template>
                        <template v-slot:[`item.resources`]="{ item }">
                            <div v-if="item.verified === 'verified'">
                                <span
                                    :class="[isResourceActive(item.resources, 'cpu') ? 'tag_active' : 'tag_inactive']"
                                    class="tag tag_width-40 mr-1 mb-1"
                                    >CPU</span
                                >
                                <span
                                    :class="[isResourceActive(item.resources, 'gpu') ? 'tag_active' : 'tag_inactive']"
                                    class="tag tag_width-40 mr-1 mb-1"
                                    >GPU</span
                                >
                                <span
                                    :class="[isResourceActive(item.resources, 'fpga') ? 'tag_active' : 'tag_inactive']"
                                    class="tag tag_width-40"
                                    >FPGA</span
                                >
                            </div>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="text-end">
                                <v-btn icon @click="showMoreAgentDetails(item)">
                                    <v-icon> mdi-chevron-right </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Data-table>
                </v-col>
            </v-row>
            <v-row class="paginator">
                <v-col cols="12">
                    <div class="text-center pt-2">
                        <v-pagination :total-visible="5" :disabled="isLoading" v-model="selectedPage" :length="agentsData.pages"></v-pagination>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import SearchField from '../../global/SearchField.vue';
import DataTable from '../../global/DataTable.vue';

import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';

export default {
    components: { SearchField, DataTable },
    mixins: [DataTableOptionsMixin],
    name: 'AgentsMainSection',

    data: () => ({
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Nazwa', value: 'name' },
            { text: 'Opis', value: 'description' },
            { text: 'Data dodania', value: 'created_at' },
            { text: 'Stan', value: 'state' },
            { text: 'Wersja', value: 'version' },
            { text: 'Tryb', value: 'work_mode' },
            { text: 'IP', value: 'ip' },
            { text: 'Zasoby', value: 'resources', sortable: false },
            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak agentów do wyświetlenia',
        noResultsText: 'Nie znaleziono żadnych agentów',
        dataEmptyText: '',
        addHash: false,

        page: null,
        search: '',
        options: {
            sortBy: ['id'],
            sortDesc: [false]
        }
    }),

    computed: {
        selectedPage: {
            get() {
                return this.agentsData?.page ? this.agentsData.page : 1;
            },
            set(value) {
                this.page = value;
            }
        },

        agentsDataOptions: {
            get() {
                return {
                    sortBy: this.agentsSortBy ? [this.agentsSortBy] : this.options.sortBy,
                    sortDesc: this.agentsSortOrder === 'desc' ? [true] : this.options.sortDesc
                };
            },
            set(value) {
                this.options = value;
            }
        },

        storedAgents() {
            return this.$store.getters.agentsItems;
        },

        agentsData() {
            return this.$store.getters.agentsData;
        },

        agentsSortBy() {
            return this.$store.getters.agentsSortBy;
        },

        agentsSortOrder() {
            return this.$store.getters.agentsSortOrder;
        },

        isLoading() {
            return this.$store.getters.isAgentsDataLoading;
        },

        formattedAgents() {
            const formattedAgents = JSON.parse(JSON.stringify(this.storedAgents));

            formattedAgents.forEach((agent) => {
                agent['created_at'] = this.formatDateFrom1970(agent['created_at']);
            });

            return formattedAgents;
        },

        preselectedAgentId() {
            return this.$store.getters.preselectedAgentId;
        }
    },

    methods: {
        showMoreAgentDetails(item) {
            const selectedAgentId = item.id;
            this.$store.dispatch('openDetails');
            this.$store.dispatch('getAgentById', selectedAgentId);
        },

        openPreselectedAgentForDetails() {
            setTimeout(async () => {
                if (!this.preselectedAgentId) return;
                this.$store.dispatch('openDetails');
                await this.$store.dispatch('getAgentById', this.preselectedAgentId);
                this.$store.dispatch('setPreselectedAgentId', null);
            }, 300);
        },

        formatDateFrom1970(date) {
            return date ? new Date(date).toLocaleDateString('en-UK') : '';
        },

        isResourceActive(resources, name) {
            const usedResource = resources.find((o) => o.type === name);
            if (usedResource) {
                return true;
            }
            return false;
        },

        onPageChange(page) {
            this.$store.dispatch('setAgentsPage', page);
            this.$store.dispatch('getAgentsFromApi');
        },
        onSortChange(sortBy, isSortDesc) {
            this.$store.dispatch('setAgentsSortBy', sortBy ? sortBy : '');
            this.$store.dispatch('setAgentsSortOrder', isSortDesc ? 'desc' : '');
            this.$store.dispatch('getAgentsFromApi');
        },
        onSearchInput(text) {
            this.$store.dispatch('setAgentsPage', 1);
            this.$store.dispatch('setAgentsSearch', text);
            this.$store.dispatch('getAgentsFromApi');
        }
    },

    mounted() {
        this.openPreselectedAgentForDetails();
    },

    created() {
        this.dataEmptyText = this.noDataText;
    },

    beforeDestroy() {
        this.$store.dispatch('setAgentsSearch', '');
    },

    watch: {
        storedAgents(newVal) {
            if (this.search && !newVal?.length) {
                this.dataEmptyText = this.noResultsText;
            } else {
                this.dataEmptyText = this.noDataText;
            }
        }
    }
};
</script>
