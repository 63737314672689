<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <Job-add-new-form ref="form" @formUpdated="updateFormData"></Job-add-new-form>
            </v-container>
        </v-card-text>
        <v-card-text class="pa-0 pr-4 pl-4">
            <p>
                <span>Czas przetwarzania: </span>
                <template v-if="estimated">
                    <span class="pl-1 pr-1">{{ this.estimatedTime.days }} d. </span>
                    <span class="pl-1 pr-1">{{ this.estimatedTime.hours }} h. </span>
                    <span class="pl-1 pr-1">{{ this.estimatedTime.minutes }} min. </span>
                    <span class="pl-1 pr-1">{{ this.estimatedTime.seconds }} sec.</span>
                </template>
            </p>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-space-between">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('closeDialog')">Anuluj</v-btn>
                    </v-card-actions>

                    <v-card-actions>
                        <v-btn :disabled="estimated" class="v-btn__primary mr-2" @click="sendEstimationRequest">Oszacuj</v-btn>
                        <v-tooltip v-if="!estimated" top>
                            <template v-slot:activator="{ on }">
                                <div v-on="on">
                                    <v-btn disabled class="v-btn__primary">Dodaj zlecenie</v-btn>
                                </div>
                            </template>
                            <span>
                                Aby dodać nowe zlecenie najpierw <br />
                                oszacuj czas jego przetwarzania
                            </span>
                        </v-tooltip>
                        <v-btn v-else class="v-btn__primary" @click="sendRequestAndCloseDialog">Dodaj zlecenie</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import JobAddNewForm from './JobAddNewForm.vue';
import { jobsService } from '../../../services/jobs.service';

export default {
    components: { JobAddNewForm },
    name: 'JobAddNew',

    data() {
        return {
            newJob: null,
            formValidated: false,
            estimated: false,
            estimatedTime: {
                days: '',
                hours: '',
                minutes: '',
                seconds: ''
            }
        };
    },

    computed: {
        newJobStored() {
            return this.$store.getters.newJob;
        },

        hash() {
            return this.newJobStored?.hash;
        },

        file() {
            return this.newJobStored?.file;
        }
    },

    methods: {
        async sendRequestAndCloseDialog() {
            if (!this.checkFormValidation() || !this.estimated) {
                return;
            }

            const response = await jobsService.postNewJob(this.newJob);

            this.$emit('closeDialog');
            if (response.status == 201) {
                this.getUpdatedJobsData();
            }
        },

        async sendEstimationRequest() {
            if (!this.checkFormValidation()) {
                return;
            }

            const response = await jobsService.estimateNewJob(this.newJob);

            if (response.status == 200) {
                this.showEstimatedTime(response.data);
            }
        },

        showEstimatedTime(estimatedTime) {
            Object.assign(this.estimatedTime, estimatedTime);
            this.estimated = true;
        },

        checkFormValidation() {
            this.$refs.form.$v.$touch();

            if (!this.newJob || !this.formValidated) {
                return false;
            }
            return true;
        },

        async getUpdatedJobsData() {
            await this.$store.dispatch('getJobsFromApi');
        },

        updateFormData(item, validatedError) {
            this.newJob = item;
            this.formValidated = !validatedError;
            this.estimated = false;
        }
    }
};
</script>
