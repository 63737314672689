import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';
import { generateQueryString } from '../_helpers/general';

export const agentsService = {
    getAgents,
    getAgentById,
    updateAgent,
    updateAgentWorkMode,

    getAgentLastCsr,
    acceptCsr,
    rejectCsr,
    downloadCsr
};

async function getAgents(queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const queryString = generateQueryString(queryObj);

    const response = await fetch(`${config.apiUrl}/${config.agentsUrl}${queryString}`, requestOptions);
    const agents = await globalService.handleResponse(response);

    return agents;
}

async function getAgentById(id) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.agentsUrl}/${id}`, requestOptions);
    const agent = await globalService.handleResponse(response);
    return agent;
}

async function updateAgent(agent) {
    const updatedData = {
        name: agent.name,
        description: agent.description
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.agentsUrl}/${agent.id}`, requestOptions);
    const updatedAgent = await globalService.handleResponse(response);

    return updatedAgent;
}

async function updateAgentWorkMode(agentId, workMode) {
    const updatedData = {
        work_mode: workMode
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.agentChangeWorkModeUrl(agentId)}`, requestOptions);
    const updatedAgent = await globalService.handleResponse(response);

    return updatedAgent;
}

async function getAgentLastCsr(agentId) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.agentLastCsrUrl(agentId)}`, requestOptions);
    const csr = await globalService.handleResponse(response);

    return csr;
}

async function acceptCsr(csrId, certificate) {
    const body = {
        action: 'accept',
        certificate
    };

    let json;
    try {
        json = JSON.stringify(body);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: json
    };

    const response = await fetch(`${config.apiUrl}/${config.csrsUrl}/${csrId}`, requestOptions);
    const csr = await globalService.handleResponse(response);

    return csr;
}

async function rejectCsr(csrId) {
    const body = {
        action: 'reject'
    };

    let json;
    try {
        json = JSON.stringify(body);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: json
    };

    const response = await fetch(`${config.apiUrl}/${config.csrsUrl}/${csrId}`, requestOptions);
    const csr = await globalService.handleResponse(response);

    return csr;
}

function downloadCsr(csrId) {
    return `${config.apiUrl}/${config.csrDownloadUrl(csrId)}`;
}
