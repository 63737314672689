import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';
import { generateQueryString } from '../_helpers/general';

export const usersService = {
    getUsers,
    getUserById,
    getCurrentUser,
    updateCurrentUser,
    updateUser,
    deleteUser,
    postNewUser,
    updateUserPassword,
    updateCurrentUserPassword,
    updateUserRole
};

async function getUsers(queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const queryString = generateQueryString(queryObj);

    const response = await fetch(`${config.apiUrl}/${config.usersUrl}${queryString}`, requestOptions);
    const users = await globalService.handleResponse(response);

    return users;
}

async function getUserById(userId) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.usersUrl}/${userId}`, requestOptions);
    const userDetails = await globalService.handleResponse(response);
    return userDetails;
}

async function deleteUser(userId) {
    const requestOptions = {
        method: 'DELETE',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.usersUrl}/${userId}`, requestOptions);
    const deleteUser = await globalService.handleResponse(response);
    return deleteUser;
}

async function updateUser(user) {
    const updatedData = {
        ...user
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.usersUrl}/${user.id}`, requestOptions);
    const updatedUser = await globalService.handleResponse(response);

    return updatedUser;
}

async function postNewUser(newUser) {
    let jsonDate;

    try {
        jsonDate = JSON.stringify(newUser);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.usersUrl}`, requestOptions);
    const addedUser = await globalService.handleResponse(response);

    return addedUser;
}

async function getCurrentUser() {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.currentUserUrl}`, requestOptions);
    const user = await globalService.handleResponse(response);

    return user;
}

async function updateCurrentUser(user) {
    const updatedData = {
        ...user
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.currentUserUrl}`, requestOptions);
    const updatedUser = await globalService.handleResponse(response);

    return updatedUser;
}

async function updateUserPassword(user, password) {
    const updatedData = {
        password
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.userPasswordChangeUrl(user.id)}`, requestOptions);
    const updatedUser = await globalService.handleResponse(response);

    return updatedUser;
}

async function updateCurrentUserPassword(password) {
    const updatedData = {
        password
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.currentUserPasswordChangeUrl}`, requestOptions);
    const updatedUser = await globalService.handleResponse(response);

    return updatedUser;
}

async function updateUserRole(user, roles) {
    const updatedData = {
        ...roles
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.userRoleChangeUrl(user.id)}`, requestOptions);
    const updatedUser = await globalService.handleResponse(response);

    return updatedUser;
}
