import router from '../../router';
import { replaceObjectFields } from '../../_helpers/general';
import { agentsService } from '../../services/agents.service';

const state = () => ({
    agentsData: {},
    agentsItems: [],
    agentsSortBy: '',
    agentsSortOrder: '',
    agentsSearch: '',
    isAgentsDataLoading: false,
    agentsLastUpdateTime: '',

    preselectedAgentId: null,
    selectedAgentForDetails: null,
    isAgentNewDataLoading: false,
    isAgentRefetching: false,

    agentLastCsr: null,
    isAgentLastCsrLoading: false,

    shouldAgentResourcesAutoReload: true,

    agentSchema: {
        created_at: null,
        description: '',
        id: null,
        identifier: '',
        ip: '',
        name: '',
        resources: [],
        serial_number: '',
        state: '',
        verified: '',
        version: '',
        work_mode: ''
    }
});

// getters
const getters = {
    agentsData: (state) => {
        return state.agentsData;
    },

    agentsItems: (state) => {
        return state.agentsItems;
    },

    agentsSortBy: (state) => {
        return state.agentsSortBy;
    },

    agentsSortOrder: (state) => {
        return state.agentsSortOrder;
    },

    agentsSearch: (state) => {
        return state.agentsSearch;
    },

    isAgentsDataLoading: (state) => {
        return state.isAgentsDataLoading;
    },

    agentsLastUpdateTime: (state) => {
        return state.agentsLastUpdateTime;
    },

    selectedAgent: (state) => {
        return state.selectedAgentForDetails;
    },

    isAgentNewDataLoading: (state) => {
        return state.isAgentNewDataLoading;
    },

    isAgentRefetching: (state) => {
        return state.isAgentRefetching;
    },

    agentLastCsr: (state) => {
        return state.agentLastCsr;
    },

    isAgentLastCsrLoading: (state) => {
        return state.isAgentLastCsrLoading;
    },

    isAgentVerified: (state) => {
        return state.selectedAgentForDetails && state.selectedAgentForDetails.verified === 'verified';
    },

    reloadingSelectedAgentStatus: (state) => {
        return state.reloadingSelectedAgentStatus;
    },

    shouldAgentResourcesAutoReload: (state) => {
        return state.shouldAgentResourcesAutoReload;
    },

    preselectedAgentId: (state) => {
        return state.preselectedAgentId;
    }
};

// actions
const actions = {
    async getAgentsFromApi({ commit, dispatch, state, rootState }, { perPage } = {}) {
        if (router.currentRoute.name === 'SystemStatus') {
            perPage = 100;
        }

        const per_page = perPage ?? rootState.global.itemsPerPage;
        const page = state.agentsData.page;
        const sort = state.agentsSortBy;
        const order = state.agentsSortOrder;
        const search = state.agentsSearch;

        commit('isAgentsDataLoadingChange');

        const response = await agentsService.getAgents({ per_page, page, sort, order, search });

        if (response.status !== 200) {
            commit('isAgentsDataLoadingChange');
            return;
        }

        const { content, ...data } = response.data;

        commit('agentsData', data);
        commit('agentsItems', content);

        dispatch('autoRefreshResetTimeout');
        commit('isAgentsDataLoadingChange');
    },

    async getAgentById({ commit, state }, agentId) {
        if (state.isAgentNewDataLoading) return;

        if (!state.isAgentRefetching) {
            commit('setAgentNewDataLoading', true);
        }

        const response = await agentsService.getAgentById(agentId);

        if (response.status !== 200) {
            commit('resetAgentForDetails');
            commit('setAgentNewDataLoading', false);
            commit('setAgentRefetching', false);
            return;
        }

        const agentData = { ...state.agentSchema, ...response.data };

        if (state.isAgentRefetching) {
            commit('setAgentForDetailsSilently', agentData);
        } else {
            commit('setAgentForDetails', agentData);
        }

        commit('setAgentRefetching', false);
        commit('setAgentNewDataLoading', false);
    },

    async getAgentLastCsr({ commit, state }, agentId) {
        if (state.isAgentLastCsrLoading) return;

        if (!state.isAgentRefetching) {
            commit('setAgentLastCsrLoading', true);
        }

        const response = await agentsService.getAgentLastCsr(agentId);

        if (response.status !== 200) {
            commit('resetAgentLastCsrData');
            commit('setAgentLastCsrLoading', false);
            return;
        }

        commit('setAgentLastCsrData', response.data);
        commit('setAgentLastCsrLoading', false);
    },

    resetAgentsData({ commit }) {
        commit('resetAgentsData');
    },

    setAgentsPage({ commit }, page) {
        commit('setAgentsPage', page);
    },

    setAgentsSortBy({ commit }, fieldName) {
        commit('setAgentsSortBy', fieldName);
    },

    setAgentsSortOrder({ commit }, order) {
        commit('setAgentsSortOrder', order);
    },

    setAgentsSearch({ commit }, text) {
        commit('setAgentsSearch', text);
    },

    setAgentsLastUpdateTime({ commit }) {
        const dataTime = new Date().toLocaleString();
        commit('setAgentsLastUpdateTime', dataTime);
    },

    setAgentRefetch({ commit }) {
        commit('setAgentRefetching', true);
    },

    changeSelectedAgentAutoReload({ commit }) {
        commit('changeSelectedAgentAutoReload');
    },

    setPreselectedAgentId({ commit }, agentId) {
        commit('setPreselectedAgentId', agentId);
    }
};

// mutations
const mutations = {
    agentsData(state, payload) {
        state.agentsData = payload;
    },

    agentsItems(state, payload) {
        state.agentsItems = payload;
    },

    resetAgentsData(state) {
        state.agentsData = {};
        state.agentsItems = [];
    },

    setAgentsLastUpdateTime(state, payload) {
        state.agentsLastUpdateTime = payload;
    },

    setAgentsPage(state, payload) {
        state.agentsData.page = payload;
    },

    setAgentsSortBy(state, payload) {
        state.agentsSortBy = payload;
    },

    setAgentsSortOrder(state, payload) {
        state.agentsSortOrder = payload;
    },

    setAgentsSearch(state, payload) {
        state.agentsSearch = payload;
    },

    resetAgentForDetails(state) {
        state.selectedAgentForDetails = null;
    },

    setAgentForDetails(state, agent) {
        state.selectedAgentForDetails = agent;
    },

    resetAgentLastCsrData(state) {
        state.agentLastCsr = null;
    },

    setAgentLastCsrData(state, lastCsr) {
        state.agentLastCsr = lastCsr;
    },

    setAgentForDetailsSilently(state, agent) {
        replaceObjectFields(state.selectedAgentForDetails, agent);
    },

    isAgentsDataLoadingChange(state) {
        if (state.isAgentsDataLoading == true) {
            setTimeout(function () {
                state.isAgentsDataLoading = false;
            }, 750);
        } else {
            state.isAgentsDataLoading = true;
        }
    },

    setAgentNewDataLoading(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isAgentNewDataLoading = false;
            }, 250);
        } else {
            state.isAgentNewDataLoading = true;
        }
    },

    setAgentRefetching(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isAgentRefetching = false;
            }, 250);
        } else {
            state.isAgentRefetching = true;
        }
    },

    setAgentLastCsrLoading(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isAgentLastCsrLoading = false;
            }, 250);
        } else {
            state.isAgentLastCsrLoading = true;
        }
    },

    changeSelectedAgentAutoReload(state) {
        state.shouldAgentResourcesAutoReload = !state.shouldAgentResourcesAutoReload;
    },

    setPreselectedAgentId(state, agentId) {
        state.preselectedAgentId = agentId;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
    // namespaced: true,
};
