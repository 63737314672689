<template>
    <div>
        <v-card-text class="pa-2">
            <v-container>
                <div>
                    <v-row class="text-left">
                        <v-col cols="12">
                            <v-form ref="form" class="mr-auto ml-auto ml-md-0">
                                <div>
                                    <Files-uploader
                                        :btnText="'+ Plik słownika'"
                                        ref="filesUploader"
                                        @fileUpload="handleFileUpload"
                                    ></Files-uploader>
                                </div>

                                <!-- <div class="mb-6 mt-4">
                                    <template v-if="newAttachment.selectedFiles.length">
                                        <div class="pb-1" v-for="(file, index) in newAttachment.selectedFiles" :key="`${index}_${file.name}`">
                                            <v-icon class="pr-2 v-icon_highlight"> mdi-file-outline </v-icon>
                                            <span class="pr-2">{{ file.name }}</span>
                                            <v-btn icon @click="removeSelectedFile(index)">
                                                <v-icon class="v-icon"> mdi-close </v-icon>
                                            </v-btn>
                                        </div>
                                    </template>
                                </div> -->

                                <v-text-field
                                    v-model="newAttachment.name"
                                    outlined
                                    label="Nazwa:"
                                    class="v-input_required"
                                    :color="CSS_COLOR.textHighlight"
                                    :error-messages="nameErrors"
                                    @input="$v.newAttachment.name.$touch()"
                                    @blur="$v.newAttachment.name.$touch()"
                                ></v-text-field>

                                <v-select
                                    v-model="newAttachment.attachmentType.selected"
                                    label="Typ słownika"
                                    attach
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                                    :items="newAttachment.attachmentType.options"
                                ></v-select>

                                <v-textarea
                                    class="v-input_full-width"
                                    label="Opis:"
                                    height="95px"
                                    outlined
                                    :no-resize="true"
                                    v-model="newAttachment.description"
                                ></v-textarea>
                            </v-form>
                        </v-col>
                    </v-row>
                </div>
                <v-row class="justify-end">
                    <v-col cols="auto">
                        <span></span>
                        <!-- <span>Słownik zostanie dodany po skończeniu przesyłania</span> -->
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4 js__close-btn" @click="checkIsPossibleToCloseDialog">Anuluj</v-btn>
                        <v-btn :disabled="!isFileUploadComplete" class="v-btn__primary" @click="sendRequestAndCloseDialog">Dodaj słownik</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>

        <Confirmation-dialog
            :cancelButtonText="'Anuluj'"
            :confirmationButtonText="'Przerwij przesyłanie i zamknij'"
            :isQuestionHtmlContent="true"
            :questionContent="`<h2 class='text_primary text-center mb-2'>Zamknięcie okna spowoduje przerwanie procesu przesyłania pliku. <br><br> Czy na pewno przerwać ?</h2>`"
            :dialogWidth="'30%'"
            :title="'Trwa wysyłanie słownika'"
            :confirmationButtonClass="'v-btn__alert'"
            v-model="showClosingDialog"
            @confirmEvent="interruptUploadingAndCloseDialog"
        >
        </Confirmation-dialog>
    </div>
</template>

<script>
import FilesUploader from './FilesUploader.vue';
import { attachmentsService } from '../../../services/attachments.service';
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ConfirmationDialog from '../../global/ConfirmationDialog.vue';

export default {
    name: 'AttachmentAddNewDialog',

    components: {
        FilesUploader,
        ConfirmationDialog
    },

    mixins: [validationMixin],

    validations: {
        newAttachment: {
            name: { required },
            attachmentType: { required }
        }
    },

    data() {
        return {
            CSS_COLOR,
            NOTIFICATIONS,

            newAttachment: {
                name: '',
                attachmentType: {
                    options: [
                        { text: 'słownikowy', value: 'dictionary' },
                        { text: 'maskowy', value: 'maskfile' }
                    ],
                    selected: 'dictionary'
                },
                description: ''
            },

            selectedFile: null,
            isFileUploading: false,
            sessionId: null,

            showClosingDialog: false,
            closingConfirmed: false
        };
    },

    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.newAttachment.name.$dirty) return errors;
            !this.$v.newAttachment.name.required && errors.push('Nazwa jest wymagana');
            return errors;
        },

        isFileUploadComplete() {
            return !this.isFileUploading && this.selectedFile;
        }
    },

    methods: {
        handleFileUpload(isUploading, file, sessionId) {
            this.isFileUploading = isUploading;
            this.selectedFile = file;
            this.sessionId = sessionId;
        },

        async sendRequestAndCloseDialog() {
            if (!this.checkFormValidation()) {
                return;
            }

            const newAttachment = {
                name: this.newAttachment.name,
                type: this.newAttachment.attachmentType.selected,
                path: `${this.sessionId}/${this.selectedFile.name}`,
                description: this.newAttachment.description
            };

            const response = await attachmentsService.postNewAttachment(newAttachment);

            if (response.status == 200 || response.status == 201) {
                try {
                    localStorage.removeItem('upload-file-temp-session-id');
                    localStorage.removeItem('upload-file-complete');
                } catch (error) {
                    console.warn(error);
                }
                this.$emit('deactivate');
                this.getUpdatedAttachmentsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Dodano nowy załącznik'));
            }
        },

        checkFormValidation() {
            this.$v.$touch();

            if (!this.newAttachment || this.$v.newAttachment.name.$anyError) {
                return false;
            }

            return true;
        },

        async getUpdatedAttachmentsData() {
            await this.$store.dispatch('getAttachmentsFromApi');
        },

        checkIsPossibleToCloseDialog() {
            if (this.isFileUploadComplete || this.isFileUploading) {
                this.showClosingDialog = true;
                return;
            }
            this.$emit('deactivate');
        },

        interruptUploadingAndCloseDialog() {
            this.closingConfirmed = true;
            this.$refs.filesUploader.removeFile();
            this.$emit('deactivate');
        }
    }
};
</script>
