<template>
    <System-Status-Index></System-Status-Index>
</template>

<script>
import SystemStatusIndex from '../components/system-status/index';

export default {
    components: {
        SystemStatusIndex
    }
};
</script>
