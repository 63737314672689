<template>
    <Main-section :hideSecondaryCol="false">
        <template v-slot:left-col>
            <Agents-main-section></Agents-main-section>
        </template>
        <template v-slot:right-col><Agents-hidden-section></Agents-hidden-section> </template>
    </Main-section>
</template>

<script>
import MainSection from '../global/MainSection.vue';
import AgentsHiddenSection from './subcomponents/AgentsHiddenSection.vue';
import AgentsMainSection from './subcomponents/AgentsMainSection.vue';

export default {
    name: 'AgentsIndex',

    components: {
        MainSection,
        AgentsMainSection,
        AgentsHiddenSection
    },

    data: () => {
        return {
            routeName: 'Agents'
        };
    },

    computed: {
        shouldAutoReload() {
            return this.$store.getters.autoRefreshData;
        },

        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },

        isAgentVerified() {
            return this.$store.getters.isAgentVerified;
        },

        preselectedAgentId() {
            return this.$store.getters.preselectedAgentId;
        }
    },

    watch: {
        shouldAutoReload(newBooleanValue) {
            if (newBooleanValue) {
                this.$store.dispatch('autoRefreshCallback', this.getAgentsData);
                this.getAgentsData();
            } else {
                this.$store.dispatch('autoRefreshCallback', null);
            }
        }
    },

    methods: {
        async getAgentsData() {
            await this.$store.dispatch('getAgentsFromApi');

            this.$store.dispatch('setAgentsLastUpdateTime');

            this.selectedAgent ? this.refreshSelectedAgent() : null;
        },

        refreshSelectedAgent() {
            if (this.preselectedAgentId) return;
            this.$store.dispatch('setAgentRefetch');
            this.$store.dispatch('getAgentById', this.selectedAgent.id);
            if (!this.isAgentVerified) {
                this.$store.dispatch('getAgentLastCsr', this.selectedAgent.id);
            }
        }
    },

    mounted() {
        this.getAgentsData();
        this.$store.dispatch('autoRefreshCallback', this.getAgentsData);
    },

    beforeDestroy() {
        this.$store.dispatch('resetAgentsData');

        this.$store.dispatch('autoRefreshCallback', null);
    }
};
</script>
