<template>
    <v-container fluid class="fill-height align-start pb-0 main-section">
        <v-row class="text-center fill-height main-section__row">
            <v-col class="main-section__col main-section__col_primary">
                <slot name="left-col"></slot>
            </v-col>
            <div class="main-section__col main-section__col_secondary pa-0" :class="{ 'main-section__col_hide': hideSecondaryCol }">
                <div class="pa-3">
                    <slot name="right-col"></slot>
                </div>
            </div>
            <div :class="{ 'main-section__overlay': !hideSecondaryCol }"></div>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'MainSection',

    data: () => ({
        expand: false,
        secondaryColSize: 4
    }),

    computed: {
        hideSecondaryCol() {
            return !this.$store.getters.showDetails;
        }
    },

    mounted() {
        this.$store.dispatch('setWindowHeight', window.innerHeight);
        this.$store.dispatch('setItemsPerPage');
    }
};
</script>
