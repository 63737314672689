<template>
    <Dialog :dialogWidth="dialogWidth" :title="title" :forceActivation="value" @dialogClosed="handleCloseDialog()">
        <template v-slot:content="{ deactivate }">
            <div>
                <v-card-text>
                    <v-container class="pl-16 pr-16">
                        <v-row class="justify-center">
                            <v-col v-if="!isQuestionHtmlContent" cols="12">
                                <h2 class="text_primary text-center mb-2">{{ questionContent }}?</h2>
                            </v-col>
                            <v-col v-if="isQuestionHtmlContent" cols="12">
                                <div v-html="questionContent"></div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider class="v-divider_light"></v-divider>
                <v-card-actions class="pa-2">
                    <v-container>
                        <v-row class="justify-end">
                            <v-card-actions>
                                <v-btn class="v-btn__secondary mr-4" @click="deactivate(), $emit('cancelEvent'), handleCloseDialog()">{{
                                    cancelButtonText
                                }}</v-btn>
                                <v-btn
                                    class="mr-4"
                                    :class="confirmationButtonClass"
                                    @click="deactivate(), $emit('confirmEvent'), handleCloseDialog()"
                                    >{{ confirmationButtonText }}</v-btn
                                >
                            </v-card-actions>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </div>
        </template>
    </Dialog>
</template>

<script>
import { CSS_COLOR } from '../../_helpers/consts';
import Dialog from './Dialog.vue';

export default {
    name: 'ConfirmationDialog',

    components: {
        Dialog
    },

    data() {
        return {
            CSS_COLOR
        };
    },

    props: {
        value: Boolean,
        isQuestionHtmlContent: {
            type: Boolean,
            default: false
        },
        title: String,
        dialogWidth: String,
        questionContent: String,
        confirmationButtonText: String,
        confirmationButtonClass: String,
        cancelButtonText: String
    },

    methods: {
        handleCloseDialog() {
            this.$emit('input', false);
        }
    }
};
</script>
