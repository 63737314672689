<template>
    <v-data-table
        v-model="selected"
        :must-sort="mustSort"
        :disable-sort="disableSort"
        :show-select="showSelect"
        :headers="headers"
        :hide-default-footer="hideDefaultFooter"
        :items="tableData"
        :item-key="itemKey"
        :item-class="itemClass"
        :loading="loading"
        :loading-text="loadingText"
        :height="height"
        :page="page"
        @update:page="updatePage"
        :items-per-page="itemsPerPage"
        @page-count="$emit('page-count', $event)"
        :search="search"
        :options="options"
        @update:options="updateOptions"
        :server-items-length="serverItemsLength"
    >
        <template v-for="itemName in customTemplatesItemsNames" v-slot:[itemName]="{ item }">
            <div :key="itemName">
                <slot v-bind:item="item" :name="itemName"></slot>
            </div>
        </template>

        <template v-slot:no-data>{{ noDataText }}</template>
        <template v-slot:no-results>{{ noResultsText }}</template>

        <template v-for="headerName in customTemplatesHeadersNames" v-slot:[headerName]="{ header }">
            <div :key="headerName">
                {{ header.text.toUpperCase() }}
            </div>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'DataTable',

    props: {
        headers: Array,
        tableData: Array,
        loading: Boolean,
        itemKey: String,
        itemClass: [String, Function],
        noDataText: String,
        noResultsText: {
            type: String,
            default: 'Brak rekordów'
        },
        customTemplatesItemsNames: Array,
        customTemplatesHeadersNames: Array,
        loadingText: {
            type: String,
            default: 'Wczytywanie... proszę czekać'
        },
        showSelect: {
            type: Boolean,
            default: true
        },
        mustSort: {
            type: Boolean,
            default: false
        },
        disableSort: {
            type: Boolean,
            default: false
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true
        },
        itemsPerPage: {
            type: Number,
            default: -1
        },
        page: {
            type: Number,
            default: 1
        },
        options: Object,
        serverItemsLength: Number,
        height: [Number, String],
        search: String
    },

    data() {
        return {
            selected: []
        };
    },

    methods: {
        updatePage(e) {
            this.$emit('update:page', e);
        },
        updateOptions(e) {
            this.$emit('update:options', e);
        }
    }
};
</script>
