<template>
    <v-text-field
        outlined
        single-line
        hide-details
        prepend-inner-icon="mdi-magnify"
        clearable
        clear-icon="mdi-close"
        class="pt-0 field-width"
        :value="value"
        @input="$emit('input', $event)"
    ></v-text-field>
</template>

<script>
export default {
    name: 'SearchField',

    props: {
        value: String
    }
};
</script>

<style scoped>
.field-width {
    width: 300px;
}
</style>
