<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <v-row>
            <v-col cols="12">
                <Data-table
                    :headers="headers"
                    :tableData="formattedJobsMaterials"
                    :itemKey="'id'"
                    :noDataText="noDataText"
                    :show-select="false"
                    :customTemplatesItemsNames="[`item.shortName`, `item.actions`]"
                    class="v-data-table_secondary v-data-table_narrow"
                >
                    <template v-slot:[`item.shortName`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ item.shortName }}</span>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item['results'] && item['results'].length" class="text-start">
                            <v-btn icon @click="openDialogWithPassword(item)">
                                <v-icon> mdi-card-search-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </Data-table>
            </v-col>
        </v-row>

        <Dialog :title="'Hasło'" :forceActivation="showJobPass" @dialogClosed="closeDialog">
            <template v-slot:content="{ deactivate }">
                <job-show-password :textPassword="clickedMaterialPasswords" @deactivate="deactivate"></job-show-password>
            </template>
        </Dialog>
    </v-card>
</template>

<script>
import DataTable from '../../global/DataTable.vue';
import Dialog from '../../global/Dialog.vue';
import JobShowPassword from './JobShowPassword.vue';

export default {
    name: 'JobMaterials',

    components: {
        DataTable,
        Dialog,
        JobShowPassword
    },

    data: () => ({
        headers: [
            { text: 'Id', value: 'id', width: '12%', class: 'pr-0' },
            { text: 'Nazwa', value: 'shortName', width: '35%' },
            { text: 'Status', value: 'state', width: '21%', class: 'pl-2 pr-0', cellClass: 'px-2' },
            { text: 'Typ', value: 'type', width: '20%' },
            { text: 'Akcje', value: 'actions', width: '12%', sortable: false }
        ],
        noDataText: 'Brak materiałów do wyświetlenia',
        showJobPass: null,
        clickedMaterialPasswords: null,
        stateTranslations: {
            not_found: 'nie znaleziono',
            found: 'znaleziono',
            in_progress: 'w toku',
            waiting: 'oczekiwanie',
            done: 'zakończono',
            aborting: 'przerywanie',
            aborted: 'przerwano'
        }
    }),

    computed: {
        selectedJob() {
            return this.$store.getters.selectedJob;
        },

        formattedJobsMaterials() {
            const formattedMaterials = JSON.parse(JSON.stringify(this.selectedJob.materials));

            const shortNameLength = 25;

            formattedMaterials.forEach((material) => {
                if (material['name'].length > shortNameLength) {
                    material['shortName'] = material['name'].substring(0, shortNameLength) + '...';
                } else {
                    material['shortName'] = material['name'];
                }

                material['state'] = material['state'] ? this.stateTranslations[material['state']] : '-';
            });

            return formattedMaterials;
        }
    },

    methods: {
        openDialogWithPassword(item) {
            const passwords = [];
            if (item.results) {
                item.results.forEach((password) => {
                    passwords.push(password.value);
                });
            }

            this.clickedMaterialPasswords = passwords.join([', ']);
            this.showJobPass = true;
        },

        closeDialog() {
            this.showJobPass = false;
        },

        cutPathToName(path) {
            return path.substring(path.lastIndexOf('/') + 1);
        }
    }
};
</script>
