import { authorizationService } from './authorization.service';
import store from '../store/index';
import { NOTIFICATIONS } from '../_helpers/consts';

export const globalService = {
    handleResponse
};

async function handleResponse(response) {
    const text = await response.text();
    const data = text && JSON.parse(text);

    if (!response.ok) {
        if (response.status === 401) {
            authorizationService.userLoggedOut();
        } else {
            const error = (data && data.message) || (data && data.code) || response.statusText || response.status || '';
            store.dispatch('addNotification', NOTIFICATIONS.alert(error));

            return Promise.resolve(error);
        }
    }

    return { data, status: response.status };
}
