<template>
    <div>
        <v-card-text class="pa-8">
            <v-form ref="form">
                <v-row>
                    <v-col cols="12">
                        <v-select
                            class="v-input v-input_required v-input_full-width"
                            v-model="selectedGroup"
                            outlined
                            :menu-props="{ nudgeBottom: 40, contentClass: 'v-select__options' }"
                            :color="CSS_COLOR.textHighlight"
                            :items="groups"
                            item-value="id"
                            item-text="name"
                            :loading="groupsLoading"
                            label="Wybierz grupę:"
                            :no-data-text="noDataText"
                            :error-messages="selectedGroupErrors"
                            @change="$v.selectedGroup.$touch()"
                            @blur="$v.selectedGroup.$touch()"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__secondary mr-4" @click="$emit('closeDialog')">Anuluj</v-btn>
                        <v-btn class="v-btn__primary" @click="sendRequestAndCloseDialog">Dodaj do grupy</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { groupsService } from '../../../services/groups.service';
import { CSS_COLOR } from '../../../_helpers/consts';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AddToGroupDialog',
    mixins: [validationMixin],

    props: {
        target: { type: String, required: true, validator: (v) => ['user', 'agent'].includes(v) }
    },

    validations: {
        selectedGroup: { required }
    },

    created() {
        this.getGroupsFromApi();
    },

    data() {
        return {
            CSS_COLOR,
            selectedGroup: null,
            formValidated: false,
            noDataText: 'Brak danych'
        };
    },

    computed: {
        selectedUser() {
            return this.$store.getters.selectedUser;
        },
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },
        assignedGroups() {
            if (this.target === 'user') return this.selectedUser.groups ?? [];
            else if (this.target === 'agent') return this.selectedAgent.groups ?? [];
            else return [];
        },
        groups() {
            return this.$store.getters.groups.filter((group) => !this.assignedGroups.map((g) => g.id).includes(group.id));
        },
        groupsLoading() {
            return this.$store.getters.isGroupsDataLoading;
        },
        selectedGroupErrors() {
            const errors = [];
            if (!this.$v.selectedGroup.$dirty) return errors;
            !this.$v.selectedGroup.required && errors.push('Grupa jest wymagana');
            return errors;
        }
    },

    methods: {
        async getGroupsFromApi() {
            this.$store.dispatch('getGroupsFromApi', { perPage: 256 });
        },

        async sendRequestAndCloseDialog() {
            if (!this.checkFormValidation()) return;

            let response;
            if (this.target === 'user') {
                response = await groupsService.addUserToGroup(this.selectedUser.id, this.selectedGroup);
            } else if (this.target === 'agent') {
                response = await groupsService.addAgentToGroup(this.selectedAgent.id, this.selectedGroup);
            }

            this.$emit('closeDialog');
            if (![200, 201].includes(response.status)) return;

            if (this.target === 'user') {
                this.fetchUserData();
            } else if (this.target === 'agent') {
                this.fetchAgentData();
            }
        },

        async fetchUserData() {
            this.$store.dispatch('setUserRefetch', this.selectedUser.id);
            await this.$store.dispatch('getUserById', this.selectedUser.id);
        },

        async fetchAgentData() {
            this.$store.dispatch('setAgentRefetch', this.selectedAgent.id);
            await this.$store.dispatch('getAgentById', this.selectedAgent.id);
        },

        checkFormValidation() {
            this.$v.$touch();
            this.formValidated = !this.$v.$anyError;

            if (!this.formValidated) {
                return false;
            }

            return true;
        }
    }
};
</script>
