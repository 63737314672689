<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <div>
            <v-row>
                <v-col cols="12">
                    <v-icon class="pr-2 v-icon_highlight"> mdi-file-outline </v-icon>
                    <span>{{ selectedAgent.name }}</span>
                </v-col>
            </v-row>
            <v-row class="text-left">
                <v-col cols="12">
                    <v-form class="mr-auto ml-auto ml-md-0">
                        <v-text-field v-model="selectedAgentName" outlined :color="CSS_COLOR.textHighlight" label="Nazwa:"> </v-text-field>

                        <v-textarea
                            v-model="selectedAgentDescription"
                            class="v-input_full-width"
                            label="Opis:"
                            height="95px"
                            outlined
                            :no-resize="true"
                        ></v-textarea>

                        <v-row class="justify-space-between">
                            <v-col cols="auto">
                                <v-btn
                                    v-if="selectedAgent.work_mode == workMode.normal"
                                    :loading="isWorkModeUpdating"
                                    class="v-btn__alert"
                                    @click="changeAgentWorkMode(workMode.sleep)"
                                    >Uśpij</v-btn
                                >
                                <v-btn
                                    v-if="selectedAgent.work_mode == workMode.sleep"
                                    :loading="isWorkModeUpdating"
                                    class="v-btn__success"
                                    @click="changeAgentWorkMode(workMode.normal)"
                                    >Przywróć</v-btn
                                >
                            </v-col>
                            <v-col cols="auto">
                                <v-btn :loading="isUpdating" class="v-btn__primary" @click="sendPatchRequest">Aktualizuj</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>

            <v-row class="text-left">
                <v-col cols="12">
                    <p class="mb-1 text_primary">Dane techniczne:</p>
                </v-col>
            </v-row>
            <v-divider class="v-divider_light mb-8"></v-divider>
            <v-card outlined color="transparent" class="text-left">
                <v-row>
                    <v-col cols="3">
                        <span>Identyfikator:</span>
                    </v-col>
                    <v-col grow>
                        <span class="text_primary">{{ selectedAgent.identifier }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <span>Data dodania:</span>
                    </v-col>
                    <v-col grow>
                        <span class="text_primary">{{ formatDateFrom1970(selectedAgent.created_at) }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <span>Wersja:</span>
                    </v-col>
                    <v-col grow>
                        <span class="text_primary">{{ selectedAgent.version }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <span>IP:</span>
                    </v-col>
                    <v-col grow>
                        <span class="text_primary">{{ selectedAgent.ip }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <span>Tryb:</span>
                    </v-col>
                    <v-col grow>
                        <span class="text_primary">{{ selectedAgent.work_mode }}</span>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-card>
</template>

<script>
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { agentsService } from '../../../services/agents.service';

export default {
    name: 'AgentDetails',

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,
        name: null,
        description: null,

        workMode: {
            normal: 'normal',
            sleep: 'sleep'
        },

        isUpdating: false,
        isWorkModeUpdating: false
    }),

    computed: {
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },

        selectedAgentName: {
            get() {
                return this.name === null ? this.selectedAgent.name : this.name;
            },
            set(value) {
                this.name = value;
            }
        },

        selectedAgentDescription: {
            get() {
                return this.description === null ? this.selectedAgent.description : this.description;
            },
            set(value) {
                this.description = value;
            }
        }
    },

    watch: {
        selectedAgent() {
            this.description = null;
            this.name = null;
        }
    },

    methods: {
        async sendPatchRequest() {
            this.isUpdating = true;

            const response = await agentsService.updateAgent({
                name: this.selectedAgentName,
                description: this.selectedAgentDescription,
                id: this.selectedAgent.id
            });

            if (response.status == 200) {
                await this.getUpdatedAgentsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isUpdating = false;
        },

        async changeAgentWorkMode(workMode) {
            this.isWorkModeUpdating = true;

            const response = await agentsService.updateAgentWorkMode(this.selectedAgent.id, workMode);

            if (response.status == 200) {
                await this.getUpdatedAgentsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.refreshSelectedAgent();
            this.isWorkModeUpdating = false;
        },

        async getUpdatedAgentsData() {
            await this.$store.dispatch('getAgentsFromApi');
        },

        formatDateFrom1970(date) {
            return date ? new Date(date).toLocaleDateString('en-UK') : '';
        },

        async refreshSelectedAgent() {
            this.$store.dispatch('setAgentRefetch');
            await this.$store.dispatch('getAgentById', this.selectedAgent.id);
        }
    }
};
</script>
