export const config = {
    // apiUrl: 'http://188.64.85.69:9090', //prop
    // apiUrl: 'http://192.168.1.98:9090', //dev
    apiUrl:
        window.location.hostname == 'cybercrypt.redgelabs.com'
            ? `https://${window.location.hostname}`
            : `http://${window.location.hostname}:9090`,

    jobsUrl: 'api/requests',
    jobsEstimateUrl: 'api/requests/estimate',

    attachmentsUrl: 'api/attachments',
    attachmentsUploadUrl: 'api/attachments',
    attachmentRequestsUrl: 'api/requests?attachment=',
    attachmentCreateUploadSessionUrl: 'api/file/upload/session/create',

    algorithmsUrl: 'api/dicts/algorithms',

    loginUrl: 'api/auth/login',
    logoutUrl: 'api/auth/logout',
    usersUrl: 'api/operators',
    currentUserUrl: 'api/auth/profile',
    loggedUserUrl: 'api/auth/profile',
    currentUserPasswordChangeUrl: 'api/auth/profile/password',

    managerVersionUrl: 'api/managers/version',
    grafanaHomeDashboardUrl: 'https://cybercrypt-monitoring.redgelabs.com/api/dashboards/home',

    jobAbortUrl: (id) => `api/requests/${id}/abort`,

    userPasswordChangeUrl: (id) => `api/operators/${id}/password`,
    userRoleChangeUrl: (id) => `api/operators/${id}/roles`,

    groupsUrl: 'api/groups',

    agentsUrl: 'api/agents',
    agentChangeWorkModeUrl: (id) => `api/agents/${id}/work-mode`,
    agentLastCsrUrl: (id) => `api/agents/${id}/csrs/last`,
    csrsUrl: 'api/csrs',
    csrDownloadUrl: (id) => `api/csrs/${id}/raw`,

    attachmentDownloadUrl: (id) => `api/attachments/${id}/raw`,
    attachmentReportUrl: (id) => `api/attachments/${id}/publication-state/report`,
    attachmentPublicUrl: (id) => `api/attachments/${id}/publication-state/accept`,
    attachmentRejectUrl: (id) => `api/attachments/${id}/publication-state/reject`,
    attachmentChunkUploadUrl: (sessionId) => `api/file/upload/session/${sessionId}/send`,
    attachmentUploadSessionCommitUrl: (sessionId) => `api/file/upload/session/${sessionId}/commit`,
    attachmentUploadSessionCancelUrl: (sessionId) => `api/file/upload/session/${sessionId}/cancel`,
    attachmentUploadSessionDestroyUrl: (sessionId) => `api/file/upload/session/${sessionId}/uploaded/destroy`
};

export const defaultRequestOptions = {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
};
