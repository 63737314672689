<template>
    <v-container fluid>
        <v-row justify="space-between">
            <v-col cols="auto">
                <Hidden-section-title
                    :titleText="isAgentVerified ? selectedAgent.name : agentNotVerifiedText"
                    :titleCase="titleCase"
                ></Hidden-section-title>
            </v-col>
            <v-col cols="auto">
                <v-btn small icon @click="closeCol">
                    <v-icon :color="CSS_COLOR.textPrimary" small> mdi-close </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-progress-linear v-if="isRefetching" indeterminate height="1" color="primary" class="mt-4"></v-progress-linear>
        <v-divider v-else class="mt-4 v-divider_light"></v-divider>
        <v-row v-if="isNewDataLoading" class="py-10">
            <v-progress-circular class="mt-12 mx-auto" color="primary" size="60" width="2" indeterminate></v-progress-circular>
        </v-row>
        <v-row v-else-if="!isNewDataLoading && selectedAgent">
            <v-col cols="12">
                <v-card v-if="isAgentVerified" color="transparent" elevation="0">
                    <v-tabs v-model="selectedTab" background-color="transparent" grow>
                        <v-tab v-for="item in items" :key="item">
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items :value="selectedTab" class="mt-8 transparent">
                        <v-tab-item>
                            <Agent-details></Agent-details>
                        </v-tab-item>
                        <v-tab-item>
                            <Agent-resources></Agent-resources>
                        </v-tab-item>
                        <v-tab-item>
                            <Agent-groups></Agent-groups>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
                <v-card v-else color="transparent" elevation="0">
                    <Agent-not-verified />
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else justify="center" class="mt-10">
            <v-col cols="auto">
                <v-alert type="error">Błąd wczytywania danych agenta</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { CSS_COLOR } from '../../../_helpers/consts';
import AgentDetails from './AgentDetails.vue';
import AgentResources from './AgentResources.vue';
import AgentGroups from './AgentGroups.vue';
import AgentNotVerified from './AgentNotVerified.vue';
import HiddenSectionTitle from '../../global/HiddenSectionTitle.vue';

export default {
    components: {
        AgentDetails,
        AgentResources,
        AgentGroups,
        AgentNotVerified,
        HiddenSectionTitle
    },
    name: 'AgentsHiddenSection',
    props: {
        preSelectedTab: {
            type: Number,
            require: false,
            default: 0
        }
    },

    data: () => ({
        CSS_COLOR,
        selectedTab: 0,
        items: ['Szczegóły', 'Zasoby obliczeniowe', 'Grupy'],
        agentNotVerifiedText: 'Agent niezweryfikowany'
    }),

    computed: {
        titleCase() {
            if (this.isNewDataLoading) {
                return 'loading';
            } else if (this.selectedAgent && !this.isNewDataLoading) {
                return 'title';
            } else {
                return 'error';
            }
        },
        isNewDataLoading() {
            return this.$store.getters.isAgentNewDataLoading;
        },
        isRefetching() {
            return this.$store.getters.isAgentRefetching;
        },
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },
        isAgentVerified() {
            return this.$store.getters.isAgentVerified;
        }
    },

    watch: {
        preSelectedTab(value) {
            this.selectedTab = value;
        }
    },

    methods: {
        closeCol() {
            this.$store.dispatch('closeDetails');
        }
    }
};
</script>
