<template>
    <v-container fill-height>
        <v-row>
            <v-col class="align-self-center" cols="12" xs="12" md="6">
                <Login-Description></Login-Description>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" xs="12" md="6">
                <Login-Form></Login-Form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LoginDescription from './subcomponents/LoginDescription.vue';
import LoginForm from './subcomponents/LoginForm.vue';

export default {
    name: 'LoginIndex',

    components: {
        LoginDescription,
        LoginForm
    }
};
</script>
