import { replaceObjectFields } from '../../_helpers/general';
import { groupsService } from '../../services/groups.service';

const state = () => ({
    groups: [],
    groupsData: {},
    groupsSortBy: '',
    groupsSortOrder: '',
    groupsSearch: '',
    isGroupsDataLoading: false,
    groupsLastUpdateTime: '',

    currentGroup: {},

    preselectedGroupId: null,
    selectedGroupForDetails: null,
    isGroupNewDataLoading: false,
    isGroupRefetching: false,

    groupSchema: {
        id: null,
        name: '',
        description: ''
    }
});

const getters = {
    groups: (state) => {
        return state.groups;
    },

    groupsData: (state) => {
        return state.groupsData;
    },

    groupsSortBy: (state) => {
        return state.groupsSortBy;
    },

    groupsSortOrder: (state) => {
        return state.groupsSortOrder;
    },

    groupsSearch: (state) => {
        return state.groupsSearch;
    },

    isGroupsDataLoading: (state) => {
        return state.isGroupsDataLoading;
    },

    isGroupNewDataLoading: (state) => {
        return state.isGroupNewDataLoading;
    },

    isGroupRefetching: (state) => {
        return state.isGroupRefetching;
    },

    groupsLastUpdateTime: (state) => {
        return state.groupsLastUpdateTime;
    },

    selectedGroup: (state) => {
        return state.selectedGroupForDetails;
    },

    preselectedGroupId: (state) => {
        return state.preselectedGroupId;
    }
};

const actions = {
    async getGroupsFromApi({ commit, dispatch, state, rootState }, { perPage } = {}) {
        const per_page = perPage ?? rootState.global.itemsPerPage;
        const page = state.groupsData.page;
        const sort = state.groupsSortBy;
        const order = state.groupsSortOrder;
        const search = state.groupsSearch;

        commit('isGroupsDataLoadingChange');

        const response = await groupsService.getGroups({ per_page, page, sort, order, search });

        if (response.status !== 200) {
            commit('isGroupsDataLoadingChange');
            return;
        }

        const { content, ...data } = response.data;

        commit('groupsData', data);
        commit('groupsItems', content);

        dispatch('autoRefreshResetTimeout');
        commit('isGroupsDataLoadingChange');
    },

    async getGroupById({ commit, state }, groupId) {
        if (state.isGroupNewDataLoading) return;

        if (!state.isGroupRefetching) {
            commit('setGroupNewDataLoading', true);
        }

        const [groupResponse, groupUsersResponse, groupAgentsResponse] = await Promise.all([
            groupsService.getGroupById(groupId),
            groupsService.getGroupUsers(groupId),
            groupsService.getGroupAgents(groupId)
        ]);

        if (groupResponse.status !== 200) {
            commit('resetGroupForDetails');
            commit('setGroupNewDataLoading', false);
            commit('setGroupRefetching', false);
            return;
        }

        const groupData = {
            ...state.groupSchema,
            ...groupResponse.data,
            users: groupUsersResponse.data?.content ?? [],
            agents: groupAgentsResponse.data?.content ?? []
        };

        if (state.isGroupRefetching) {
            commit('setGroupForDetailsSilently', groupData);
        } else {
            commit('setGroupForDetails', groupData);
        }
        commit('setGroupRefetching', false);
        commit('setGroupNewDataLoading', false);
    },

    resetGroupsData({ commit }) {
        commit('resetGroupsData');
    },

    setGroupsPage({ commit }, page) {
        commit('setGroupsPage', page);
    },

    setGroupsSortBy({ commit }, fieldName) {
        commit('setGroupsSortBy', fieldName);
    },

    setGroupsSortOrder({ commit }, order) {
        commit('setGroupsSortOrder', order);
    },

    setGroupsSearch({ commit }, text) {
        commit('setGroupsSearch', text);
    },

    setGroupsLastUpdateTime({ commit }) {
        const dataTime = new Date().toLocaleString();
        commit('setGroupsLastUpdateTime', dataTime);
    },

    setGroupRefetch({ commit }) {
        commit('setGroupRefetching', true);
    },

    setPreselectedGroupId({ commit }, groupId) {
        commit('setPreselectedGroupId', groupId);
    }
};

const mutations = {
    groupsItems(state, payload) {
        state.groups = payload;
    },

    groupsData(state, payload) {
        state.groupsData = payload;
    },

    resetGroupsData(state) {
        state.groupsData = {};
        state.groups = [];
    },

    setGroupsLastUpdateTime(state, payload) {
        state.groupsLastUpdateTime = payload;
    },

    setGroupsPage(state, payload) {
        state.groupsData.page = payload;
    },

    setGroupsSortBy(state, payload) {
        state.groupsSortBy = payload;
    },

    setGroupsSortOrder(state, payload) {
        state.groupsSortOrder = payload;
    },

    setGroupsSearch(state, payload) {
        state.groupsSearch = payload;
    },

    currentGroupData(state, payload) {
        state.currentGroup = payload;
    },

    resetGroupForDetails(state) {
        state.selectedGroupForDetails = null;
    },

    setGroupForDetails(state, group) {
        state.selectedGroupForDetails = group;
    },

    setGroupForDetailsSilently(state, group) {
        replaceObjectFields(state.selectedGroupForDetails, group);
    },

    isGroupsDataLoadingChange(state) {
        if (state.isGroupsDataLoading == true) {
            setTimeout(function () {
                state.isGroupsDataLoading = false;
            }, 750);
        } else {
            state.isGroupsDataLoading = true;
        }
    },

    setGroupNewDataLoading(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isGroupNewDataLoading = false;
            }, 250);
        } else {
            state.isGroupNewDataLoading = true;
        }
    },

    setGroupRefetching(state, payload) {
        if (payload == false) {
            setTimeout(function () {
                state.isGroupRefetching = false;
            }, 250);
        } else {
            state.isGroupRefetching = true;
        }
    },

    setPreselectedGroupId(state, groupId) {
        state.preselectedGroupId = groupId;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
