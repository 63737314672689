<template>
    <div>
        <v-footer app>
            <v-container fluid>
                <v-divider></v-divider>
                <v-row>
                    <v-col>
                        <p class="body-1 text_primary mb-0">Cybercrypt@gov</p>
                        <p class="body-1 text_primary mb-0">Numer wniosku: DOB-BIO9/32/03/2018</p>
                    </v-col>
                    <v-col>
                        <p class="body-1 text_primary text-right">Projekt realizowany ze środków Narodowego Centrum Badań i Rozwoju</p>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </div>
</template>

<script>
export default {
    name: 'MainFooter'
};
</script>
