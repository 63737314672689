import { debounce } from '../_helpers/general';

export default {
    watch: {
        page(newVal, oldVal) {
            if (newVal === oldVal) return;
            this.onPageChange(newVal);
        },
        search: debounce(function (newVal, oldVal) {
            if (newVal === oldVal) return;
            this.onSearchInput(newVal);
        }),
        options: {
            deep: true,
            handler(newOpts, oldOpts) {
                const sortOptions = {
                    oldSortBy: oldOpts.sortBy.length ? oldOpts.sortBy[0] : null,
                    newSortBy: newOpts.sortBy.length ? newOpts.sortBy[0] : null,
                    sortByChanged: false,
                    oldSortDesc: oldOpts.sortDesc.length ? oldOpts.sortDesc[0] : null,
                    newSortDesc: newOpts.sortDesc.length ? newOpts.sortDesc[0] : null,
                    sortDescChanged: false
                };

                if (sortOptions.oldSortBy !== sortOptions.newSortBy) {
                    sortOptions.sortByChanged = true;
                }
                if (sortOptions.oldSortDesc !== sortOptions.newSortDesc) {
                    sortOptions.sortDescChanged = true;
                }

                if (!sortOptions.sortByChanged && !sortOptions.sortDescChanged) return;

                this.onSortChange(sortOptions.newSortBy, sortOptions.newSortDesc);
            }
        }
    }
};
