<template>
    <div>
        <v-row class="text-left">
            <v-col cols="12">
                <v-form class="mr-auto ml-auto ml-md-0">
                    <v-row class="controls-row-container">
                        <v-col cols="auto">
                            <v-text-field
                                class="v-input_half-width"
                                v-model="selectedUserForename"
                                outlined
                                :color="CSS_COLOR.textHighlight"
                                label="Imię:"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field
                                class="v-input_half-width"
                                v-model="selectedUserSurname"
                                outlined
                                :color="CSS_COLOR.textHighlight"
                                label="Nazwisko:"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-text-field
                        disabled
                        class="v-input v-input_required"
                        v-model="selectedUserLogin"
                        outlined
                        :color="CSS_COLOR.textHighlight"
                        label="Login:"
                    ></v-text-field>

                    <v-text-field
                        class="v-input v-input_required"
                        v-model="selectedUserEmail"
                        outlined
                        :color="CSS_COLOR.textHighlight"
                        label="E-mail:"
                        @input="$v.selectedUserEmail.$touch()"
                        @blur="$v.selectedUserEmail.$touch()"
                        :error-messages="emailErrors"
                    ></v-text-field>

                    <v-text-field
                        class="v-input"
                        v-model="selectedUserDepartment"
                        outlined
                        :color="CSS_COLOR.textHighlight"
                        label="Departament:"
                    ></v-text-field>

                    <v-row class="justify-end">
                        <v-col cols="auto">
                            <div>
                                <v-btn :loading="isUpdating" class="v-btn__primary" @click="updateUserData">Aktualizuj</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
        <v-divider class="v-divider_light mt-8 mb-8"></v-divider>
        <v-row>
            <v-col cols="auto" class="pr-0">
                <v-btn class="v-btn__secondary" @click="(showPasswordChangeDialog = true), forceRerender()">
                    <v-icon class="mb-1 mr-2"> mdi-key </v-icon>
                    <span>Zmień hasło</span>
                </v-btn>
            </v-col>
            <v-col v-if="isAdminLogged" cols="auto" class="pr-0">
                <v-btn class="v-btn__secondary" @click="(showRolesChangeDialog = true), forceRerender()">
                    <v-icon class="mr-2"> mdi-account-switch </v-icon>
                    <span>Zmień uprawnienia</span>
                </v-btn>
            </v-col>
            <v-col v-if="isAdminLogged" cols="grow" class="text-right">
                <v-btn :disabled="currentUser.id == selectedUser.id" :loading="isDeleting" class="v-btn__alert" @click="deleteUser">Usuń</v-btn>
            </v-col>
        </v-row>

        <v-divider class="v-divider_light mt-8 mb-8"></v-divider>
        <v-row class="justify-start">
            <v-col cols="auto"> </v-col>
        </v-row>

        <Dialog
            :dialogWidth="'350px'"
            :title="'Hasło'"
            :forceActivation="showPasswordChangeDialog"
            @dialogClosed="showPasswordChangeDialog = false"
        >
            <template v-slot:content="{ deactivate }">
                <User-change-password-dialog :key="componentKey" :title="'Zmień hasło'" @deactivate="deactivate()"></User-change-password-dialog>
            </template>
        </Dialog>

        <Dialog
            :dialogWidth="'350px'"
            :title="'Uprawnienia użytkownika'"
            :forceActivation="showRolesChangeDialog"
            @dialogClosed="showRolesChangeDialog = false"
        >
            <template v-slot:content="{ deactivate }">
                <User-change-roles-dialog :key="componentKey" :title="'Zmień uprawnienia'" @deactivate="deactivate()"></User-change-roles-dialog>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { usersService } from '../../../services/users.service';
import Dialog from '../../global/Dialog.vue';
import UserChangePasswordDialog from './UserChangePasswordDialog.vue';
import UserChangeRolesDialog from './UserChangeRolesDialog.vue';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
    components: { Dialog, UserChangePasswordDialog, UserChangeRolesDialog },
    name: 'userDetails',

    mixins: [validationMixin],

    validations: {
        selectedUserEmail: { required, email }
    },

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,
        componentKey: 0,
        userDetails: {
            forename: null,
            surname: null,
            email: null,
            department: null
        },

        isUpdating: false,
        isDeleting: false,
        showPasswordChangeDialog: false,
        showRolesChangeDialog: false,

        formValidated: false
    }),

    computed: {
        selectedUser() {
            return this.$store.getters.selectedUser;
        },

        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        currentUser() {
            return this.$store.getters.loggedUserDetails;
        },

        selectedUserForename: {
            get() {
                return this.userDetails.forename === null ? this.selectedUser.forename : this.userDetails.forename;
            },
            set(value) {
                this.userDetails.forename = value;
            }
        },

        selectedUserSurname: {
            get() {
                return this.userDetails.surname === null ? this.selectedUser.surname : this.userDetails.surname;
            },
            set(value) {
                this.userDetails.surname = value;
            }
        },

        selectedUserLogin: {
            get() {
                return this.selectedUser.login;
            }
        },

        selectedUserEmail: {
            get() {
                return this.userDetails.email === null ? this.selectedUser.email : this.userDetails.email;
            },
            set(value) {
                this.userDetails.email = value;
            }
        },

        selectedUserDepartment: {
            get() {
                return this.userDetails.department === null ? this.selectedUser.department : this.userDetails.department;
            },
            set(value) {
                this.userDetails.department = value;
            }
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.selectedUserEmail.$dirty) return errors;
            !this.$v.selectedUserEmail.required && errors.push('Adres email jest wymagany');
            !this.$v.selectedUserEmail.email && errors.push('Wprowadź poprawny adres email');
            return errors;
        }
    },

    methods: {
        async sendPatchRequest() {
            if (!this.checkFormValidation()) {
                return;
            }
            this.isUpdating = true;

            const response = await usersService.updateUser({
                id: this.selectedUser.id,
                forename: this.selectedUserForename,
                surname: this.selectedUserSurname,
                login: this.selectedUserLogin,
                email: this.selectedUserEmail,
                department: this.selectedUserDepartment
            });

            if (response.status == 200) {
                await this.getUpdatedUsersData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isUpdating = false;
        },

        async getUpdatedUsersData() {
            await this.$store.dispatch('getUsersFromApi');
        },

        async sendDeleteRequest() {
            this.isDeleting = true;

            const response = await usersService.deleteUser(this.selectedUser.id);

            if (response.status == 204) {
                await this.getUpdatedUsersData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isDeleting = false;
            this.closeCol();
        },

        updateUserData() {
            this.sendPatchRequest();
        },

        deleteUser() {
            this.sendDeleteRequest();
        },

        forceRerender() {
            this.componentKey += 1;
        },

        closeCol() {
            this.$store.dispatch('closeDetails');
        },

        checkFormValidation() {
            this.$v.$touch();

            this.formValidated = !this.$v.$anyError;

            if (!this.formValidated) {
                return false;
            }

            return true;
        }
    },

    watch: {
        selectedUser() {
            this.userDetails = {
                forename: null,
                surname: null,
                email: null,
                department: null
            };
        }
    },

    mounted() {
        //this.getSelectedUserData();
    }
};
</script>
