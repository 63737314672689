import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';

export const managerService = {
    getManagerVersion
};

async function getManagerVersion() {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.managerVersionUrl}`, requestOptions);
    const version = await globalService.handleResponse(response);

    return version;
}
