<template>
    <v-container fluid>
        <v-row>
            <v-col cols="auto">
                <h2 class="text_primary sub-header">Użytkownicy</h2>
            </v-col>
            <v-col cols="auto" v-if="isAdminLogged">
                <v-btn color="primary" dark @click="openNewUserDialog"> + Nowy </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-4"></v-divider>
        <div class="pl-6 pr-6 mb-14">
            <v-row justify="space-between">
                <v-col cols="auto">
                    <h3 class="sub-title">Zarejestrowani użytkownicy</h3>
                </v-col>
                <v-col cols="auto">
                    <Search-field v-model.lazy="search" />
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4 pl-6 pr-6"></v-divider>
            <v-row>
                <v-col cols="12">
                    <Data-table
                        :must-sort="true"
                        :disable-sort="isLoading"
                        :headers="headers"
                        :tableData="formattedUsers"
                        :loading="isLoading"
                        :itemKey="'id'"
                        :noDataText="dataEmptyText"
                        :customTemplatesItemsNames="[`item.actions`]"
                        :options.sync="usersDataOptions"
                        :serverItemsLength="usersData.elements"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="text-end">
                                <v-btn icon @click="showMoreUserDetails(item)">
                                    <v-icon> mdi-chevron-right </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </Data-table>
                </v-col>
            </v-row>
            <v-row class="paginator">
                <v-col cols="12">
                    <div class="text-center pt-2">
                        <v-pagination :total-visible="5" :disabled="isLoading" v-model="selectedPage" :length="usersData.pages"></v-pagination>
                    </div>
                </v-col>
            </v-row>
        </div>

        <Dialog :title="'Nowy użytkownik'" :forceActivation="showNewUserDialog" dialog-width="600px" @dialogClosed="showNewUserDialog = false">
            <template v-slot:content="{ deactivate }">
                <User-add-new-dialog @closeDialog="deactivate()" :key="componentKey"></User-add-new-dialog>
            </template>
        </Dialog>
    </v-container>
</template>

<script>
import SearchField from '../../global/SearchField.vue';
import DataTable from '../../global/DataTable.vue';
import Dialog from '../../global/Dialog.vue';
import UserAddNewDialog from '../subcomponents/UserAddNewDialog.vue';

import DataTableOptionsMixin from '../../../mixins/DataTableOptionsMixin';

export default {
    components: { SearchField, DataTable, Dialog, UserAddNewDialog },
    mixins: [DataTableOptionsMixin],
    name: 'UsersMainSection',

    data: () => ({
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Login', value: 'login' },
            { text: 'Imię', value: 'forename' },
            { text: 'Nazwisko', value: 'surname' },
            { text: 'E-mail', value: 'email' },
            { text: 'Uprawnienia', value: 'role', sortable: false },
            { text: 'Data ost. logowania', value: 'last_logged_at' },

            { text: 'Akcje', value: 'actions', sortable: false }
        ],
        noDataText: 'Brak użytkowników do wyświetlenia',
        noResultsText: 'Nie znaleziono użytkowników',
        dataEmptyText: '',
        showNewUserDialog: false,
        componentKey: 0,

        page: null,
        search: '',
        options: {
            sortBy: ['id'],
            sortDesc: [false]
        }
    }),

    computed: {
        selectedPage: {
            get() {
                return this.usersData?.page ? this.usersData.page : 1;
            },
            set(value) {
                this.page = value;
            }
        },

        usersDataOptions: {
            get() {
                return {
                    sortBy: this.usersSortBy ? [this.usersSortBy] : this.options.sortBy,
                    sortDesc: this.usersSortOrder === 'desc' ? [true] : this.options.sortDesc
                };
            },
            set(value) {
                this.options = value;
            }
        },

        storedUsers() {
            return this.$store.getters.users;
        },

        usersData() {
            return this.$store.getters.usersData;
        },

        usersSortBy() {
            return this.$store.getters.usersSortBy;
        },

        usersSortOrder() {
            return this.$store.getters.usersSortOrder;
        },

        isLoading() {
            return this.$store.getters.isUsersDataLoading;
        },

        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        formattedUsers() {
            const formattedUsers = JSON.parse(JSON.stringify(this.storedUsers));

            formattedUsers.forEach((user) => {
                user.role = this.specifyUserMainRole(user);
                user.last_logged_at = this.formatDateToLocalString(user.last_logged_at);
            });

            return formattedUsers;
        },

        preselectedUserId() {
            return this.$store.getters.preselectedUserId;
        }
    },

    methods: {
        showMoreUserDetails(item) {
            const selectedUserId = item.id;

            this.$store.dispatch('openDetails');
            this.$store.dispatch('getUserById', selectedUserId);
        },

        specifyUserMainRole(user) {
            const roles = [];
            user.roles.forEach((role) => {
                roles.push(role.name);
            });

            let role;

            switch (true) {
                case roles.includes('ADMIN'):
                    role = 'admin';
                    break;
                case roles.includes('USER'):
                    role = 'user';
                    break;
                default:
                    role = 'unknown';
                // code block
            }

            return role;
        },

        formatDateToLocalString(date) {
            return date ? new Date(date).toLocaleString('en-UK') : '';
        },

        forceRerender() {
            this.componentKey += 1;
        },

        openNewUserDialog() {
            this.forceRerender();
            this.showNewUserDialog = true;
        },

        openPreselectedUserForDetails() {
            setTimeout(async () => {
                if (!this.preselectedUserId) return;
                this.$store.dispatch('openDetails');
                await this.$store.dispatch('getUserById', this.preselectedUserId);
                this.$store.dispatch('setPreselectedUserId', null);
            }, 300);
        },

        onPageChange(page) {
            this.$store.dispatch('setUsersPage', page);
            this.$store.dispatch('getUsersFromApi');
        },
        onSortChange(sortBy, isSortDesc) {
            this.$store.dispatch('setUsersSortBy', sortBy ? sortBy : '');
            this.$store.dispatch('setUsersSortOrder', isSortDesc ? 'desc' : '');
            this.$store.dispatch('getUsersFromApi');
        },
        onSearchInput(text) {
            this.$store.dispatch('setUsersPage', 1);
            this.$store.dispatch('setUsersSearch', text);
            this.$store.dispatch('getUsersFromApi');
        }
    },

    created() {
        this.dataEmptyText = this.noDataText;
    },

    mounted() {
        this.openPreselectedUserForDetails();
    },

    beforeDestroy() {
        this.$store.dispatch('setUsersSearch', '');
    },

    watch: {
        storedUsers(newVal) {
            if (this.search && !newVal?.length) {
                this.dataEmptyText = this.noResultsText;
            } else {
                this.dataEmptyText = this.noDataText;
            }
        }
    }
};
</script>
