<template>
    <Main-section :hideSecondaryCol="true">
        <template v-slot:left-col="{}">
            <Profile-main-section></Profile-main-section>
        </template>
    </Main-section>
</template>

<script>
import MainSection from '../global/MainSection.vue';
import ProfileMainSection from './subcomponents/ProfileMainSection.vue';
export default {
    name: 'ProfileIndex',

    components: {
        MainSection,
        ProfileMainSection
    },

    data: () => ({}),

    methods: {
        async getCurrentUserData() {
            await this.$store.dispatch('getCurrentUserFromApi');
        }
    },

    async mounted() {
        this.getCurrentUserData();
    }
};
</script>
