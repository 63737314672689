import { config, defaultRequestOptions } from './config';
import { globalService } from './global.service';
import { generateQueryString } from '../_helpers/general';

export const jobsService = {
    getJobs,
    getJobById,
    postNewJob,
    updateJob,
    estimateNewJob,
    abortJob
};

async function getJobs(queryObj) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const queryString = generateQueryString(queryObj);

    const response = await fetch(`${config.apiUrl}/${config.jobsUrl}${queryString}`, requestOptions);
    const jobs = await globalService.handleResponse(response);

    return jobs;
}

async function getJobById(id) {
    const requestOptions = {
        method: 'GET',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.jobsUrl}/${id}`, requestOptions);
    const job = await globalService.handleResponse(response);

    return job;
}

async function postNewJob(newJob) {
    let jsonDate;

    try {
        jsonDate = JSON.stringify(newJob);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.jobsUrl}`, requestOptions);
    const addedJob = await globalService.handleResponse(response);

    return addedJob;
}

async function estimateNewJob(newJob) {
    let jsonDate;

    try {
        jsonDate = JSON.stringify(newJob);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.jobsEstimateUrl}`, requestOptions);
    const estimateTime = await globalService.handleResponse(response);

    return estimateTime;
}

async function updateJob(job) {
    const updatedData = {
        name: job.name,
        description: job.description
    };

    let jsonDate;

    try {
        jsonDate = JSON.stringify(updatedData);
    } catch (e) {
        console.log(e);
    }

    const requestOptions = {
        method: 'PATCH',
        ...defaultRequestOptions,
        body: jsonDate
    };

    const response = await fetch(`${config.apiUrl}/${config.jobsUrl}/${job.id}`, requestOptions);
    const updatedJob = await globalService.handleResponse(response);

    return updatedJob;
}

async function abortJob(id) {
    const requestOptions = {
        method: 'POST',
        ...defaultRequestOptions
    };

    const response = await fetch(`${config.apiUrl}/${config.jobAbortUrl(id)}`, requestOptions);
    const updatedJob = await globalService.handleResponse(response);

    return updatedJob;
}
