<template>
    <v-card outlined min-height="100%" color="transparent" class="text-left">
        <div>
            <v-row>
                <v-col cols="12">
                    <v-icon class="pr-2 v-icon_highlight"> mdi-file-outline </v-icon>
                    <span>{{ selectedJob.name }}</span>
                </v-col>
            </v-row>
            <v-row class="text-left">
                <v-col cols="12">
                    <v-form class="mr-auto ml-auto ml-md-0">
                        <v-text-field
                            v-model="selectedJobName"
                            outlined
                            class="v-input_required"
                            label="Nazwa:"
                            :color="CSS_COLOR.textHighlight"
                            :error-messages="nameErrors"
                            @input="$v.selectedJobName.$touch()"
                            @blur="$v.selectedJobName.$touch()"
                        >
                        </v-text-field>

                        <v-row class="controls-row-container">
                            <v-col cols="auto">
                                <v-text-field
                                    class="v-input_half-width"
                                    disabled
                                    v-model="selectedJobAttackType"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Rodzaj ataku:"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <v-text-field
                                    disabled
                                    class="v-input_half-width"
                                    v-model="selectedJobPriority"
                                    outlined
                                    :color="CSS_COLOR.textHighlight"
                                    label="Priorytet:"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-text-field
                            disabled
                            v-model="selectedJobAttachments"
                            class="v-input_full-width"
                            :label="selectedJobAttachmentsLabel"
                            outlined
                        ></v-text-field>

                        <v-textarea
                            v-model="selectedJobDescription"
                            class="v-input_full-width"
                            label="Opis:"
                            height="95px"
                            outlined
                            :no-resize="true"
                        ></v-textarea>

                        <v-row class="justify-space-between">
                            <v-col cols="auto">
                                <v-btn
                                    v-if="selectedJob.state == 'processing' || selectedJob.state == 'new'"
                                    :loading="isAborting"
                                    class="v-btn__alert"
                                    @click="showAbortDialog = true"
                                    >Przerwij
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn :loading="isUpdating" class="v-btn__primary" @click="sendPatchRequest">Aktualizuj </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>

            <v-row class="text-left">
                <v-col cols="12">
                    <p class="mb-1 text_primary">Dane techniczne:</p>
                </v-col>
            </v-row>
            <v-divider class="v-divider_light mb-6"></v-divider>
            <v-card outlined color="transparent" class="text-left">
                <v-row justify="space-between">
                    <v-col cols="12">
                        <span>Właściciel: </span>
                        <template v-if="isSelectedJobUserAvailable">
                            <span class="text_primary">{{ selectedJobUser.login }} </span>
                            <v-btn v-if="isAdminLogged" icon @click="openUserDetails(selectedJobUser)">
                                <v-icon :color="CSS_COLOR.textPrimary">mdi-open-in-new</v-icon>
                            </v-btn>
                        </template>
                        <span v-else class="text_primary font-italic">brak użytkownika</span>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <Confirmation-dialog
            :cancelButtonText="'Anuluj'"
            :confirmationButtonText="'Przerwij'"
            :isQuestionHtmlContent="true"
            :questionContent="`<h2 class='text_primary text-center mb-2'>Czy na pewno chcesz przerwać wybrane zlecenie ?</h2>`"
            :dialogWidth="'30%'"
            :title="'Przerywanie zlecenia'"
            :confirmationButtonClass="'v-btn__alert'"
            v-model="showAbortDialog"
            @confirmEvent="sendAbortRequest"
        >
        </Confirmation-dialog>
    </v-card>
</template>

<script>
import { CSS_COLOR, NOTIFICATIONS } from '../../../_helpers/consts';
import { jobsService } from '../../../services/jobs.service';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ConfirmationDialog from '../../global/ConfirmationDialog.vue';

export default {
    name: 'JobDetails',

    components: {
        ConfirmationDialog
    },

    mixins: [validationMixin],

    validations: {
        selectedJobName: { required }
    },

    data: () => ({
        CSS_COLOR,
        NOTIFICATIONS,
        name: null,
        description: null,
        formattedJobAttack: {
            mask: 'maskowy',
            dictionary: 'słownikowy'
        },
        formattedJobPriority: {
            default: 'domyślny',
            high: 'wysoki'
        },
        jobAttachmentsLabel: {
            mask: 'Pliki maskowe:',
            dictionary: 'Typ słownika'
        },
        isUpdating: false,
        isAborting: false,
        showAbortDialog: false
    }),

    computed: {
        isAdminLogged() {
            return this.$store.getters.isAdminLogged;
        },

        selectedJob() {
            return this.$store.getters.selectedJob;
        },

        selectedJobName: {
            get() {
                return this.name === null ? this.selectedJob.name : this.name;
            },
            set(value) {
                this.name = value;
            }
        },

        selectedJobAttachments: {
            get() {
                const attachmentsName = [];
                this.selectedJob?.attachments?.forEach((attachment) => {
                    attachmentsName.push(attachment.name);
                });
                return attachmentsName.join(', ');
            }
        },

        selectedJobAttachmentsLabel: {
            get() {
                return this.selectedJob.type ? this.jobAttachmentsLabel[this.selectedJob.type] : 'Użyte słowniki';
            }
        },

        selectedJobAttackType: {
            get() {
                return this.selectedJob.type ? this.formattedJobAttack[this.selectedJob.type] : '-';
            }
        },

        selectedJobPriority: {
            get() {
                return this.formattedJobPriority[this.selectedJob.priority];
            }
        },

        selectedJobDescription: {
            get() {
                return this.description === null ? this.selectedJob.description : this.description;
            },
            set(value) {
                this.description = value;
            }
        },

        isSelectedJobUserAvailable() {
            return this.selectedJob.user ? true : false;
        },

        selectedJobUser() {
            return this.selectedJob.user;
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.selectedJobName.$dirty) return errors;
            !this.$v.selectedJobName.required && errors.push('Nazwa jest wymagana');
            return errors;
        }
    },

    watch: {
        selectedJob() {
            this.description = null;
            this.name = null;
        }
    },

    methods: {
        async sendPatchRequest() {
            if (!this.checkFormValidation()) return;

            this.isUpdating = true;

            const response = await jobsService.updateJob({
                name: this.selectedJobName,
                description: this.selectedJobDescription,
                id: this.selectedJob.id
            });

            if (response.status == 200) {
                await this.getUpdatedJobsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success());
            }
            this.isUpdating = false;
        },

        async sendAbortRequest() {
            this.isAborting = true;
            const jobId = this.selectedJob.id;

            const response = await jobsService.abortJob(jobId);

            if (response.status == 200) {
                await this.getUpdatedJobsData();
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Poprawnie zatrzymano zlecenie'));
            }
            this.isAborting = false;
        },

        async getUpdatedJobsData() {
            await this.$store.dispatch('getJobsFromApi');
            this.$store.dispatch('setJobRefetch');
            await this.$store.dispatch('getJobById', this.selectedJob.id);
        },

        checkFormValidation() {
            this.$v.$touch();

            return !this.$v.selectedJobName.$anyError;
        },

        openUserDetails(user) {
            this.$store.dispatch('setPreselectedUserId', user.id);
            this.$router.push({ path: '/uzytkownicy' });
        }
    }
};
</script>
