<template>
    <div>
        <v-card-text class="pa-6">
            <v-form>
                <v-textarea
                    outlined
                    :no-resize="true"
                    :rows="15"
                    label="Treść certyfikatu:"
                    class="v-input_full-width"
                    @blur="$v.certificate.$touch()"
                    :error-messages="certificateErrors"
                    v-model="certificate"
                ></v-textarea>
            </v-form>
            <v-alert v-if="acceptError" dense outlined type="error">{{ acceptError }}</v-alert>
        </v-card-text>
        <v-divider class="v-divider_light"></v-divider>
        <v-card-actions class="pa-2">
            <v-container>
                <v-row class="justify-end">
                    <v-card-actions>
                        <v-btn class="v-btn__primary mr-4" :loading="isUpdating" @click="acceptCsr">Wyślij</v-btn>
                        <v-btn class="v-btn__secondary" @click="$emit('deactivate')">Anuluj</v-btn>
                    </v-card-actions>
                </v-row>
            </v-container>
        </v-card-actions>
    </div>
</template>

<script>
import { NOTIFICATIONS } from '../../../_helpers/consts';
import { agentsService } from '../../../services/agents.service';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AgentCsrAcceptDialog',

    mixins: [validationMixin],
    validations: {
        certificate: { required }
    },

    data() {
        return {
            NOTIFICATIONS,

            certificate: '',
            isUpdating: false,

            acceptError: null
        };
    },

    props: {
        csr: {
            type: Object,
            required: true
        }
    },

    computed: {
        selectedAgent() {
            return this.$store.getters.selectedAgent;
        },

        certificateErrors() {
            const errors = [];
            if (!this.$v.certificate.$dirty) return errors;
            !this.$v.certificate.required && errors.push('Treść jest wymagana');
            return errors;
        }
    },

    methods: {
        async acceptCsr() {
            const isValid = this.checkFormValidation();
            if (!isValid) return;

            this.isUpdating = true;

            const response = await agentsService.acceptCsr(this.csr.id, this.certificate);

            if (response.status == 200) {
                this.$store.dispatch('addNotification', this.NOTIFICATIONS.success('Zaakceptowano agenta'));
                await this.fetchAgentsData();
            } else {
                this.acceptError = `Błąd podczas próby akceptacji agenta: ${response}`;
            }

            this.isUpdating = false;
        },

        checkFormValidation() {
            this.$v.$touch();
            return !this.$v.$anyError;
        },

        resetState() {
            this.acceptError = null;
            this.$v.$reset();
        },

        async fetchAgentsData() {
            this.$store.dispatch('getAgentById', this.selectedAgent.id);
            this.$store.dispatch('getAgentsFromApi');
        }
    },

    mounted() {
        this.resetState();
    }
};
</script>
