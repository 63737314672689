<template>
    <div>
        <v-form class="mr-auto ml-auto ml-md-0" ref="form">
            <div class="mb-6">
                <v-btn v-if="!selectedFile" :loading="isSelecting" @click="handleFileImport" class="v-btn__primary mr-4">{{ btnText }} </v-btn>
            </div>

            <input ref="uploader" id="fileUpload" class="d-none" type="file" @change="onFileChanged" />

            <div v-if="showProgressBar && selectedFile">
                <span v-if="Math.ceil(progress) == 0" class="label_color">Przetwarzanie pliku</span>
                <span v-else-if="Math.ceil(progress) < 100" class="label_color">Trwa przesyłanie pliku</span>
                <span v-else-if="Math.ceil(progress) == 100" class="label_color">Plik przesłany</span>
                <v-progress-linear
                    :value="progress"
                    :color="CSS_COLOR.textHighlight"
                    :background-color="CSS_COLOR.linesColorTertiary"
                    rounded
                    height="30"
                >
                    <strong>{{ Math.ceil(progress) }}%</strong>
                </v-progress-linear>
            </div>

            <div class="mb-6 mt-4">
                <template v-if="selectedFile">
                    <div class="pb-1">
                        <v-icon class="pr-2 v-icon_highlight"> mdi-file-outline </v-icon>
                        <span class="pr-2">{{ selectedFile.name }}</span>
                        <v-btn icon @click="removeFile()">
                            <v-icon class="v-icon"> mdi-close </v-icon>
                        </v-btn>
                    </div>
                </template>
            </div>
        </v-form>
    </div>
</template>

<script>
import { attachmentsService } from '../../../services/attachments.service';
import { CSS_COLOR } from '../../../_helpers/consts.js';
import crc32FromUint8Array from '../../../_helpers/crc32';

export default {
    name: 'FilesUploader',

    data: () => ({
        CSS_COLOR,
        isSelecting: false,
        selectedFile: null,
        showProgressBar: false,
        progress: 0,
        fileSize: 0,
        isUploading: false,
        fileUploaded: false,
        sessionId: null
    }),

    props: {
        btnText: {
            type: String,
            default: '+ Plik '
        },

        chunkSizeInBytes: {
            type: Number,
            default: 1048576 // 1mb
        }
    },

    methods: {
        handleFileImport() {
            this.checkTerminatedSessionExists();

            this.isSelecting = true;

            // After obtaining the focus when closing the FilePicker, return the button state to normal
            window.addEventListener(
                'focus',
                () => {
                    this.isSelecting = false;
                },
                { once: true }
            );

            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },

        async onFileChanged(e) {
            this.selectedFile = Array.from(e.target.files)[0];
            document.querySelector('#fileUpload').value = '';
            this.isUploading = true;
            this.showProgressBar = true;

            this.$emit('fileUpload', this.isUploading, this.selectedFile, null);

            const chunks = await this.createChunks(this.selectedFile);

            if (!this.selectedFile) return;

            this.sessionId = await this.startSession(this.selectedFile);

            if (!this.sessionId) return;

            this.$emit('fileUpload', this.isUploading, this.selectedFile, this.sessionId);

            for (const chunk of chunks) {
                if (!this.selectedFile) {
                    return;
                }
                await this.sendChunk(chunk, this.sessionId);
            }

            const commitResponse = await this.commitSession(this.sessionId);
            this.isUploading = false;
            commitResponse.status == 200 ? this.uploadComplete() : this.removeFile();
        },

        async createCheckSum(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = function (e) {
                    const arrayBuffer = e.currentTarget.result;
                    const byteArray = new Uint8Array(arrayBuffer);
                    const checksum = crc32FromUint8Array(byteArray);
                    resolve(checksum);
                    reject();
                };
            });
        },

        async createChunks(file) {
            /* cSize should be byte 1024*1 = 1KB */

            let startPointer = 0;
            const endPointer = file.size;
            const chunks = [];
            while (startPointer < endPointer) {
                if (!this.isUploading || !this.selectedFile) {
                    chunks.length = 0;
                    break;
                }
                let newStartPointer = startPointer + this.chunkSizeInBytes;
                const chunk = file.slice(startPointer, newStartPointer);
                chunks.push({
                    size: chunk.size,
                    offset: newStartPointer,
                    content: chunk,
                    checksum: await this.createCheckSum(chunk)
                });
                startPointer = newStartPointer;
            }
            return chunks;
        },

        async startSession(file) {
            const sessionId = await attachmentsService.createUploadSession(file);
            if (!sessionId) return Promise.resolve(false);
            try {
                localStorage.setItem('upload-file-temp-session-id', sessionId);
            } catch (error) {
                console.warn(error);
            }
            return sessionId;
        },

        async sendChunk(chunk, sessionId) {
            await attachmentsService.sendChunkSession(chunk, sessionId);
            this.addProgress(chunk.size, this.selectedFile?.size);
        },

        async commitSession(sessionId) {
            const commitData = await attachmentsService.commitUploadSession(sessionId);
            return commitData;
        },

        addProgress(stepValue, maxValue) {
            if (this.selectedFile) {
                const newProgress = (stepValue / maxValue) * 100;
                this.progress += newProgress;
            } else {
                this.progress = 0;
            }
        },

        uploadComplete() {
            try {
                localStorage.setItem('upload-file-complete', true);
            } catch (error) {
                console.warn(error);
            }
            this.$emit('fileUpload', this.isUploading, this.selectedFile, this.sessionId);
        },

        async removeFile() {
            this.selectedFile = null;
            this.progress = 0;

            if (!this.sessionId) {
                this.isUploading = false;
                this.$emit('fileUpload', this.isUploading, this.selectedFile, null);
                return;
            }

            if (this.isUploading) {
                attachmentsService.stopChunkSessionRequest();
                await attachmentsService.cancelUploadSession(this.sessionId);
            } else {
                await attachmentsService.destroyUploadFile(this.sessionId);
            }

            try {
                localStorage.removeItem('upload-file-temp-session-id');
                localStorage.removeItem('upload-file-complete');
            } catch (error) {
                console.warn(error);
            }
            this.isUploading = false;
            this.sessionId = null;

            this.$emit('fileUpload', this.isUploading, this.selectedFile, this.sessionId);
        },

        async checkTerminatedSessionExists() {
            let oldSessionId;
            let isOldSessionComplete;
            try {
                oldSessionId = localStorage.getItem('upload-file-temp-session-id');
                isOldSessionComplete = localStorage.getItem('upload-file-complete');
            } catch (error) {
                console.warn(error);
            }

            if (!oldSessionId) return;

            if (!isOldSessionComplete) {
                await attachmentsService.cancelUploadSession(oldSessionId);
            } else {
                await attachmentsService.destroyUploadFile(oldSessionId);
            }

            try {
                localStorage.removeItem('upload-file-temp-session-id');
                localStorage.removeItem('upload-file-complete');
            } catch (error) {
                console.warn(error);
            }
        }
    }
};
</script>
